import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetPodcastEpisode = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }
    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.podcast_episode, params);
    return response;
}

export const GetPodcastEpisodeById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.podcast_episode + '/' + id,params);
    return response;
}

export const CreatePodcastEpisode = async (data) => {
    const response = await POST(apiUrl.podcast_episode, data);
    return response;
}

export const UpdatePodcastEpisode = async (id, data) => {
    const response = await PUT(apiUrl.podcast_episode, id, data);
    return response;
}

export const DeletePodcastEpisode = async (id) => {
    const response = await DELETE(apiUrl.podcast_episode, id);
    return response;
}
