import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetTrainingStep = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }
    if(is_paginate === 1){
        params['paginate'] = is_paginate;
    }


    const response = await GET(apiUrl.training_step, params);
    return response;
}

export const GetTrainingStepById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.training_step + '/' + id,params);
    return response;
}

export const CreateTrainingStep = async (data) => {
    const response = await POST(apiUrl.training_step, data);
    return response;
}

export const UpdateTrainingStep = async (id, data) => {
    const response = await PUT(apiUrl.training_step, id, data);
    return response;
}

export const DeleteTrainingStep = async (id) => {
    const response = await DELETE(apiUrl.training_step, id);
    return response;
}
