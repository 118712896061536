import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateMusic} from "../../services/music.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {uploadToS3} from "../../utils/helpers";
import {CreateCategory, GetCategory} from "../../services/category.service";
import Select from "react-select";
import {GetType} from "../../services/users.service";

function AddNewCategory(props) {
    let history = useHistory();
    const [formData, setFormData] = useState([]);
    const [image, setImage] = useState()
    const [file, setFile] = useState(null)
    const [isUpload, setIsUpload] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [category, setCategory] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [type, setType] = useState();
    const {register, watch, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });


    useEffect(  async ()=>{
        await getType()
    },[])

    const onSubmit = async data => {
        if(file){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }
        if(file?.type.startsWith('image/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }
        setLoader(true)
        let uploadImage = await uploadToS3(file.type,file.name,file,'category');

        if (uploadImage) {
            if (!uploadImage) {
                setLoader(false)
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        data.media=uploadImage;
        await CreateCategory({
            name: data.name || '-',
            image:  data.media ,
            type: data.type,
            is_feature: (data?.is_feature && data?.is_feature==1 ? true: false ),
            status: (data?.status && data?.status==1 ? true: false ),
            ...(isChecked && { parent_id: selectedCategory }),

        }).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/categories');
            } else {
                setLoader(false)

            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })

    };


    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }

    }

    //IN CASE OF SUB CATEGORY
    const getCategory = async () => {
        await GetCategory(null, null, null, 0).then((result) => {
            if (result.status && result.data) {
                setCategory(result.data);
            }
        })
    }

    const optionsCategory = (item) => {
        setSelectedCategory(item.value);
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const getType = async () => {
        await GetType("CategoryTypes").then((result) => {
            if (result.status && result.data) {
                const serviceTypes =  result.data[0].CategoryTypes;
                setType(Object.values(serviceTypes));
            }
        })
    }

    return (
        <div className="AddNewMusic">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Category</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                   <br/>
                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'

                                    />

                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    <br/>
                                    {isUpload ?
                                        <img  src={image} type="image" className={"img-table img-thumbnail mt-2"} controls crossOrigin="anonymous" />:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                        {/*     <Form.Group className="mb-2 checkbox-group" controlId="sub-category">*/}
                        {/*        <input type="checkbox"*/}
                        {/*                             checked={isChecked}*/}
                        {/*                             onChange={handleCheckboxChange} />*/}
                        {/*        <Form.Label className=''>Sub Category</Form.Label>*/}
                        {/*     </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                            <Row>
                        {/*        {isChecked ?  <Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                        {/*            <Form.Group className="mb-3" controlId="category">*/}
                        {/*                <Form.Label className='d-block'>Parent Category*</Form.Label>*/}
                        {/*                <Select*/}
                        {/*                    options={category && category?.map(e => ({ label: e.name, value:e._id }))}*/}
                        {/*                    className="basic-multi-select"*/}
                        {/*                    onChange={optionsCategory}*/}

                        {/*                />*/}
                        {/*                {errors.category && <Form.Text className="text-muted validationText hasError">{errors.category.message}</Form.Text>}*/}
                        {/*            </Form.Group>*/}
                        {/*        </Col> : null}*/}

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Name"

                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Type*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type" {...register('type', {
                                        required: {
                                            value: "required",
                                            message: "Type is required"
                                        }
                                    })}>
                                        <option value={""} disabled selected hidden>-- Select --</option>
                                        {type && type.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.type && <Form.Text
                                        className=" validationText hasError">{errors.type.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Is Feature*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('is_feature', {
                                        required: {
                                            value: "required",
                                            message: "Feature status is required"
                                        }
                                    })}>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </Form.Select>
                                    {errors.is_feature && <Form.Text
                                        className=" validationText hasError">{errors.is_feature.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Category"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default AddNewCategory;