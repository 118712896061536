import React from 'react';
import {Button, Col, Row} from "react-bootstrap";

function ExportCsv({exportRows,fileName}) {


    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(exportRows[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = `${fileName}-export.csv`;
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }



    return (
        <Button className={"btn btn-green-theme h40 w-100 mb-1 mb-sm-3 mb-3"} onClick={() => downloadCSV(exportRows)}>Export
            CSV</Button>

    );
}

export default ExportCsv;