import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetTrainerById, GetType, GetUserById, UpdateUser} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {uploadToS3} from "../../utils/helpers";
import {Button, Col, Form, FormControl, InputGroup, Row, Tab} from "react-bootstrap";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {FadeLoader} from "react-spinners";
import Select from "react-select";
import {GetService} from "../../services/service.service";
import {GetState} from "../../services/state.service";
import {STATUS} from "../../utils/constants";
import moment from "moment/moment";
import {GetCountry} from "../../services/country.service";

function EditTrainer(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [image, setImage] = useState()
    const [states, setStates] = useState([])
    const [isUpload, setIsUpload] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [certificates, setCertificates] = useState()
    const [license, setLicense] = useState([])
    const [isPdfUpload, setIsPdfUpload] = useState(false)
    const [isLicUpload, setIsLicUpload] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [isLicloading, setIsLicloading] = useState(true)
    const [file, setFile] = useState(null)
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState();
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState();
    const [status, setStatus] = useState('');
    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const regex= new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$")
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format

    const getType = async () => {
        await GetType("ServiceTypes").then((result) => {
            if (result.status && result.data) {
                const serviceTypes =  result.data[0].ServiceTypes;
                setType(Object.values(serviceTypes));
            }
        })
    }
    const getStatusType = async () => {
        await GetType("StatusTypes").then((result) => {
            if (result.status && result.data) {
                const StatusTypes =  result.data[0].StatusTypes;
                setStatus(Object.values(StatusTypes));
            }
        })
    }

    useEffect(async () => {
        let id = props.match.params.id;
        const endpoint = window.location.href;
        const hasViewSegment = endpoint.includes("status");
        if(hasViewSegment){
            setDisabled(true)
        }else{
            setDisabled(false)
        }
        await getType();
        await getStatusType();
        await getService();
        await getState();
        await getUserByid(id);
    }, [])

    const getService = async () => {
        await GetService(null, null, null,0,null,).then((result) => {
            if (result.status && result.data) {
                const options = result.data.services.map((item,index) => ({
                    label: item.title,
                    value: item._id,
                }));
                setServices(options);
            }
        })
    }
    const getState = async () => {
        await GetState(null, null, null, 0).then((result) => {
            if (result.status && result.data) {
                setStates(result.data);
            }
        })
    }

    const handleChange = (event) => {
        const trimmedValue = event.target.value.trimStart();
    };

    const getUserByid = async (id) => {
        setIsLicloading(true)
        await GetUserById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("username", result.data?.full_name);
                    setValue("email", result.data?.email);
                    setValue("phone", result.data?.phone);
                    setValue("address", result.data?.address);
                    setValue("dob" ,result.data?.dob ? new Date(result.data.dob).toLocaleDateString('en-CA') : '');
                    setValue("gender", result.data?.gender);
                    setValue("type", result.data?.trainer_profile?.type);
                    setValue("bio", result.data?.trainer_profile?.bio);
                    setValue("location", result.data?.trainer_profile?.location);
                    setValue("status", result.data?.status);
                    setValue("is_license_verified",( result.data?.is_license_verified &&  result.data?.is_license_verified== true ? "1":"0"));
                    // setValue("type", result.data?.type);
                    if (result.data?.trainer_profile?.certifications && result.data.trainer_profile?.certifications.length > 0) {
                        const certificatePaths = result.data.trainer_profile?.certifications.map((attachment) => ({
                            url: attachment.path,
                            path: attachment.path
                        }));

                        setCertificates(certificatePaths);
                        setIsPdfUpload(true);
                    }
                    if (result.data?.license && result.data.license.length > 0) {
                        setLicense(result.data?.license);
                        setIsLicUpload(true);
                    }
                    if(result.data?.trainer_profile?.services_data && result.data?.trainer_profile?.services_data.length > 0){
                        const selected = result.data?.trainer_profile?.services_data.map((item,index) => ({
                            label: item.title,
                            value: item._id,
                        }));
                        setSelectedService(selected);
                    }
                    if(result.data?.image){
                        setImage(result.data?.image)
                        setIsUpload(true)
                    }
                }
                setIsLicloading(false)
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {

        let updatedLicense  = license?.length > 0 ? license?.map((item, index) => ({
                ...item,
                state_id: data?.[`states${index}`] || item.state_id,
                state_name: states.find(state => state._id === data?.[`states${index}`])?.name || item.state_name,
                is_verified: (data?.[`is_license_verified_${index}`] != undefined ? (data?.[`is_license_verified_${index}`] === '1' ? true : false): item.is_verified)
            })): null;

        if(isUploadOverWrite){
            if(file.type.startsWith('image/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }}
        setLoader(true)
        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Media is required"})
            }

            let uploadImage = await uploadToS3(file.type,file.name,file,'user');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            data.media=uploadImage;
        }else {
            data.media = image
        }
        const certifications = certificates?.map((pdf) => ({
            is_feature: true,
            mime_type: 'document',
            path: pdf?.path,
            type: 110,
        }));
        const services = selectedService?.map((item) => ({
            service_id:item.value
        }));
        let id = props.match.params.id;
        let dt = {
            full_name: data.username,
            address: data.address,
            dob: data.dob,
            gender:data.gender,
            type:'Trainer',
            device_type: 'web',
            device_token: 'user',
            image: data.media ,
            ...(updatedLicense ? { license:updatedLicense} : {}),
            status: data.status ,
            is_license_verified: false ,
            trainer_profile: {
                bio:data.bio,
                location:data.location,
                latitude:data.latitude || '123',
                longitude:data.longitude || '123',
                certifications:certifications,
                type:data.type,
                services:services,

            }
        }
        if (data.password && data.password_confirmation) {
            dt.password = data.password;
            dt.password_confirmation = data.password_confirmation;
        }
        await UpdateUser(id, dt).then(async (data) => {
            setLoader(false)
            if(data.status){
                toast.success(data.message);
                history.push('/professionals');
            } else{
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const onAgeChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setAge(date.toLocaleDateString())
    }

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
        }

    }

    const validateMedia = (fileList) => {
        const file = fileList[0];
        if (file && file.size > 5 * 1024 * 1024) {
            return 'Image size should be less than 5MB';
        }
        return true; // Validation passed
    };

    const uploadFiles = async (fileInput) => {
        const uploadedCertificates = [];
        setIsloading(true)
        for (let i = 0; i < fileInput.length; i++) {
            const file = fileInput[i];
            if (!file.type.startsWith('application/pdf')) {
                setError('certificate', { message: 'Invalid file type' });
                setIsloading(false)
                return false;
            }
            setIsloading(true)
            let fileUrl = URL.createObjectURL(file);
            const uploadedCertificate = await uploadToS3(file.type, file.name, file, 'profile');
            if (uploadedCertificate) {
                uploadedCertificates.push({ url: fileUrl, path: uploadedCertificate });
            }
        }
        setIsloading(false)
        setCertificates(uploadedCertificates);
        setIsPdfUpload(true);
        if (uploadedCertificates.length > 0) {
            clearErrors('certificate');
        }
    };

    const uploadLicenseFiles = async (fileInput) => {
        setIsLicloading(true)
            const file = fileInput[0];
            if (!file.type.startsWith('application/pdf')) {
                setError('license', { message: 'Invalid file type' });
                setIsLicloading(false)
                return false;
            }
            const uploadedLicense = await uploadToS3(file.type, file.name, file, 'profile');
        setIsLicloading(false)
        setLicense(prevLicense => [...prevLicense, {
            is_verified: true,
            mime_type: "document",
            path: uploadedLicense,
            state_id: "",
            state_name: "",
            type: 90
        }]);
        setIsLicUpload(true);
        if (uploadedLicense) {
            clearErrors('license');
        }
    };

    const optionsService = (selectedOptions) => {
        setSelectedService(selectedOptions || []);
        if(!selectedOptions){
            setError('service' ,{message:"Service is required"})
        }else{
            clearErrors('service')
        }
    }

    return (
        <div className="EditUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Professional</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={7}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 MB)</Form.Text> </Form.Label>
                                    <input
                                        disabled={disabled}
                                        {...register('media',{
                                            validate: validateMedia
                                        })}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class={disabled ? "disabled-file-input" :"custom-file-input"}
                                        id='image'
                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUpload ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img  src={image} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        {...register('username', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Full Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.username && <Form.Text
                                        className="text-muted validationText hasError">{errors.username.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="email" readOnly/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select
                                        disabled={disabled}
                                        id={"gender"}
                                        type="text"
                                        placeholder="Select Gender"
                                        {...register("gender", {
                                                required: disabled ? null : {
                                                    value: "required",
                                                    message: "Gender is required"
                                                }
                                            }
                                        )}
                                    >
                                        <option selected disabled value="">Select Gender</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Non-binary"}>Non-binary</option>
                                        <option value={"Transgender"}>Transgender</option>
                                        <option value={"Gender Neutral"}>Gender Neutral</option>
                                        <option value={"Agender"}>Agender</option>
                                        <option value={"Pangender"}>Pangender</option>
                                        <option value={"Two-spirit"}>Two-spirit</option>
                                        <option value={"Genderqueer"}>Genderqueer</option>
                                        <option value={"Third Gender"}>Third Gender</option>
                                        <option value={"Gender Fluid"}>Gender Fluid</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Select>
                                    {errors.gender && <Form.Text
                                        className=" validationText hasError">{errors.gender.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {/*<Col  xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className={"mb-3"} controlId="type">*/}
                            {/*        <Form.Label className='d-block'>Select Type*</Form.Label>*/}
                            {/*        <Form.Select className='formselect' aria-label="type" {...register('type', {*/}
                            {/*            required: {*/}
                            {/*                value: "required",*/}
                            {/*                message: "Type is required"*/}
                            {/*            }*/}
                            {/*        })}>*/}
                            {/*            <option value="Admin">Admin</option>*/}
                            {/*            <option value="User">User</option>*/}
                            {/*            <option value="Trainer">Trainer</option>*/}
                            {/*        </Form.Select>*/}
                            {/*        {errors.type && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.type.message}</Form.Text>}*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>DOB*</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        id={"age"}
                                        placeholder="Enter Age"
                                        value={age.date}
                                        max={maxDate}
                                        maxLength={3}
                                        {...register('dob', {
                                            required: disabled ? null : {
                                                value: "required",
                                                message: "DOB is required"
                                            },
                                            onChange: (e) => onAgeChange(e),
                                        })} type="date"
                                    />
                                    {errors.dob && <Form.Text
                                        className=" validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address*</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        placeholder="Enter Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('address', {
                                            required: {
                                                value: "required",
                                                message: "Address is required"
                                            },
                                            minLength: disabled ? null : {
                                                value: 5,
                                                message: "Minimum length is 5"
                                            },
                                            maxLength:  disabled ? null :{
                                                value: 200,
                                                message: "Maximum length is 200"
                                            }

                                        })} type="text" />
                                    {errors.address && <Form.Text
                                        className="text-muted validationText hasError">{errors.address.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-1" controlId="image">
                                    <Form.Label>Licenses</Form.Label>

                                    {/*<input*/}
                                    {/*    {...register('license')}*/}
                                    {/*    onChange={(e) => uploadLicenseFiles(e.target.files)}*/}
                                    {/*    type='file'*/}
                                    {/*    accept={'.pdf'}*/}
                                    {/*    class={disabled ? "disabled-file-input" :"custom-file-input"}*/}
                                    {/*    id='pdf'*/}
                                    {/*    disabled={disabled}*/}
                                    {/*/>*/}

                                    {/*{errors.license && <Form.Text*/}
                                    {/*    className=" validationText hasError">{errors.license.message}</Form.Text>}*/}
                                    {/*<br/>*/}
                                    {/*{isLicloading ? <div className="spin-loader mb-2">*/}
                                    {/*    <FadeLoader color={"#41D2D2"} height={10}/>*/}
                                    {/*</div> : null*/}

                                     {/*    <>{isLicUpload && (*/}
                                     {/*    <ul className={'order-list mt-2'}>*/}
                                     {/*            <li >*/}
                                     {/*                    <span className={'order-list-title'}>License:*/}
                                     {/*                    </span> <Button type={'button'} className={"btn btn-filter-theme pt-0 pb-0"} onClick={()=>{*/}
                                     {/*                window.open((license), '_blank');*/}
                                     {/*            }}>*/}
                                     {/*                View License*/}
                                     {/*            </Button>*/}
                                     {/*            </li>*/}
                                     {/*    </ul>*/}
                                     {/*)}</>*/}

                                     {/*}*/}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {isLicloading ?
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <div className="spin-loader  mb-3">
                                    <FadeLoader color={"#41D2D2"} height={10}/>
                                </div>
                                </Col>:
                                (Array.isArray(license) && license?.length > 0 ?
                                        license.map((item,index)=>{
                                            // setValue(`states${index}`,item?.state_id)
                                            return  <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                                <Form.Group className="license-list mb-3" controlId="text">
                                                    <Form.Label>
                                                        <span className={'license-number'}>{index + 1}</span>
                                                        <span className={'order-list-title'}>License:
                                                        </span> <Button type={'button'} className={"btn btn-filter-theme pt-0 pb-0"} onClick={()=>{
                                                        window.open((item?.path), '_blank');
                                                    }}>
                                                        View License
                                                    </Button></Form.Label>
                                                    <Form.Select
                                                        className='trainerformselect mb-2'
                                                        aria-label="type"
                                                        defaultValue={item?.is_verified ? "1" : "0"}
                                                        {...register(`is_license_verified_${index}`)}
                                                        disabled={disabled}
                                                    >
                                                        <option value={""} disabled selected hidden>-- Select --</option>
                                                        <option value={'1'}>Verified</option>
                                                        <option value={'0'}>Unverified</option>

                                                    </Form.Select>
                                                    <Form.Select className='formselect' aria-label="status"
                                                                 defaultValue={item?.state_id}
                                                                 {...register(`states${index}`)}
                                                                 disabled={disabled}
                                                    >
                                                        <option selected disabled value="" >Filter State</option>
                                                        {states.map((item) => {
                                                            return  <option value={item?._id} key={item?._id}>{item?.name}</option>
                                                        })}
                                                    </Form.Select>

                                                </Form.Group>
                                            </Col>

                                        }): <Form.Text
                                            className="text-muted hasError mb-3">The license has not been uploaded yet by the user.</Form.Text>
                                )
                            }


                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Certifications<Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('certificate')}
                                        onChange={(e) => uploadFiles(e.target.files)}
                                        type='file'
                                        accept={'.pdf'}
                                        class={disabled ? "disabled-file-input" :"custom-file-input"}
                                        id='pdf'
                                        multiple
                                        disabled={disabled}
                                    />

                                    {errors.certificate && <Form.Text
                                        className=" validationText hasError">{errors.certificate.message}</Form.Text>}
                                    <br/>
                                    {isloading ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> : <>{isPdfUpload && (
                                        <ul className={'order-list mt-2'}>
                                            {certificates.map((item,index) => (
                                                <li key={index} >
                                                        <span className={'order-list-title'}>Certificate {index+1}:
                                                        </span> <Button type={'button'} className={"btn btn-filter-theme pt-0 pb-0"} onClick={()=>{
                                                    window.open((item.url), '_blank');
                                                }}>
                                                    View Certificate
                                                </Button>,
                                                </li>
                                            ))}
                                        </ul>
                                    )} </>
                                    }

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="type">
                                    <Form.Label className='d-block'>Select Type</Form.Label>
                                    <Form.Select
                                        disabled={disabled}
                                        className='formselect' aria-label="type" {...register('type')}>
                                        <option value={""} disabled selected hidden>-- Select --</option>
                                        {type && type.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.type && <Form.Text
                                        className=" validationText hasError">{errors.type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>Service</Form.Label>
                                    <Select
                                        value={selectedService}
                                        options={services}
                                        className="basic-multi-select"
                                        onChange={optionsService}
                                        isMulti
                                        isDisabled={disabled}
                                    />
                                    {errors.user && <Form.Text className="text-muted validationText hasError">{errors.user.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="location">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                        disabled={disabled}
                                        placeholder="Enter Location"
                                        autocomplete="off"
                                        autofill="off"
                                        {...register('location', {
                                            minLength: {
                                                value: 5,
                                                message: "Min length is 5"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text" />
                                    {errors.location && <Form.Text
                                        className="text-muted validationText hasError">{errors.location.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Bio</Form.Label>
                                    <textarea
                                        id='custom-text-area'
                                        disabled={disabled}
                                        rows={6} className={'w-100'}
                                               placeholder="Enter Bio"
                                               {...register('bio', {
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"Bio should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 10,
                                                       message: "Min length is 10"
                                                   },
                                                   maxLength: {
                                                       value: 300,
                                                       message: "Max length is 300"
                                                   },
                                                   onChange: (e) => setValue('bio',e.target.value.trimStart()),

                                               })} type="text" />
                                    {errors.bio && <Form.Text
                                        className=" validationText hasError">{errors.bio.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            {!disabled ? <>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <div className={"pass-field mb-3"}>
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup controlId="passowrd">
                                            <FormControl
                                                placeholder="Enter Password"
                                                autoComplete={"off"}
                                                maxLength={255}
                                                minLength={8}
                                                {...register('password', {
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                        message: 'Password should contain a mix of uppercase and lowercase letters, numbers, and special characters'
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Min length is 8"
                                                    },
                                                    maxLength: {
                                                        value: 255,
                                                        message: "Max length is 255"
                                                    }
                                                })}
                                                type={pass ? "password" : "text"} />
                                            <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                                {
                                                    pass ? <FaEye /> : <FaEyeSlash />
                                                }
                                            </Button>
                                        </InputGroup>
                                        {errors.password && <Form.Text className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <div className={"pass-field mb-3"}>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <InputGroup controlId="password_confirmation">
                                            <Form.Control
                                                placeholder="Enter Password Again"
                                                maxLength={25}
                                                minLength={3}
                                                {...register('password_confirmation', {
                                                    minLength: {
                                                        value: 8,
                                                        message: "Min length is 8"
                                                    },
                                                    validate: (value) => {
                                                        if ( password.current?.length > 0 && !value) {
                                                            return "The confirm password is required";
                                                        } else if (password.current && password.current?.length > 0) {
                                                            return value === password.current || "The passwords do not match";
                                                        }
                                                        return true;
                                                    },
                                                    maxLength: {
                                                        value: 255,
                                                        message: "Max length is 255"
                                                    }
                                                })}
                                                type={confPass ? "password" : "text"}
                                            />
                                            <Button variant="outline-secondary" id="button-addon2" onClick={() => ToggleConfPass(!confPass)}>
                                                {
                                                    confPass ? <FaEye /> : <FaEyeSlash />
                                                }
                                            </Button>
                                        </InputGroup>
                                        {errors.password_confirmation && <Form.Text className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                    </div>
                                </Col>
                            </>:null}
                          <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        className='trainerformselect'
                                        aria-label="type"
                                        {...register('status')}
                                    >
                                        <option value={""} disabled selected hidden>-- Select --</option>
                                        {status && status.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Professional"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditTrainer;