import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm,useFieldArray } from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {CreatePlan} from "../../services/plan.service";
import Select from "react-select";
import {GetUsers} from "../../services/users.service";
import {GetCategory} from "../../services/category.service";


function AddNewPlan(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [startDate, setStartDate] = useState({ format: "mm/dd/yyyy" })
    const [mealDate, setMealDate] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [category, setCategory] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const {register, handleSubmit,control, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
        defaultValues: {
            meals: [{ meal: '', meal_date: '' }]
        }
    });
    const { fields, append ,remove} = useFieldArray({
        control,
        name: 'meals'
    });

    useEffect(  ()=>{
        getUsers()
        getCategory()
    },[])

    const onSubmit = async data => {
        if(!selectedCategory && !selectedUser ){
            setError('category' ,{message:"Category is required"})
            setError('user' ,{message:"User is required"})
            return false
        }else{
            clearErrors('category')
            clearErrors('User')
        }
        if(!selectedCategory){
            setError('category' ,{message:"Category is required"})
            return false
        }else{
            clearErrors('category')
        }
        if(!selectedUser){
            setError('user' ,{message:"User is required"})
            return false
        }else{
            clearErrors('user')
        }
        setLoader(true)


        await CreatePlan({
            category_id:selectedCategory,
            user_id:selectedUser,
            title: data.title || '-',
            duration: data.duration || '-',
            start_date: data.start_date,
            time: data.time || '-',
            meals: fields.map((field, index) => ({
                meal: data[`meal_${index}`],
                date: data[`meal_date_${index}`]
            })),
            repeat_weekly: (data?.repeat_weekly && data?.repeat_weekly==1 ? true: false )

        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/plans');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }

    const getCategory = async () => {
        await GetCategory(null, null, null, 0, true,'Wellness').then((result) => {
            if (result.status && result.data) {
                setCategory(result.data);
            }
        })
    }

    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setStartDate(date.toLocaleDateString())
    }

    const onMealDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setMealDate(date.toLocaleDateString())
    }

    const optionsUsers = (item) => {
        setSelectedUser(item.value);
        if(!item.value){
            setError('user' ,{message:"User is required"})
        }else{
            clearErrors('user')
        }
    }

    const optionsCategory = (item) => {
        setSelectedCategory(item.value);
        if(!item.value){
            setError('category' ,{message:"Category is required"})
        }else{
            clearErrors('category')
        }
    }

    const addMealField = () => {
        append({ meal: '', meal_date: '' });
    };

    const removeMealField = () => {
        remove(fields.length - 1);
    };

    return (
        <div className="AddNewPlan">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Plan</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>User*</Form.Label>
                                    <Select
                                        options={users && users?.map(e => ({ label:`${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}

                                    />
                                    {errors.user && <Form.Text className="text-muted validationText hasError">{errors.user.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label className='d-block'>Category*</Form.Label>
                                    <Select
                                        options={category && category?.map(e => ({ label: e.name, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsCategory}

                                    />
                                    {errors.category && <Form.Text className="text-muted validationText hasError">{errors.category.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Duration In Days*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter number of days"

                                        {...register('duration', {
                                            required: {
                                                value: "required",
                                                message: "Duration is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="number"
                                    />
                                    {errors.duration && <Form.Text
                                        className=" validationText hasError">{errors.duration.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Date*</Form.Label>
                                    <Form.Control
                                        id={"start_date"}
                                        placeholder="Enter Age"
                                        value={startDate.date}
                                        maxLength={3}
                                        {...register('start_date', {
                                            required: {
                                                value: "required",
                                                message: "Start Date is required"
                                            },
                                            onChange: (e) => onDateChange(e),
                                        })} type="date"
                                    />
                                    {errors.start_date && <Form.Text
                                        className=" validationText hasError">{errors.start_date.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Time In Min*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter time in minutes"

                                        {...register('time', {
                                            required: {
                                                value: "required",
                                                message: "Time is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="number"
                                    />
                                    {errors.time && <Form.Text
                                        className=" validationText hasError">{errors.time.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                        </Row>

                            {fields.map((field, index) => (
                                <Row key={field.id}>
                                    <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                        <Form.Group className="mb-3" controlId={`text${field.id}`}>
                                            <Form.Label>Meal{index == 0 ? '*': ` ${index+1}*`}</Form.Label>
                                            <Form.Control
                                                placeholder="Enter Meal"
                                                {...register(`meal_${index}`, {
                                                    required: {
                                                        value: true,
                                                        message: "Meal is required"
                                                    },
                                                    pattern:{
                                                        value:/^(?!\s).*$/,
                                                        message:"Meal should not start with a space"
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Min length is 3"
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: "Max length is 100"
                                                    },
                                                })}
                                                type="text"
                                            />
                                            {errors?.[`meal_${index}`] && (
                                                <Form.Text className="validationText hasError">
                                                    {errors?.[`meal_${index}`].message}
                                                </Form.Text>
                                            )}

                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Meal Date*</Form.Label>
                                            <Form.Control
                                                id={`meal_date${field.id}`}
                                                placeholder="Enter Date"
                                                value={mealDate.date}
                                                maxLength={3}
                                                {...register(`meal_date_${index}`, {
                                                    required: {
                                                        value: true,
                                                        message: "Date is required"
                                                    },
                                                    onChange: (e) => onMealDateChange(e),
                                                })}
                                                type="date"
                                            />
                                            {errors?.[`meal_date_${index}`] && (
                                                <Form.Text className="validationText hasError">
                                                    {errors?.[`meal_date_${index}`].message}
                                                </Form.Text>
                                            )}

                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))}
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <button type="button" className={"btn btn-green-theme w-60 mb-3"} onClick={addMealField}>Add Meal</button>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <button type="button" className={"btn btn-green-theme w-60 mb-3"} onClick={removeMealField}>Remove Meal</button>
                            </Col>
                        </Row>
                        <Row>

                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Repeat Weekly*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('repeat_weekly', {
                                        required: {
                                            value: "required",
                                            message: "Repeat Weekly is required"
                                        }
                                    })}>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </Form.Select>
                                    {errors.repetition_schedule && <Form.Text
                                        className=" validationText hasError">{errors.repetition_schedule.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Create Plan"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewPlan;