import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';




export const GetOrder = async (keyword = '', page = '', perPage = '', is_paginate = 1, type = null,user_id='') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (user_id) {
        params['user_id'] = user_id;
    }

    if (type) {
        params['status'] = type;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.order, params);
    return response;
}

export const GetOrderById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.order + '/' + id,params);
    return response;
}


export const UpdateOrder = async (id, data) => {
    const response = await PUT(apiUrl.order, id, data);
    return response;
}

export const DeleteOrder = async (id) => {
    const response = await DELETE(apiUrl.order, id);
    return response;
}