import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetRating = async (keyword = '', page = '', perPage = '', is_paginate = 1,user = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }
    if (user) {
        params['user_id'] = user;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.rating, params);
    return response;
}

export const GetRatingById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.rating + '/' + id,params);
    return response;
}

