import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetPodcastById, UpdatePodcast} from "../../services/podcast.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {GetArticleById, UpdateArticle} from "../../services/article.service";
import {GetCategory} from "../../services/category.service";
import Select from "react-select";
import {uploadToS3} from "../../utils/helpers";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, convertToRaw, ContentState,convertFromRaw ,convertFromHTML} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html";
import {UpdateAds} from "../../services/ads.service";
import CropComponent from "../../components/CropComponent";

function EditAds(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [file, setFile] = useState(null)
    const location = useLocation();
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [disable, setDisable] = useState(false)
    const {register, watch,setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect( () => {
         getAdsByid();

    }, [])

    const getAdsByid =  () => {
        const { data } = location.state;
        if (data) {
                setData(data);
                setValue("status", (data?.status_key && data?.status_key == true ? "1":"0"));
                if(data.image){
                    setImage(data.image)
                    setIsUpload(true)
                }

        }
    }



    const onSubmit = async data => {

        if(isUploadOverWrite){
            if(file?.type.startsWith('image/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }}
        setLoader(true)
        if(isUploadOverWrite){
            let uploadImage = await uploadToS3(file.type,file.name,file,'article');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            data.media=uploadImage;
        }else{
            data.media=image;
        }
        let id = props.match.params.id;
        let dt = {
            content:"content",
            image_url:data.media,
            is_active: (data?.status && data?.status==1 ? true: false ),

        }

        await UpdateAds(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/ads');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };



    // const uploadFile = async (fileInput) => {
    //     let fileUrl = URL.createObjectURL(fileInput[0])
    //     setImage(fileUrl)
    //     setFile(fileInput[0])
    //     setIsUpload(true)
    //     if(fileUrl){
    //         setIsUploadOverWrite(true)
    //         clearErrors('media')
    //     }
    //
    // }

    const uploadFile = async (fileInput) => {
        const file = fileInput[0];
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            if (aspectRatio === 9 / 16) {
                const fileUrl = URL.createObjectURL(file);
                setImage(fileUrl);
                setFile(file);
                setIsUpload(true);
                setIsUploadOverWrite(true);
                // Assuming clearErrors is a function to clear any previous errors
                // clearErrors('media');
            } else {
                alert('Please upload an image with a 9:16 aspect ratio.');
            }
        };
        img.src = URL.createObjectURL(file);
    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
        // const url = apiUrl[props['moduleName']]
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        setValue( "content" , plainText );

    }

    const handleFileUpload = async (file) => {
        // Perform file upload logic here
        // console.log(file);
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        // let uploadedImages = this.state.uploadedImages;



        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        let uploadImage = await uploadToS3(file.type,file.name,file,'article');
        console.log("[uploadImage]", uploadImage)

        if (!uploadImage) {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        // uploadedImages.push(imageObject);

        // this.setState(uploadedImages: uploadedImages)

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: uploadImage } });
            }
        );

    };

    return (
        <div className="EditAds">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Advertisement</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <Form.Text className="text-muted" style={{fontSize: "0.675em"}}>Upload an image with a 9:16 aspect ratio (e.g., 1440 pixels x 2560 pixels)</Form.Text>

                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class={disable ? "disabled-file-input mt-1":"custom-file-input mt-1"}
                                        disabled={disable}
                                        id='image'

                                    />
                                    {/*<CropComponent imageSrc={image}/>*/}
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    <br/>
                                    {isUpload ?
                                        <img  src={image} type="image" className={"img-ad img-thumbnail"} controls crossOrigin="anonymous" />:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status"   disabled={disable} {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                        {/*        <Form.Group className="mb-3" controlId="cast">*/}
                        {/*            <Form.Label>Content*</Form.Label>*/}
                        {/*            <textarea  id='custom-text-area' rows={8} cols={63}*/}
                        {/*                       placeholder="Enter Content"*/}
                        {/*                       {...register('content', {*/}
                        {/*                           required: {*/}
                        {/*                               value: "required",*/}
                        {/*                               message: "Content is required"*/}
                        {/*                           },*/}
                        {/*                           pattern:{*/}
                        {/*                               value:/^(?!\s).*$/,*/}
                        {/*                               message:"Content should not start with a space"*/}
                        {/*                           },*/}
                        {/*                           minLength: {*/}
                        {/*                               value: 3,*/}
                        {/*                               message: "Min length is 3"*/}
                        {/*                           },*/}
                        {/*                           maxLength: {*/}
                        {/*                               value: 1000,*/}
                        {/*                               message: "Max length is 1000"*/}
                        {/*                           },*/}
                        {/*                       })} type="text" />*/}
                        {/*            {errors.content && <Form.Text*/}
                        {/*                className=" validationText hasError">{errors.content.message}</Form.Text>}*/}

                        {/*        </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row>
                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={ "Update Advertisement"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditAds;