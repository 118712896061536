import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import Select from "react-select";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetVideoById, UpdateVideo} from "../../services/video.service";
import {GetCategory} from "../../services/category.service";
import {uploadToS3} from "../../utils/helpers";

function EditVideo(props) {
    let history = useHistory();
    const videoRef = useRef(null);
    const [data, setData] = useState({});
    const [video, setVideo] = useState()
    const [image, setImage] = useState()
    const [duration, setDuration] = useState(0);
    const [isUpload, setIsUpload] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [file, setFile] = useState(null)
    const [imageFile, setImageFile] = useState(null)
    const [loader, setLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [category, setCategory] = useState([]);
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [isUploadImgOverWrite, setIsUploadImgOverWrite] = useState(false)
    const [editSelectedCategory, setEditSelectedCategory] = useState();
    const {register, watch, handleSubmit, setError, clearErrors,setValue, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getVideoByid(id);
        getCategory()

    }, [])

    const getVideoByid = async (id) => {
        await GetVideoById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("title", result.data?.title);
                    setValue("status", (result.data?.status && result.data?.status == true ? "1":"0"));
                    if(result.data?.video[0].path){
                        setVideo(result.data?.video[0].path)
                        setIsUpload(true)
                    }
                    if(result.data?.thumbnail){
                        setImage(result.data?.thumbnail)
                        setIsImage(true)
                    }
                    setSelectedCategory({ label:result.data?.category[0].name, value: result.data?.category[0]._id })
                    // setSelectedCategory(result.data?.category[0]._id)

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }



    const onSubmit = async data => {
        if(isUploadOverWrite){
        if(file?.type.startsWith('video/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }}
        if(isUploadImgOverWrite){
            if(imageFile?.type.startsWith('image/')){
                clearErrors('thumbnail')
            }else{
                setError('thumbnail',{message:'Invalid file type'})
                return false;
            }
        }
        setLoader(true)
        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Video is required"})
            }

            let uploadVideo = await uploadToS3(file.type,file.name,file,'video');

            if (uploadVideo) {
                if (!uploadVideo) {
                    setLoader(false)
                    toast.error(uploadVideo.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Video');
                return false;
            }
            data.media=uploadVideo;
        }
        if(isUploadImgOverWrite){
            if (!imageFile){
                setError('thumbnail' ,{message:"Thumbnail is required"})
            }

            let uploadthumbnail = await uploadToS3(imageFile.type,imageFile.name,imageFile,'video');

            if (uploadthumbnail) {
                if (!uploadthumbnail) {
                    setLoader(false)
                    toast.error(uploadthumbnail.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload thumbnail');
                return false;
            }
            data.thumbnail=uploadthumbnail;
        }
        let id = props.match.params.id;
        let dt = {
            category_id:selectedCategory?.value,
            title: data.title || '-',
            ...(isUploadImgOverWrite && { thumbnail:data.thumbnail}),
            ...(isUploadOverWrite && {
                video:[{
                duration: duration,
                is_feature: true,
                mime_type: "video",
                 path: data.media,
                type: 50
            }]
            }),
            status: (data?.status && data?.status==1 ? true: false ),

        }

        await UpdateVideo(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/Videos');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const uploadFile = async (fileInput) => {
        let fileUrl = URL.createObjectURL(fileInput[0])
        setVideo(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
            clearErrors('media')
        }

    }
    const uploadThumbnailFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setImageFile(fileInput[0])
        setIsImage(true)
        if(fileUrl){
            setIsUploadImgOverWrite(true)
            clearErrors('thumbnail')
        }

    }

    const handleLoadedMetadata = () => {
        setDuration( Math.round(videoRef.current?.duration));
    }
    const getCategory = async () => {
        await GetCategory(null, null, null, 0, null,'Wellness').then((result) => {
            if (result.status && result.data) {
                setCategory(result.data);
            }
        })
    }
    const optionsCategory = (item) => {
        setSelectedCategory(item);
    }
    return (
        <div className="EditVideo">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Video</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="media">
                                    <Form.Label>Video* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                   <br/>
                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.mp4'}
                                        class="custom-file-input"
                                        id='image'

                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    {isUpload ?
                                        <video ref={videoRef} src={video} type="video" width="150" height="100" controls crossOrigin="anonymous" onLoadedMetadata={handleLoadedMetadata}/>:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Form.Group className="mb-3" controlId="image">
                                <Form.Label>Thumbnail* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                <br/>
                                <input
                                    {...register('thumbnail')}
                                    onChange={(e) => uploadThumbnailFile(e.target.files)}
                                    type='file'
                                    accept={'.jepg,.jpg,.png'}
                                    class="custom-file-input"
                                    id='image'

                                />
                                {errors.thumbnail && <Form.Text
                                    className="validationText hasError">{errors.thumbnail.message}</Form.Text>}
                                <br/>
                                {isImage ?
                                    <img src={image} className={"img-table img-thumbnail mt-2"}
                                         crossOrigin="anonymous"/> : "" }
                            </Form.Group>
                        </Col>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="cast">
                                    <Form.Label>Title, Presenter, and Description*</Form.Label>
                                    <textarea  id='custom-text-area' rows={8} cols={63}
                                               placeholder="Enter Title"
                                               {...register('title', {
                                                   required: {
                                                       value: "required",
                                                       message: "Title, Presenter, and Description is required"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 1000,
                                                       message: "Max length is 1000"
                                                   },
                                               })} type="text" />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="title">*/}
                            {/*        <Form.Label>Title*</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Title"*/}

                            {/*            {...register('title', {*/}
                            {/*                required: {*/}
                            {/*                    value: "required",*/}
                            {/*                    message: "Title is required"*/}
                            {/*                },*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"Title should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 3,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 100,*/}
                            {/*                    message: "Max length is 100"*/}
                            {/*                },*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.title && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.title.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label className='d-block'>Category*</Form.Label>
                                    <Select
                                        value={selectedCategory}
                                        options={category && category?.map(e => ({ label: e.name, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsCategory}

                                    />
                                    {errors.category && <Form.Text className="text-muted validationText hasError">{errors.category.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Video"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditVideo;