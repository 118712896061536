import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';


export const UpdateGoals = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.goals, id, data, config);
    return response;
}


export const GetGoals = async (user = '',category= '',keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }
    if (user) {
        params['user_id'] = user;
    }
    if (category) {
        params['category_id'] = category;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.goals, params);
    return response;
}

export const GetGoalById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.goals + '/' + id,params);
    return response;
}

export const CreateGoal = async (data) => {
    const response = await POST(apiUrl.goals, data);
    return response;
}

export const UpdateGoal = async (id, data) => {
    const response = await PUT(apiUrl.goals, id, data);
    return response;
}

export const DeleteGoal = async (id) => {
    const response = await DELETE(apiUrl.goals, id);
    return response;
}
