import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetAppointment = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null,user_id=null,trainer_id=null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }
    console.log(keyword)
    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['date'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (user_id) {
        params['user_id'] = user_id;
    }

    if (trainer_id) {
        params['trainer_id'] = trainer_id;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.appointment, params);
    return response;
}

export const GetAvailableTimeslots = async (date = null,session_id=null,trainer_id=null) => {
    let params = {};

    if (date) {
        params['date'] = date;
    }

    if (session_id) {
        params['session_id'] = session_id;
    }


    if (trainer_id) {
        params['trainer_id'] = trainer_id;
    }

    const response = await GET(apiUrl.appointment_time_slots, params);
    return response;
}

export const GetAppointmentById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.appointment + '/' + id,params);
    return response;
}

export const CreateAppointment = async (data) => {
    const response = await POST(apiUrl.appointment, data);
    return response;
}

export const EditAppointment = async (id, data) => {
    const response = await PUT(apiUrl.appointment, id, data);
    return response;
}

export const DeleteAppointment = async (id) => {
    const response = await DELETE(apiUrl.appointment, id);
    return response;
}
