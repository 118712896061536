import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetCity = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null, country_id = null,state_id = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['name'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }
    if (country_id) {
        params['country_id'] = country_id;
    }
    if (state_id) {
        params['state_id'] = state_id;
    }
    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }
    const response = await GET(apiUrl.cities, params);
    return response;
}

export const GetCityById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.cities + '/' + id,params);
    return response;
}

export const CreateCity = async (data) => {
    const response = await POST(apiUrl.cities, data);
    return response;
}

export const UpdateCity = async (id, data) => {
    const response = await PUT(apiUrl.cities, id, data);
    return response;
}

export const DeleteCity = async (id) => {
    const response = await DELETE(apiUrl.cities, id);
    return response;
}
