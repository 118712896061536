import React, {useState} from 'react'
import {Navbar, NavDropdown, Nav, Container} from 'react-bootstrap'
import {FaBuysellads, FaRegBell, FaRegUserCircle, FaUsers} from "react-icons/fa";
import {Logo} from "../utils/Svg"
import {EmptyLocalStorage, Logout} from "../services/auth/auth.service";
import { toast } from "react-toastify";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {
    RiArticleLine,
    RiDashboardFill,
    RiLogoutBoxFill,
    RiUserStarLine,
    RiAdminLine,
    RiProductHuntLine,
    RiQuestionnaireLine, RiPagesLine
} from "react-icons/ri";
import {
    BsBarChartSteps,
    BsBookFill,
    BsCalendar4Range,
    BsChatLeftQuote,
    BsClock,
    BsJournals,
    BsMic,
    BsQuestionSquare
} from "react-icons/bs";
import {BiBookContent, BiCategoryAlt, BiMusic, BiVideo} from "react-icons/bi";
import {
    MdModelTraining,
    MdOutlineClass,
    MdOutlineHomeRepairService,
    MdOutlineVerified,
    MdOutlineViewModule,
    MdPassword,
    MdPostAdd, MdReportGmailerrorred
} from "react-icons/md";
import Offcanvas from 'react-bootstrap/Offcanvas';
import {CgProfile} from "react-icons/cg";
import swal from "sweetalert";
import {HiOutlineUsers} from "react-icons/hi";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import {GrPlan} from "react-icons/gr";
import {GiAudioCassette} from "react-icons/gi";
import {AiOutlineShoppingCart} from "react-icons/ai";





function Header(props) {
    let history = useHistory();
    const { slug } = useParams();
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState(false);
    async function handleLogout() {
        const device_token = '123456';
        const device_type = 'web';
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await Logout(device_token,device_type).then(async (data) => {
                    if (data.status) {
                        toast.success(data.message);
                        await EmptyLocalStorage();
                        history.push('/');
                    } else {
                        toast.error(data.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });

    }
    const checkNotifications = () => {
        const notificationCount = 10;
    /*
    *
    *  checking the notification count and set to true
    *
    */
        if(notificationCount > 1){
            setNotificationStatus(!notificationStatus);
        }

    }

    const navDropdownTitle = (<><FaRegUserCircle/></>);

    return (
        <div>
            <Navbar  className={"TruthGPT-header shadow d-none d-lg-block"}>
                <Container fluid>
                    <Navbar.Brand href={props.url}>
                       <Logo/>
                    </Navbar.Brand>

                  <Nav className="ms-auto d-none d-lg-flex">
                            <NavDropdown align="end" title={navDropdownTitle} id="basic-nav-dropdown"
                                         className={"profileDropdown"}>
                                <NavDropdown.Item><NavLink to="/profile">Profile</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/change-password">Change Password</NavLink></NavDropdown.Item>
                                <NavDropdown.Item onClick={handleLogout} href="javascript:;">Logout</NavDropdown.Item>
                            </NavDropdown>
                            {/*<div className={"profile"}>*/}
                            {/*    <CgProfile/> Admin*/}
                            {/*</div>*/}
                        </Nav>

                </Container>
            </Navbar>
            {/* Responsive Navbar Starts Here*/}
            <div className="responsive-navbar d-lg-none">
                {[false].map((expand) => (
                    <Navbar expand='false' className=" imprint-header shadow">
                        <Container>
                            <Navbar.Brand href="/dashboard" >
                                <Logo/>
                            </Navbar.Brand>
                            <Navbar.Toggle className='toggles' aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(true)}/>
                            <Navbar.Offcanvas
                                className='offcanvas-bg'
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                                show={showOffcanvas}
                                onHide={() => setShowOffcanvas(false)}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="res-head justify-content-end flex-grow-1 pe-3">
                                        <ul className="nav nav-pills flex-column mb-auto">
                                            <li className="nav-item">
                                                <NavLink to="/dashboard" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <RiDashboardFill/>
                                                    Dashbaord
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/profile" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <CgProfile/>
                                                    Profile
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/manage-users" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <HiOutlineUsers/>
                                                    Users
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/admins" className="nav-link  " activeClassName="active"  onClick={() => setShowOffcanvas(false)} >
                                                    <RiAdminLine/>
                                                    Admins
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/professionals" className="nav-link  " activeClassName="active"  onClick={() => setShowOffcanvas(false)} >
                                                    <RiUserStarLine/>
                                                    Trainers
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/trainer-verification" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <MdOutlineVerified/>
                                                    Trainer SignUp Verification
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/trainer-availability" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsClock/>
                                                    Trainer Availability
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/appointments" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsCalendar4Range/>
                                                    Appointments
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/goals" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <IoMdCheckmarkCircleOutline/>
                                                    Goals
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/musics" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BiMusic/>
                                                    Musics
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/videos" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BiVideo/>
                                                    Videos
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/podcasts" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsMic/>
                                                    Podcasts
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/podcast-episodes" className="nav-link  " activeClassName="active"  onClick={() => setShowOffcanvas(false)}>
                                                    <GiAudioCassette/>
                                                    Podcast Episodes
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/daily-quotes" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsChatLeftQuote/>
                                                    Daily Quotes
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/articles" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <RiArticleLine/>
                                                    Articles
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/plans" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <GrPlan/>
                                                    Plans
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/journals" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsJournals/>
                                                    Journals
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/posts" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <MdPostAdd/>
                                                    Posts
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/orders" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <AiOutlineShoppingCart/>
                                                    Order
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/categories" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BiCategoryAlt/>
                                                    Categories
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/products" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <RiProductHuntLine/>
                                                    Products
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/services" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <MdOutlineHomeRepairService/>
                                                    Services
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/sessions" className="nav-link  " activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <MdOutlineClass/>
                                                    Sessions
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/questionnaire" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsQuestionSquare/>
                                                    Questionnaire
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/modules" className="nav-link" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <MdOutlineViewModule/>
                                                    Modules
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/module-steps" className="nav-link" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsBarChartSteps/>
                                                    Module Steps
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/trainings" className="nav-link" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <MdModelTraining/>
                                                    Trainings
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/training-steps" className="nav-link" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <BsBarChartSteps/>
                                                    Training Steps
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/reports" className="nav-link " activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <MdReportGmailerrorred/>
                                                    Reported Contents
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/faqs" className="nav-link " activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <RiQuestionnaireLine/>
                                                    FAQs
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/pages" className="nav-link " activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <RiPagesLine/>
                                                    Pages
                                                </NavLink>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <NavLink to="/push-notification-history" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>*/}
                                            {/*        <AiOutlineBell/>*/}
                                            {/*        Push Notifications*/}
                                            {/*    </NavLink>*/}
                                            {/*</li>*/}
                                            <li>
                                                <NavLink to="/change-password" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <MdPassword/>
                                                    Change Password
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="javascript:;" className="nav-link link-dark" activeClassName="active" onClick={handleLogout}>
                                                    <RiLogoutBoxFill/>
                                                    Logout
                                                </NavLink>
                                            </li>
                                        </ul>

                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </div>
            {/* Responsive Navbar Ends Here */}
        </div>
    )
}

export default Header
