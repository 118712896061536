import React, { useState } from 'react';
import Select from 'react-select';

function TimeDropdown({selectedTime, setSelectedTime}) {


    const generateTimeOptions = () => {
        const options = [];

        for (let hours = 0; hours < 24; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 30) {
                const formattedHours = hours.toString().padStart(2, '0');
                const formattedMinutes = minutes.toString().padStart(2, '0');
                const timeValue = `${formattedHours}:${formattedMinutes}`;
                const displayValue = formatTimeDisplay(hours, minutes);

                options.push({
                    value: timeValue,
                    label: displayValue,
                });
            }
        }

        return options;
    };

    const formatTimeDisplay = (hours, minutes) => {
        const period = hours < 12 ? 'AM' : 'PM';
        const displayHours = hours % 12 === 0 ? 12 : hours % 12;
        const displayMinutes = minutes.toString().padStart(2, '0');

        return `${displayHours}:${displayMinutes} ${period}`;
    };

    const handleTimeChange = (selectedOption) => {
        setSelectedTime(selectedOption?.value || null);
    };

    const defaultValue = selectedTime ? { value: selectedTime, label: formatTimeDisplay(...selectedTime.split(':')) } : null;




    return (
        <div>
            <Select
                id="timeSelect"
                options={generateTimeOptions()}
                value={defaultValue}
                onChange={handleTimeChange}
                isClearable
                placeholder="-- Select --"
            />
        </div>
    );
}

export default TimeDropdown;
