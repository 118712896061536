import React, {useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreatePodcast} from "../../services/podcast.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {UploadImageToS3, uploadToS3} from "../../utils/helpers";

function AddNewPodcast(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [podcast, setPodcast] = useState()
    const [duration, setDuration] = useState(1);
    const [isUpload, setIsUpload] = useState(false)
    const [file, setFile] = useState(null)
    const [loader, setLoader] = useState(false);
    const {register, watch, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });



    const onSubmit = async data => {
        if(file){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }
        if(file?.type.startsWith('image/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }
        setLoader(true)
        let uploadImage = await uploadToS3(file.type,file.name,file,'podcast');

        if (uploadImage) {
            if (!uploadImage) {
                setLoader(false)
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        data.media=uploadImage;
        await CreatePodcast({
            title: data.title || '-',
            content: data.content ,
            attachments: [{
                duration: duration,
                is_feature: true,
                mime_type: "image",
                path: data.media,
                type: 30
            }],
            status: (data?.status && data?.status==1 ? true: false ),


        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/podcasts');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };


    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setPodcast(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }

    }



    return (
        <div className="AddNewPodcast">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Podcast</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'

                                    />

                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    <br/>
                                    {isUpload ?
                                        <img  src={podcast} type="image" className={"img-table img-thumbnail mt-2"} controls crossOrigin="anonymous" />:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="cast">
                                    <Form.Label>Content*</Form.Label>
                                    <textarea  id='custom-text-area' rows={8} cols={63}
                                               placeholder="Enter Content"
                                               {...register('content', {
                                                   required: {
                                                       value: "required",
                                                       message: "Content is required"
                                                   },
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"Content should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 1000,
                                                       message: "Max length is 1000"
                                                   },
                                               })} type="text" />
                                    {errors.content && <Form.Text
                                        className=" validationText hasError">{errors.content.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Podcast"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default AddNewPodcast;