import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {uploadToS3} from "../../utils/helpers";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {SentNotification} from "../../services/notification.service";
import {GetUsers} from "../../services/users.service";
import {NotificationType} from "../../utils/constants";


function Notifications(props) {
    let history = useHistory();
    const videoRef = useRef(null);
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const {register, watch, handleSubmit, setError, clearErrors,reset,setValue, formState: {errors}} = useForm({
        mode: "onBlur",
    });


    const selectedType = watch('type');

    const getUsers = async () => {
        await GetUsers(false, null, null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }


    useEffect(  async ()=>{
        await getUsers();

    },[])

    const onSubmit = async data => {
        setLoader(true)
         if(data.type == NotificationType.CUSTOM && selectedUser?.length == 0){
             setError('user' ,{message:"User is required"})
             return false
         }else{
             clearErrors('user')
         }

        let dt = {
            users:selectedUser,
            title:data.title,
            body:data.body,
            type:data.type,
        }

        await SentNotification(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                reset()
                setValue('type','')
                setSelectedUser([])
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const optionsUsers = (item) => {

        let ids = item?.map((item)=>item.value)
        setSelectedUser(ids);
    }

    return (
        <div className="EditVideo">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Notifications</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Title*</Form.Label>
                                <Form.Control
                                    placeholder="Enter Title"
                                    id={"formSignUpName"}
                                    {...register('title', {
                                        required: {
                                            value: "required",
                                            message: "Title is required"
                                        },
                                        pattern:{
                                            value:/^(?!\s).*$/,
                                            message:"Title should not start with a space"
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                    })} type="text"
                                />
                                {errors.title && <Form.Text
                                    className="text-muted validationText hasError">{errors.title.message}</Form.Text>}

                            </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Body*</Form.Label>
                                    <textarea
                                        id='custom-text-area'
                                        rows={6} className={'w-100'}
                                        placeholder="Enter Body"
                                        {...register('body', {
                                            required: {
                                                value: "required",
                                                message: "Body is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Body should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                        })} type="text" />
                                    {errors.body && <Form.Text
                                        className=" validationText hasError">{errors.body.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Type*</Form.Label>
                                    <Form.Select
                                        id={"type"}
                                        type="text"
                                        placeholder="Select Type"
                                        {...register("type", {
                                                required: {
                                                    value: "required",
                                                    message: "Type is required"
                                                }
                                            }
                                        )}
                                    >
                                        <option selected disabled value="">Select Type</option>
                                        <option value={NotificationType.USER}>Only Users</option>
                                        <option value={NotificationType.TRAINER}>Only Professionals</option>
                                        <option value={NotificationType.ALL}>All App Users</option>
                                        <option value={NotificationType.CUSTOM}>Custom Users</option>
                                    </Form.Select>
                                    {errors.type && <Form.Text
                                        className=" validationText hasError">{errors.type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {console.log(selectedType == NotificationType.CUSTOM,"selectedType")}
                            {selectedType == NotificationType.CUSTOM && <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>Users*</Form.Label>
                                    <Select
                                        isMulti
                                        options={users && users?.map(e => ({
                                            label: `${e.full_name} (${e.email})`,
                                            value: e._id
                                        }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}
                                    />
                                    {errors.user && <Form.Text
                                        className="text-muted validationText hasError">{errors.user.message}</Form.Text>}
                                </Form.Group>
                            </Col>}
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Sent"}/>}
                            </Col>

                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default Notifications;