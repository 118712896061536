import React, {useState, useEffect, useLayoutEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import {MdOutlineModeEditOutline} from 'react-icons/md'
import {toast} from "react-toastify";
import {GetProfile} from "../../services/users.service";
import {constants, GENDERS} from "../../utils/constants";
import { useLocation } from "react-router-dom";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';

const initialData = {
    id: '',
    full_name: '',
    email: '',
    phone: '',
    image: constants.USERIMAGENOTFOUND,
}

function ViewProfile() {
    const history = useHistory();
    const [data, setData] = useState({});

    // useEffect(() => {
    //     getProfile()
    // }, [])
    //
    useLayoutEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let dt = result.data;
                    if (dt) {
                        await setData(
                            {
                                id: dt._id,
                                full_name: dt.full_name,
                                email: dt.email,
                                gender: GENDERS[dt?.gender],
                                type: dt.type,
                                role_id: dt.role_id,
                                address: dt.address,
                                dob: dt.dob,
                                image: dt?.image || constants.USERIMAGENOTFOUND,
                            }
                        )
                    }
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">View Profile</h3>
            <hr/>
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className={"profileImg mx-auto text-center mb-3 mb-sm-3 mb-md-0"}>
                                <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.USERIMAGENOTFOUND;
                                }} src={data?.image || constants.USERIMAGENOTFOUND} alt={"Profile Image"}  crossOrigin="anonymous"/>
                            </div>
                        </Col>
                        <Col xs={12} sm={8} md={7} lg={6} xl={7}>
                            <div className={"profileDetails"}>
                                <h4>{data?.full_name}</h4>
                                <p><b>Email:</b> {data?.email}</p>
                                <p><b>DOB:</b> {data?.dob}</p>
                                <p><b>Gender:</b> {data?.gender}</p>
                                <p><b>Type:</b> {data?.type}</p>
                                <p><b>Address:</b> {data?.address}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} md={2} lg={3} xl={2} className={'text-sm-end text-start'}>
                            <NavLink className={"floating-link"} to={{pathname: "/edit-profile/"+ data.id, state: data}}>
                                <MdOutlineModeEditOutline/> Edit Profile
                            </NavLink>
                        </Col>

                    </Row>
                </div>

            </div>
        </div>
    )
}

export default ViewProfile
