import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetFAQById, UpdateFAQ} from "../../services/faq.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetPackageById, UpdatePackage} from "../../services/package.service";

function EditPackages(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const {register, watch,setValue, handleSubmit, setError, clearErrors,control, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getPackByid(id);

    }, [])

    const getPackByid = async (id) => {
        await GetPackageById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("name", result.data?.name);
                    setValue("price", result.data?.price);
                    setValue("answer", result.data?.answer);
                    setValue("status", (result.data?.status && result.data?.status == true ? "1":"0"));

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }



    const onSubmit = async data => {
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            name: data.name || '-',
            answer: data.answer ,
            status: (data?.status && data?.status==1 ? true: false ),

        }

        await UpdatePackage(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/packages');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    return (
        <div className="EditPackage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Package</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Package Name</Form.Label>
                                    <Form.Control
                                        placeholder="Package Name"
                                        id={"formSignUpName"}
                                        disabled
                                        {...register('name')} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        placeholder="Price"
                                        id={"formSignUpName"}
                                        disabled
                                        {...register('price')} type="text"
                                    />
                                    {errors.price && <Form.Text
                                        className="text-muted validationText hasError">{errors.price.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Answer*</Form.Label>
                                    <textarea  id='custom-text-area' rows={8} cols={80}
                                               placeholder="Enter Answer"
                                               {...register('answer', {
                                                   required: {
                                                       value: "required",
                                                       message: "Answer is required"
                                                   },
                                                   pattern:{
                                                       value:/^(?!^\s).*$/s,
                                                       message:"Answer should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 4000,
                                                       message: "Max length is 4000"
                                                   },
                                               })} type="text" />
                                    {errors.answer && <Form.Text
                                        className=" validationText hasError">{errors.answer.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Package"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditPackages;