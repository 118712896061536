import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import { Tab, Nav } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import {constants, customStyles, IS} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import Dummy from "../../assets/images/truth-banner-6.jpg"
import {GetUserById} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetAnswers, GetQuestionnaire} from "../../services/questionnaire.service";
import moment from "moment";
import {GetGoals} from "../../services/goal.service";
import {GetPlan} from "../../services/plan.service";
import {GetJournal} from "../../services/journal.service";
import {GetUserStat} from "../../services/userstats.service";
import {GetModuledetails, GetStepsCompleted} from "../../services/module.service";
import {GetOrder} from "../../services/order.service";
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

function UserView(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [goalData, setGoalData] = useState([]);
    const [moduleStepData, setModuleStepsData] = useState([]);
    const [planData, setPlanData] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [userStatsData, setUserStatsData] = useState([]);
    const [answerData, setAnswerData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [loader, setLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('tab1');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [defaultImage, setDefaultImage] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [rows, setRows] = useState([{
        id: 1,
        name: 'John Doe',
        email: 'johndoe@example.com',
        gender: 'Male',
        dob: '1990-01-01',
        phone: '1234567890',
        address: '123 Main St, City',
    },
        {
            id: 2,
            name: 'Jane Smith',
            email: 'janesmith@example.com',
            gender: 'Female',
            dob: '1995-05-15',
            phone: '9876543210',
            address: '456 Elm St, Town',
        },]);


    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format


    const onSubmit = async data => {
        setLoader(true)
        history.push('/manage-users');
    };

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    useEffect(async () => {
        let id = props.match.params.id;
        await getUserByid(id);
        await getAnswers(id);
        await getGoals(id);
        await getPlans(id);
        await getJournals(id);
        await getStepsCompleted(id)
        await getOrder(id)
    }, [])


    useEffect(async () => {
        let id = props.match.params.id;
        await getUserStats(id);
    }, [startDate,endDate])

    const getUserByid = async (id) => {
        await GetUserById(id).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("username", result.data?.full_name);
                    setValue("email", result.data?.email);
                    setValue("type", result.data?.type);
                    setValue("address", result.data?.address);
                    setValue("dob" ,result.data?.dob ? new Date(result.data.dob).toLocaleDateString('en-CA') : '');
                    setValue("gender", result.data?.gender);
                    setValue("bio", result.data?.bio);
                    setValue("phone", result.data?.phone);
                    await setDefaultImage(result.data?.image|| constants.USERIMAGENOTFOUND)
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getAnswers = async (id) => {
        await GetAnswers(id, page, perPage,1).then((result) => {
            if (result?.status) {
                if (result?.data?.questionnaire_answers) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.questionnaire_answers.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            question: dt?.question[0]?.text || '-',
                            answer: dt.answer || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')

                        });

                    })
                    setAnswerData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getGoals = async (id) => {
        await GetGoals(id,null,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.goals) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.goals.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:dt?.user[0]?.full_name || '-',
                            category_id:dt?.category[0]?.name || '-',
                            title: dt.title || '-',
                            duration: dt.duration || '-',
                            start_date: dt.start_date || '-',
                            time: dt.time || '-',
                            repetition_schedule: dt.repetition_schedule || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setGoalData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getPlans = async (id) => {
        await GetPlan(id,null,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.plans) {

                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.plans.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:dt?.user[0]?.full_name || '-',
                            category_id:dt?.category[0]?.name || '-',
                            title: dt.title || '-',
                            duration: dt.duration || '-',
                            start_date: dt.start_date || '-',
                            time: dt.time || '-',
                            repeat_weekly: IS[dt.repeat_weekly]|| '-',
                            meals: dt?.meals,
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setPlanData(rowData);
                    setLoading(false)
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getJournals = async (id) => {
        await GetJournal(id,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.journals) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.journals.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            text: dt.text || '-',
                            date: dt.date || '-',
                            time: dt.time || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setJournalData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getUserStats = async (id) => {
        await GetUserStat(id,startDate,endDate,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.user_stats) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.user_stats.map((dt, index) => {
                        rowData.push({
                            id:dt.user_id || '-',
                            calories: dt.calories || '-',
                            steps: dt.steps || '-',
                            sleep: dt.sleep || '-',
                            heart_rate: dt.heart_rate || '-',
                            date: dt.date || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setUserStatsData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getStepsCompleted = async (id) => {
        await GetStepsCompleted(keyword, page, perPage,1,id).then((result) => {
            if (result.status) {
                if (result.data.user_modules) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.user_modules.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            module:dt?.modules[0]?.title || '-',
                            module_image:dt?.modules[0]?.attachments[0].path || '-',
                            step: dt.steps[0]?.title || '-',
                            step_content: dt.steps[0]?.content || '-',
                            completed_at:  moment(dt.completed_at).format("MM/DD/YYYY hh:mm A") || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setModuleStepsData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getOrder = async (id) => {
        await GetOrder(keyword, page, perPage,1,null,id).then((result) => {
            if (result.status) {
                if (result.data.orders) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.orders.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:`${dt?.user?.full_name} (${dt?.user?.email})` || '-',
                            order_number:dt?.order_number || '-',
                            shipping_address: dt?.shipping_address.address || '-',
                            amount: dt?.amount || '-',
                            vat: dt?.vat || '-',
                            total_payable: dt?.vat + dt?.amount || '-',
                            payment_status: dt?.payment_status|| '-',
                            user_card_id: dt?.user?.stripe_customer_id|| '-',
                            status: dt?.status,
                            cancelled_by: (dt?.cancelled_by ? `${dt?.cancelled_by?.full_name} (${dt?.cancelled_by?.email})` : "-"),
                            rating:  (dt?.ratings?.rating ? `${dt?.ratings?.rating}/5` : "-" ),
                            review: dt?.ratings?.review || '-',
                            submitted_at:  moment(dt?.ratings?.created_at).format("MM/DD/YYYY hh:mm A"),
                            created_at:  moment(dt.created_at).format("MM/DD/YYYY hh:mm A"),
                        });

                    })
                    setOrderData(rowData);
                }
            } else {
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const ModuleStepscolumns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'60px',
            minWidth:'20px',
            selector: rowData => rowData.id,
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth:'100px',
            minWidth:'20px',
            center: true,
            selector: rowData => rowData.module_image,
            format: rowData => <img src={rowData.module_image}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Broken'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'module',
            name: 'Module',
            grow: false,
            center: true,
            selector: rowData => rowData.module,
        },
        {
            id: 'step',
            name: 'Step',
            grow: false,
            center: true,
            selector: rowData => rowData.step,
        },
        {
            id: 'step_content',
            name: 'Step Content',
            maxWidth:'240px',
            minWidth:'360px',
            grow: false,
            center: true,
            selector: rowData => rowData.step_content,
        },
        {
            id: 'completed_at',
            name: 'Completed At',
            grow: false,
            center: true,
            selector: rowData => rowData.completed_at,
        },
    ];

    const Journalscolumns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'text',
            name: 'Text',
            grow: false,
            selector: rowData => rowData.text,
        },
        {
            id: 'date',
            name: 'Date',
            grow: false,
            center: true,
            selector: rowData => rowData.date,
        },
        {
            id: 'time',
            name: 'Time',
            grow: false,
            center: true,
            selector: rowData => rowData.time,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
    ];

    const Answercolumns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '100px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'question',
            name: 'Question',
            maxWidth: '500px',
            minWidth: '500px',
            sortable: true,
            selector: rowData => rowData.question,
        },
        {
            id: 'answer',
            name: 'Answer',
            minWidth: '350px',
            selector: rowData => rowData.answer,
        },
        {
            id: 'created_at',
            name: 'Submitted At',
            center: true,
            selector: rowData => rowData.created_at,
        },

    ];

    const Goalscolumns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'user_id',
            name: 'User',
            grow: false,
            center: true,
            selector: rowData => rowData.user_id,
        },
        {
            id: 'category_id',
            name: 'Category',
            grow: false,
            center: true,
            selector: rowData => rowData.category_id,
        },
        {
            id: 'title',
            name: 'Title',
            grow: false,
            selector: rowData => rowData.title,
        },
        {
            id: 'duration',
            name: 'Duration',
            grow: false,
            center: true,
            selector: rowData => rowData.duration,
        },    {
            id: 'start_date',
            name: 'Start Date',
            grow: false,
            center: true,
            selector: rowData => rowData.start_date,
        },
        {
            id: 'time',
            name: 'Time',
            grow: false,
            center: true,
            selector: rowData => rowData.time,
        },
        {
            id: ' repetition_schedule',
            name: 'Repetition Schedule',
            grow: false,
            center: true,
            selector: rowData => rowData.repetition_schedule,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },

    ];

    const Planscolumns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'user_id',
            name: 'User',
            grow: false,
            center: true,
            selector: rowData => rowData.user_id,
        },
        {
            id: 'category_id',
            name: 'Category',
            grow: false,
            center: true,
            selector: rowData => rowData.category_id,
        },
        {
            id: 'title',
            name: 'Title',
            grow: false,
            selector: rowData => rowData.title,
        },
        {
            id: 'duration',
            name: 'Duration',
            grow: false,
            center: true,
            selector: rowData => rowData.duration,
        },    {
            id: 'start_date',
            name: 'Start Date',
            grow: false,
            center: true,
            selector: rowData => rowData.start_date,
        },
        {
            id: 'time',
            name: 'Time',
            grow: false,
            center: true,
            selector: rowData => rowData.time,
        },
        {
            id: ' repeat_weekly',
            name: 'Repeat Weekly',
            grow: false,
            center: true,
            selector: rowData => rowData.repeat_weekly,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
    ];

    const UserStatscolumns = [

        {
            id: 'calories',
            name: 'Calories',
            grow: false,
            center: true,
            selector: rowData => rowData.calories,
        },
        {
            id: 'steps',
            name: 'Steps',
            grow: false,
            center: true,
            selector: rowData => rowData.steps,
        },
        {
            id: 'sleep',
            name: 'Sleep',
            grow: false,
            center: true,
            selector: rowData => rowData.sleep,
        },
        {
            id: 'heart_rate',
            name: 'Heart Rate',
            grow: false,
            center: true,
            selector: rowData => rowData.heart_rate,
        },
        {
            id: 'date',
            name: 'Date',
            grow: false,
            center: true,
            selector: rowData => rowData.date,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
    ];

    const Ordercolumns = [
        {
            id: 'order_number',
            name: 'order number',
            width:'160px',
            grow: false,
            selector: rowData => rowData.order_number,
        },
        {
            id: 'payment_status',
            name: 'payment status',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.payment_status,
        },
        {
            id: 'amount',
            name: 'amount ($)',
            grow: false,
            center: true,
            selector: rowData => rowData.amount,
        },
        {
            id: 'vat',
            name: 'vat ($)',
            grow: false,
            center: true,
            selector: rowData => rowData.vat,
        },
        {
            id: 'total_payable',
            name: 'total payable($)',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.total_payable,
        },
        {
            id: 'shipping_address',
            name: 'shipping address',
            minWidth: '200px',
            maxWidth: '350px',
            grow: false,
            center: true,
            selector: rowData => rowData.shipping_address,
        },
        {
            id: ' status',
            name: 'status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'cancelled_by',
            name: 'cancelled by',
            minWidth: '200px',
            maxWidth: '350px',
            grow: false,
            center: true,
            selector: rowData => rowData.cancelled_by,
        },
        {
            id: 'rating',
            name: 'rating',
            grow: false,
            center: true,
            selector: rowData => rowData.rating,
        },
        {
            id: 'review',
            name: 'review',
            width: '300px',
            grow: false,
            center: true,
            selector: rowData => rowData.review,
        },
        {
            id: 'submitted_at',
            name: 'Review Submitted At',
            width: '160px',
            grow: false,
            center: true,
            selector: rowData => rowData.submitted_at,
        },
        {
            id: 'created_at',
            name: 'Ordered At',
            width: '150px',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        }
    ];

    return (
        <div className="ViewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">View User</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="full_name">
                               <div className="img-cover img-thumbnail">
                                   <img onError={({currentTarget}) => {
                                       currentTarget.onerror = null; // prevents looping
                                       currentTarget.src = constants.IMAGENOTFOUND;
                                   }} src={ defaultImage} className="user-profile"/>
                               </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        {...register('username')} type="text"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('email')} type="email" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select
                                        disabled
                                        id={"gender"}
                                        type="text"
                                        placeholder="Select Gender"
                                        {...register("gender")}
                                    >
                                        <option selected disabled value="">Select Gender</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Non-binary"}>Non-binary</option>
                                        <option value={"Transgender"}>Transgender</option>
                                        <option value={"Gender Neutral"}>Gender Neutral</option>
                                        <option value={"Agender"}>Agender</option>
                                        <option value={"Pangender"}>Pangender</option>
                                        <option value={"Two-spirit"}>Two-spirit</option>
                                        <option value={"Genderqueer"}>Genderqueer</option>
                                        <option value={"Third Gender"}>Third Gender</option>
                                        <option value={"Gender Fluid"}>Gender Fluid</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>DOB*</Form.Label>
                                    <Form.Control
                                        disabled
                                        id={"age"}
                                        placeholder="Enter Age"
                                        value={age.date}
                                        max={maxDate}
                                        maxLength={3}
                                        {...register('dob')} type="date"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Type*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Type"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        {...register('type')} type="text"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Phone"
                                        id={"formSignUpName"}
                                        {...register('phone')}
                                        type="number"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('address')} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Bio</Form.Label>
                                    <textarea id='custom-text-area'
                                        disabled
                                        rows={6} className={'w-100'}
                                        placeholder="Bio"
                                        {...register('bio')} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab1">Questionnaire</Nav.Link>
                                        </Nav.Item>
                                        {/*<Nav.Item>*/}
                                        {/*    <Nav.Link eventKey="tab2">Goals</Nav.Link>*/}
                                        {/*</Nav.Item>*/}
                                        {/*<Nav.Item>*/}
                                        {/*    <Nav.Link eventKey="tab3">Plans</Nav.Link>*/}
                                        {/*</Nav.Item>*/}
                                        {/*<Nav.Item>*/}
                                        {/*    <Nav.Link eventKey="tab4">Journals</Nav.Link>*/}
                                        {/*</Nav.Item>*/}
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab5">User Stats</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab6">Module Steps Detail</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab7">Order History</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tab1">
                                            <h4>Question Answers</h4>
                                            <div className="theme-dataGrid products-dataGrid">
                                                <DataTable
                                                    pagination
                                                    columns={Answercolumns}
                                                    data={answerData}
                                                    // actions={actionsMemo}
                                                    // data={data}
                                                    progressPending={loading}
                                                    customStyles={customStyles}
                                                    selectableRows={false}
                                                    striped
                                                    highlightOnHover

                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={perPage}
                                                    onChangePage={(page) => {
                                                        setPage(page);
                                                    }}
                                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                                        setPerPage(currentRowsPerPage)
                                                    }}
                                                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                                                  height={'4'} width={'100%'} />}
                                                />
                                            </div>
                                        </Tab.Pane>
                                        {/*<Tab.Pane eventKey="tab2">*/}
                                        {/*    <h4>Goals</h4>*/}
                                        {/*    <div className="theme-dataGrid products-dataGrid">*/}
                                        {/*        <DataTable*/}
                                        {/*            pagination*/}
                                        {/*            columns={Goalscolumns}*/}
                                        {/*            data={goalData}*/}
                                        {/*            // actions={actionsMemo}*/}
                                        {/*            // data={data}*/}
                                        {/*            progressPending={loading}*/}
                                        {/*            customStyles={customStyles}*/}
                                        {/*            selectableRows={false}*/}
                                        {/*            striped*/}
                                        {/*            highlightOnHover*/}

                                        {/*            paginationServer*/}
                                        {/*            paginationTotalRows={totalRows}*/}
                                        {/*            paginationPerPage={perPage}*/}
                                        {/*            onChangePage={(page) => {*/}
                                        {/*                setPage(page);*/}
                                        {/*            }}*/}
                                        {/*            onChangeRowsPerPage={(currentRowsPerPage) => {*/}
                                        {/*                setPerPage(currentRowsPerPage)*/}
                                        {/*            }}*/}
                                        {/*            progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}*/}
                                        {/*                                          height={'4'} width={'100%'} />}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</Tab.Pane>*/}
                                        {/*<Tab.Pane eventKey="tab3">*/}
                                        {/*    <h4>Plans</h4>*/}
                                        {/*    <div className="theme-dataGrid products-dataGrid">*/}
                                        {/*        <DataTable*/}
                                        {/*            pagination*/}
                                        {/*            columns={Planscolumns}*/}
                                        {/*            data={planData}*/}
                                        {/*            // actions={actionsMemo}*/}
                                        {/*            // data={data}*/}
                                        {/*            progressPending={loading}*/}
                                        {/*            customStyles={customStyles}*/}
                                        {/*            selectableRows={false}*/}
                                        {/*            striped*/}
                                        {/*            highlightOnHover*/}

                                        {/*            paginationServer*/}
                                        {/*            paginationTotalRows={totalRows}*/}
                                        {/*            paginationPerPage={perPage}*/}
                                        {/*            onChangePage={(page) => {*/}
                                        {/*                setPage(page);*/}
                                        {/*            }}*/}
                                        {/*            onChangeRowsPerPage={(currentRowsPerPage) => {*/}
                                        {/*                setPerPage(currentRowsPerPage)*/}
                                        {/*            }}*/}
                                        {/*            progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}*/}
                                        {/*                                          height={'4'} width={'100%'} />}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</Tab.Pane>*/}
                                        {/*<Tab.Pane eventKey="tab4">*/}
                                        {/*    <h4>Jouranls</h4>*/}
                                        {/*    <div className="theme-dataGrid products-dataGrid">*/}
                                        {/*        <DataTable*/}
                                        {/*            pagination*/}
                                        {/*            columns={Journalscolumns}*/}
                                        {/*            data={journalData}*/}
                                        {/*            // actions={actionsMemo}*/}
                                        {/*            // data={data}*/}
                                        {/*            progressPending={loading}*/}
                                        {/*            customStyles={customStyles}*/}
                                        {/*            selectableRows={false}*/}
                                        {/*            striped*/}
                                        {/*            highlightOnHover*/}

                                        {/*            paginationServer*/}
                                        {/*            paginationTotalRows={totalRows}*/}
                                        {/*            paginationPerPage={perPage}*/}
                                        {/*            onChangePage={(page) => {*/}
                                        {/*                setPage(page);*/}
                                        {/*            }}*/}
                                        {/*            onChangeRowsPerPage={(currentRowsPerPage) => {*/}
                                        {/*                setPerPage(currentRowsPerPage)*/}
                                        {/*            }}*/}
                                        {/*            progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}*/}
                                        {/*                                          height={'4'} width={'100%'} />}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</Tab.Pane>*/}
                                        <Tab.Pane eventKey="tab5">
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <h4>User Stats</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/*<Col xs={12} sm={6} md={5} lg={4} xl={3}>*/}
                                                {/*    <DateRangePicker*/}
                                                {/*        localeText={{ start: 'Check-in', end: 'Check-out' }}*/}
                                                {/*        // value={value}*/}
                                                {/*        // onChange={(newValue) => setValue(newValue)}*/}
                                                {/*    />*/}
                                                {/*</Col>*/}
                                                <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Start Date</Form.Label>
                                                        <Form.Control
                                                            id={"age"}
                                                            placeholder="Enter Age"
                                                            value={startDate}
                                                            max={maxDate}
                                                            maxLength={3}
                                                            onChange={(e)=>{
                                                                setStartDate(e.target.value)
                                                                }}
                                                            type="date"
                                                        />

                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>End Date</Form.Label>
                                                        <Form.Control
                                                            id={"age"}
                                                            placeholder="Filter from end date"
                                                            value={endDate.date}
                                                            max={maxDate}
                                                            maxLength={3}
                                                            onChange={(e)=>{
                                                                setEndDate(e.target.value)
                                                                }}
                                                             type="date"
                                                        />

                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={3} md={3} lg={{span:3,offset:1}} xl={{span:3,offset:3}}>
                                                    <Button className={"btn btn-filter-theme h40 w-100"} onClick={ ()=>{
                                                        setEndDate('')
                                                        setStartDate('')
                                                    }}>
                                                        Reset Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="theme-dataGrid products-dataGrid">
                                                        <DataTable
                                                            pagination
                                                            columns={UserStatscolumns}
                                                            data={userStatsData}
                                                            // actions={actionsMemo}
                                                            // data={data}
                                                            progressPending={loading}
                                                            customStyles={customStyles}
                                                            selectableRows={false}
                                                            striped
                                                            highlightOnHover

                                                            paginationServer
                                                            paginationTotalRows={totalRows}
                                                            paginationPerPage={perPage}
                                                            onChangePage={(page) => {
                                                                setPage(page);
                                                            }}
                                                            onChangeRowsPerPage={(currentRowsPerPage) => {
                                                                setPerPage(currentRowsPerPage)
                                                            }}
                                                            progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                                                          height={'4'} width={'100%'} />}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab6">
                                            <h4>Module Steps Detail</h4>
                                            <div className="theme-dataGrid products-dataGrid">
                                                <DataTable
                                                    pagination
                                                    columns={ModuleStepscolumns}
                                                    data={moduleStepData}
                                                    // actions={actionsMemo}
                                                    // data={data}
                                                    progressPending={loading}
                                                    customStyles={customStyles}
                                                    selectableRows={false}
                                                    striped
                                                    highlightOnHover

                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={perPage}
                                                    onChangePage={(page) => {
                                                        setPage(page);
                                                    }}
                                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                                        setPerPage(currentRowsPerPage)
                                                    }}
                                                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                                                  height={'4'} width={'100%'} />}
                                                />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab7">
                                            <h4>Order History</h4>
                                            <div className="theme-dataGrid products-dataGrid">
                                                <DataTable
                                                    pagination
                                                    columns={Ordercolumns}
                                                    data={orderData}
                                                    // actions={actionsMemo}
                                                    // data={data}
                                                    progressPending={loading}
                                                    customStyles={customStyles}
                                                    selectableRows={false}
                                                    striped
                                                    highlightOnHover

                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={perPage}
                                                    onChangePage={(page) => {
                                                        setPage(page);
                                                    }}
                                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                                        setPerPage(currentRowsPerPage)
                                                    }}
                                                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                                                  height={'4'} width={'100%'} />}
                                                />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Back"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    );
}

export default UserView;