import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetPackage = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (status) {
        params['status'] = status;
    }

    if (keyword) {
        params['name'] = keyword;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.package, params);
    return response;
}


export const GetPackageById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.package + '/' + id,params);
    return response;
}


export const UpdatePackage = async (id, data) => {
    const response = await PUT(apiUrl.package, id, data);
    return response;
}

export const UpdatePackageStatus = async (id, data) => {
    const response = await PUT(apiUrl.package_status, id, data);
    return response;
}