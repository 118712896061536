import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateModuleStep, GetModuleStepById, UpdateModuleStep} from "../../services/modulesteps.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetModule, GetModuleById, UpdateModule} from "../../services/module.service";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import {uploadToS3} from "../../utils/helpers";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

function EditModuleStep(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [selectedModule, setSelectedModule] = useState();
    const [editSelectedModule, setEditSelectedModule] = useState();
    const [module, setModule] = useState([]);
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, watch, handleSubmit,setValue, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getModule()
        await getModuleStepByid(id);


    }, [])

    const getModuleStepByid = async (id) => {
        await GetModuleStepById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("title", result.data?.title);
                    setValue("duration", result.data?.duration);
                    setValue("sequence", result.data?.sequence);
                    setValue("status", (result.data?.status && result.data?.status == true ? "1":"0"));
                    setEditSelectedModule({ label:result.data?.module[0].title, value: result.data?.module[0]._id })
                    setSelectedModule(result.data?.module[0]?._id)
                    if(result.data?.content){
                        const contentState = ContentState.createFromBlockArray(htmlToDraft(result.data?.content));
                        const newEditorState = EditorState.createWithContent(contentState);
                        setEditorState(newEditorState);

                    }

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }

    const onSubmit = async data => {
        if(!selectedModule){
            setError('category' ,{message:"Module is required"})
            return false
        }else{
            clearErrors('category')
        }
        if(!editorState.getCurrentContent().hasText()){
            setError('content' ,{message:"Content is required"})
            return false
        }else{
            clearErrors('content')
        }
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            module_id:selectedModule || null,
            title: data.title || '-',
            content:draftToHtml(convertToRaw(editorState.getCurrentContent())) ,
            duration:data.duration,
            sequence:data.sequence,
            status: (data?.status && data?.status==1 ? true: false ),
        }

        await UpdateModuleStep(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/module-steps');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const getModule = async () => {
        await GetModule(null, null, null, 0).then((result) => {
            if (result.status && result.data) {
                setModule(result.data);
            }
        })
    }
    const optionsModule = (item) => {
        setSelectedModule(item.value);
        setEditSelectedModule({ label:item.label, value:item.value })
        if(!item.value){
            setError('module' ,{message:"Module is required"})
            return false
        }else{
            clearErrors('module')
        }
    }

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
        // const url = apiUrl[props['moduleName']]
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        setValue( "content" , plainText );

    }

    const handleFileUpload = async (file) => {
        // Perform file upload logic here
        // console.log(file);
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        // let uploadedImages = this.state.uploadedImages;



        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        let uploadImage = await uploadToS3(file.type,file.name,file,'article');
        console.log("[uploadImage]", uploadImage)

        if (!uploadImage) {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        // uploadedImages.push(imageObject);

        // this.setState(uploadedImages: uploadedImages)

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: uploadImage } });
            }
        );

    };



    return (
        <div className="EditModuleStep">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Module Step</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="duration">
                                    <Form.Label>Duration*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Duration"

                                        {...register('duration', {
                                            required: {
                                                value: "required",
                                                message: "Duration is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="number"
                                    />
                                    {errors.duration && <Form.Text
                                        className=" validationText hasError">{errors.duration.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="sequence">
                                    <Form.Label>Sequence*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Sequence"

                                        {...register('sequence', {
                                            required: {
                                                value: "required",
                                                message: "Sequence is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="number"
                                    />
                                    {errors.sequence && <Form.Text
                                        className=" validationText hasError">{errors.sequence.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="module">
                                    <Form.Label className='d-block'>Module*</Form.Label>
                                    <Select
                                        value={editSelectedModule}
                                        options={module && module?.map(e => ({ label: e.title, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsModule}

                                    />
                                    {errors.module && <Form.Text className="text-muted validationText hasError">{errors.module.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={3} xl={3}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="cast">
                                    <Form.Label>Content*</Form.Label>
                                    <Editor
                                        {...register('content')}
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            image: {
                                                uploadCallback: handleFileUpload,
                                                previewImage: true,
                                                // alt: { present: true, mandatory: false },
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            },
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                    />
                                    {errors.content && <Form.Text
                                        className=" validationText hasError">{errors.content.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Module Step"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditModuleStep;