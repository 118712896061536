import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {GetPlanById, UpdatePlan} from "../../services/plan.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetUsers} from "../../services/users.service";
import {GetCategory} from "../../services/category.service";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {GetJournalById, UpdateJournal} from "../../services/journal.service";

function EditJournal(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [date, setDate] = useState({ format: "mm/dd/yyyy" })
    const [selectedUser, setSelectedUser] = useState();
    const [editSelectedUser, setEditSelectedUser] = useState();
    const [users, setUsers] = useState([]);

    const {register, handleSubmit,control, setError, clearErrors,setValue, formState: {errors}} = useForm({
        mode: "onBlur",
        defaultValues: {
            meals: [{ meal: '', meal_date: '' }]
        }
    });
    const { fields, append ,remove} = useFieldArray({
        control,
        name: 'meals'
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getUsers()
        await getJournalByid(id);

    }, [])

    const getJournalByid = async (id) => {
        await GetJournalById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("text", result.data?.text);
                    setValue("date", result.data?.date ? new Date(result.data.date).toLocaleDateString('en-CA') : '');
                    setEditSelectedUser({ label:`${result.data?.user[0].full_name} (${result.data?.user[0].email})`, value: result.data?.user[0]._id })
                    setSelectedUser(result.data?.user[0]._id);

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }



    const onSubmit = async data => {
        if(!selectedUser){
            setError('user' ,{message:"User is required"})
            return false
        }else{
            clearErrors('user')
        }
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            user_id:selectedUser,
            text: data.text || '-',

        }

        await UpdateJournal(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/journals');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }


    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setDate(date.toLocaleDateString())
    }


    const optionsUsers = (item) => {
        setSelectedUser(item.value);
        setEditSelectedUser({ label:item.label, value:item.value })
        if(!item.value){
            setError('user' ,{message:"User is required"})
        }else{
            clearErrors('User')
        }
    }


    return (
        <div className="AddNewJournal">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Journal</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>User*</Form.Label>
                                    <Select
                                        value={editSelectedUser}
                                        options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}

                                    />
                                    {errors.sports && <Form.Text className="text-muted validationText hasError">{errors.sports.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Text*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Text"

                                        {...register('text', {
                                            required: {
                                                value: "required",
                                                message: "Text is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Text should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.text && <Form.Text
                                        className=" validationText hasError">{errors.text.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date*</Form.Label>
                                    <Form.Control
                                        disabled
                                        id={"date"}
                                        placeholder="Enter Date"
                                        value={date.date}
                                        maxLength={3}
                                        {...register('date', {
                                            required: {
                                                value: "required",
                                                message: "Date is required"
                                            },
                                            onChange: (e) => onDateChange(e),
                                        })} type="date"
                                    />
                                    {errors.date && <Form.Text
                                        className=" validationText hasError">{errors.date.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Journal"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditJournal;