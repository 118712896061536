import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {GetPageById, UpdatePage} from "../../services/pages.service";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {uploadToS3} from "../../utils/helpers";

function EditPage(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getPageByid(id);
        // if (data) {
        //     reset({
        //         'full_name': data.full_name,
        //         // 'username': data.username,
        //         'email': data.email,
        //         'phone': data.phone,
        //         'dob': data.dob,
        //         //'company_name': data.company_name,
        //         //'business_type': data.business_type,
        //     });
        //
        //
        // }
    }, []);
    const getPageByid = async (id) => {
        await GetPageById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setFormData(result.data);
                    setValue("slug", result.data?.slug);
                    setValue("title", result.data?.title);
                    setValue("status", (result.data?.status && result.data?.status == true ? "1":"0"));
                    const contentState = ContentState.createFromBlockArray(htmlToDraft(result.data?.content).contentBlocks);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);

                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }
    const onSubmit = async data => {
        if(!editorState.getCurrentContent().hasText()){
            setError('content' ,{message:"Content is required"})
            return false
        }else{
            clearErrors('content')
        }
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            slug: data.slug,
            title: data.title,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())) ,
            status: (data?.status && data?.status == 1 ? true: false ),

        }
        await UpdatePage(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/pages');
            } else {
                setLoader(false)
                // setDisableButton(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            // setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })


    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
        // const url = apiUrl[props['moduleName']]
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        setValue( "content" , plainText );

    }

    const handleFileUpload = async (file) => {
        // Perform file upload logic here
        // console.log(file);
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        // let uploadedImages = this.state.uploadedImages;



        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        let uploadImage = await uploadToS3(file.type,file.name,file,'article');
        console.log("[uploadImage]", uploadImage)

        if (!uploadImage) {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        // uploadedImages.push(imageObject);

        // this.setState(uploadedImages: uploadedImages)

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: uploadImage } });
            }
        );

    };
    return (
        <div className="EditPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Page</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Slug*</Form.Label>
                                    <Form.Control
                                         disabled
                                        {...register('slug')} type="text"
                                    />
                                    {errors.slug && <Form.Text
                                        className=" validationText hasError">{errors.slug.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        disabled
                                        {...register('title')} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Col  xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Form.Group className={"mb-3"} controlId="status">
                                <Form.Label className='d-block'>Select Status*</Form.Label>
                                <Form.Select className='formselect' aria-label="status" {...register('status', {
                                    required: {
                                        value: "required",
                                        message: "Status is required"
                                    }
                                })}>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </Form.Select>
                                {errors.status && <Form.Text
                                    className=" validationText hasError">{errors.status.message}</Form.Text>}
                            </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="cast">
                                    <Form.Label>Content*</Form.Label>
                                    <Editor
                                        {...register('content')}
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            image: {
                                                uploadCallback: handleFileUpload,
                                                previewImage: true,
                                                // alt: { present: true, mandatory: false },
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            },
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                    />
                                    {errors.content && <Form.Text
                                        className=" validationText hasError">{errors.content.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col  xs={12} sm={12} md={6} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Page"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditPage;