import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {CreatePost} from "../../services/post.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage, GetAuthUser} from "../../services/auth/auth.service";
import {GetType, GetUsers} from "../../services/users.service";
import {GetCategory} from "../../services/category.service";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {uploadToS3} from "../../utils/helpers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {POST_TYPES} from "../../utils/constants";

function AddNewPost(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [category, setCategory] = useState();
    const [type, setType] = useState();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [images, setImages] = useState()
    const [dateTime, setDateTime] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [file, setFile] = useState(null)
    const {register, handleSubmit,control, setError, clearErrors,watch, formState: {errors}} = useForm({
        mode: "onBlur",
        defaultValues: {
            poll: [{ title: '' }]
        }
    });
    const { fields, append ,remove} = useFieldArray({
        control,
        name: 'poll'
    });
    const selectedType = watch('type');
    const selectedEventType = watch('event_type');
    const selectedPostType = watch('category');

    useEffect(  async ()=>{
        await getCategory();
        await getType();
        await getUsers()
    },[])

    const getType = async () => {
        await GetType("PostTypes").then((result) => {
            if (result.status && result.data) {
                const PostTypes =  result.data[0].PostTypes;
                setType(Object.values(PostTypes));
            }
        })
    }

    const getCategory = async () => {
        await GetType("PostCategory").then((result) => {
            if (result.status && result.data) {
                const PostCategory =  result.data[0].PostCategory;
                setCategory(Object.values(PostCategory));
            }
        })
    }

    const onSubmit = async data => {
        const user = GetAuthUser();
        setLoader(true);
        let attachments;
        console.log(user,"user")
        if(selectedPostType == POST_TYPES.COMMUNITY){
            if(!selectedUser){
                setError('user' ,{message:"User is required"})
                return false
            }else{
                clearErrors('user')
            }
            if (images?.length === 0) {
                setError('media', { message: 'Please upload at least one image' });
                return false;
            }else{
                clearErrors('media')
            }

            attachments = images?.map((image) => ({
                is_feature: true,
                mime_type: (image.type.startsWith('image/') ? 'image' : 'video'),
                path: image.path,
                type: 80,
            }));
        }







        await CreatePost({
            user_id:selectedPostType == POST_TYPES.COMMUNITY ? selectedUser : user?._id ,
            content: data.content || '-',
            category: data.category || '-',
            status: (data?.status && data?.status==1 ? true: false ),
            type: data.type || '-',
           ...(data.type == "Event" ?{event_name: data.event_name} : {} ),
           ...(data.type == "Event" ?{event_type: data.event_type} : {} ),
           ...(data.type == "Event" ?{event_link: data.event_link} : {} ),
           ...(data.type == "Event" ?{date_time: dateTime} : {} ),
           ...(data.category == POST_TYPES.COMMUNITY ?{ attachments: attachments} : {} ),
           ...(data.type == "Poll"?{ poll: fields.map((field, index) => ({
                   title: data[`title_${index}`],
               })),} : {} ),


        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/posts');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }


    const optionsUsers = (item) => {
        setSelectedUser(item.value);
        if(!item.value){
            setError('user' ,{message:"User is required"})
        }else{
            clearErrors('user')
        }
    }


    const addPollField = () => {
        append({ meal: '', meal_date: '' });
    };

    const removePollField = () => {
        remove(fields.length - 1);
    };

    const uploadFiles = async (fileInput) => {
        const uploadedImages = [];
        setIsloading(true)
        for (let i = 0; i < fileInput.length; i++) {
            const file = fileInput[i];
            if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
                setError('media', { message: 'Invalid file type' });
                setIsloading(false);
                return false;
            }
            setIsloading(true)
            let fileUrl = URL.createObjectURL(file);
            const uploadedImage = await uploadToS3(file.type, file.name, file, 'post');
            if (uploadedImage) {
                uploadedImages.push({ url: fileUrl, path: uploadedImage ,type : file.type });
            }
        }
        setIsloading(false)
        console.log(uploadedImages,"uploadedImages")
        if(uploadedImages.length > 0){
            setImages(uploadedImages);

            setIsUpload(true);
        }

        if (uploadedImages.length > 0) {
            clearErrors('media');
        }
    };



    return (
        <div className="AddNewPost">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Post</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Category*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type" {...register('category', {
                                        required: {
                                            value: "required",
                                            message: "Category is required"
                                        }
                                    })}>
                                        <option  value='' disabled hidden  selected>--Select--</option>
                                        {category && category.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.category && <Form.Text
                                        className=" validationText hasError">{errors.category.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {selectedPostType == POST_TYPES.COMMUNITY &&
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group className="mb-3" controlId="image">
                                        <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                        <input
                                            {...register('media')}
                                            onChange={(e) => uploadFiles(e.target.files)}
                                            type='file'
                                            accept={'.jepg,.jpg,.png'}
                                            class="custom-file-input"
                                            id='image'
                                            multiple
                                        />

                                        {errors.media && <Form.Text
                                            className=" validationText hasError">{errors.media.message}</Form.Text>}
                                        <br/>
                                        {isloading ? (
                                            <div className="spin-loader">
                                                <FadeLoader color={"#41D2D2"} height={10} />
                                            </div>
                                        ) : (
                                            <>
                                                {isUpload && (
                                                    <div className={'image-wrapper'}>
                                                        {images.map((image, index) => {
                                                            if (image.type.startsWith('image/')) {
                                                                return (
                                                                    <img
                                                                        key={index}
                                                                        src={image.url}
                                                                        alt="uploaded"
                                                                        className="post-thumbnail mt-2"
                                                                        controls
                                                                        crossOrigin="anonymous"
                                                                    />
                                                                );
                                                            } else if (image.type.startsWith('video/')) {
                                                                return (
                                                                    <video
                                                                        key={index}
                                                                        src={image.url}
                                                                        className="post-thumbnail"
                                                                        type="video"
                                                                        controls
                                                                        crossOrigin="anonymous"
                                                                    />
                                                                );
                                                            }
                                                            return null; // This handles other types like audio, etc.
                                                        })}
                                                    </div>
                                                )}
                                            </>
                                        )}

                                    </Form.Group>
                                </Col>
                            </Row>
                        }

                        <Row>
                            {selectedPostType == POST_TYPES.COMMUNITY &&
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>User*</Form.Label>
                                    <Select
                                        options={users && users?.map(e => ({ label:`${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}

                                    />
                                    {errors.user && <Form.Text className="text-muted validationText hasError">{errors.user.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            }
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Type*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type" {...register('type', {
                                        required: {
                                            value: "required",
                                            message: "Type is required"
                                        }
                                    })}>
                                        <option  value={''} selected disabled hidden>--Select--</option>
                                        {type && type.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.type && <Form.Text
                                        className=" validationText hasError">{errors.type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {selectedType === 'Event'?
                                <>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="event_name">
                                    <Form.Label>Event Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Event Name"
                                        id={"formSignUpName"}
                                        {...register('event_name', {
                                            required: {
                                                value: "required",
                                                message: "Event Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Event Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 60,
                                                message: "Max length is 60"
                                            },
                                        })} type="text"
                                    />
                                    {errors.event_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.event_name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Event Type*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type" {...register('event_type', {
                                        required: {
                                            value: "required",
                                            message: "Event Type is required"
                                        }
                                    })}>
                                        <option  value='' disabled hidden  selected>--Select--</option>
                                        <option  value='Online'>Online</option>
                                        <option  value='Physical'>Physical</option>
                                    </Form.Select>
                                    {errors.event_type && <Form.Text
                                        className=" validationText hasError">{errors.event_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Event Date & Time*</Form.Label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        // label="Controlled picker"
                                        value={dateTime}
                                        onChange={(newValue) => setDateTime(newValue)}
                                    />
                                    </LocalizationProvider>
                                    {errors.date_time && <Form.Text
                                        className=" validationText hasError">{errors.date_time.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="event_link">
                                    <Form.Label>{selectedEventType === 'Online' ? "Event Link*":"Event Link"}</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Event Link"
                                        id={"formSignUpName"}
                                        {...register('event_link', {
                                            required: selectedEventType === 'Online' ? "Event Link is required" : false,
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Event Link should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.event_link && <Form.Text
                                        className="text-muted validationText hasError">{errors.event_link.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            </> : null}

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>{selectedType === 'Event'? "Description*" : "Content*"}</Form.Label>
                                    <textarea id='custom-text-area'
                                        rows={6}
                                               placeholder={`Enter ${selectedType === 'Event'? "Description" : "Content"}`}
                                               {...register('content', {
                                                   required: {
                                                       value: "required",
                                                       message: `${selectedType === 'Event'? "Description" : "Content"} is required`
                                                   },
                                                   pattern:{
                                                       value: /^(?!\s)(?:[\s\S]*\S)?$/gm,
                                                       message:`${selectedType === 'Event'? "Description" : "Content"} should not start with a space`
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 2000,
                                                       message: "Max length is 2000"
                                                   },
                                               })} type="text" />
                                    {errors.content && <Form.Text
                                        className=" validationText hasError">{errors.content.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {selectedType === 'Poll' && (
                            <>
                                {fields.map((field, index) => (
                                    <Row key={field.id}>
                                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                            <Form.Group className="mb-3" controlId={`text${field.id}`}>
                                                <Form.Label>{`Poll Option${index === 0 ? '*' : ` ${index + 1}*`}`}</Form.Label>
                                                <Form.Control
                                                    placeholder="Enter title"
                                                    {...register(`title_${index}`, {
                                                        required: {
                                                            value: true,
                                                            message: "Title is required",
                                                        },
                                                        pattern: {
                                                            value: /^(?!\s).*$/,
                                                            message: "Title should not start with a space",
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            message: "Min length is 3",
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: "Max length is 100",
                                                        },
                                                    })}
                                                    type="text"
                                                />
                                                {errors?.[`title_${index}`] && (
                                                    <Form.Text className="validationText hasError">
                                                        {errors?.[`title_${index}`].message}
                                                    </Form.Text>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                                        <button type="button" className="btn btn-green-theme w-60 mb-3" onClick={addPollField}>
                                            Add Poll
                                        </button>
                                    </Col>
                                    {fields.length !== 1 && (
                                        <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <button type="button" className="btn btn-green-theme w-60 mb-3" onClick={removePollField}>
                                                Remove Poll
                                            </button>
                                        </Col>
                                    )}

                                </Row>
                            </>
                        )}

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Create Post"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewPost;