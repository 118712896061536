import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetTrainerAvailability = async (keyword = '', page = '', perPage = '', is_paginate = 1, trainer_id = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['day'] = keyword;
    }

    if (trainer_id) {
        params['trainer_id'] = trainer_id;
    }
    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.trainer_availability, params);
    return response;
}

export const GetTrainerAvailabilityById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.trainer_availability + '/' + id,params);
    return response;
}

export const CreateTrainerAvailability = async (data) => {
    const response = await POST(apiUrl.trainer_availability, data);
    return response;
}

export const UpdateTrainerAvailability = async (id, data) => {
    const response = await PUT(apiUrl.trainer_availability, id, data);
    return response;
}

export const DeleteTrainerAvailability = async (id) => {
    const response = await DELETE(apiUrl.trainer_availability, id);
    return response;
}
