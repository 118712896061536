import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreatePodcast} from "../../services/podcast.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {CreateArticle} from "../../services/article.service";
import {GetCategory} from "../../services/category.service";
import Select from "react-select";
import {uploadToS3} from "../../utils/helpers";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

function AddNewArticle(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [image, setImage] = useState()
    const [duration, setDuration] = useState(0);
    const [isUpload, setIsUpload] = useState(false)
    const [file, setFIle] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState();
    const [category, setCategory] = useState([]);
    const [loader, setLoader] = useState(false);
    const [editorData, setEditorData] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, watch, handleSubmit, setError, clearErrors,setValue, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(  ()=>{
        getCategory()
    },[])





    const onSubmit = async data => {
        if(file){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }
        if(!editorState.getCurrentContent().hasText()){
            setError('content' ,{message:"Content is required"})
            return false
        }else{
            clearErrors('content')
        }
        if(file?.type.startsWith('image/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }
        if(!selectedCategory){
            setError('category' ,{message:"Category is required"})
            return false
        }else{
            clearErrors('category')
        }
        setLoader(true)
        let uploadImage = await uploadToS3(file.type,file.name,file,'article');

        if (uploadImage) {
            if (!uploadImage) {
                setLoader(false)
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        data.media=uploadImage;

        await CreateArticle({
            category_id:selectedCategory || null,
            title: data.title || '-',
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())) ,
            attachments: [{
                duration: duration,
                is_feature: true,
                mime_type: "image",
                path:  data.media,
                type: 60
            }],
            status: (data?.status && data?.status==1 ? true: false ),


        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/articles');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };

    const getCategory = async () => {
        await GetCategory(null, null, null, 0, true,'Wellness').then((result) => {
            if (result.status && result.data) {
                setCategory(result.data);
            }
        })
    }

    const optionsCategory = (item) => {
        setSelectedCategory(item.value);
        if(!item.value){
            setError('category' ,{message:"Category is required"})
            return false
        }else{
            clearErrors('category')
        }
    }
    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFIle(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }

    }

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
        // const url = apiUrl[props['moduleName']]
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        // setValue(url == "faqs" ? "answer" : 'description', plainText );

    }

    const handleFileUpload = async (file) => {
        // Perform file upload logic here
        // console.log(file);
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        // let uploadedImages = this.state.uploadedImages;



        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        let uploadImage = await uploadToS3(file.type,file.name,file,'article');
        console.log("[uploadImage]", uploadImage)

            if (!uploadImage) {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        // uploadedImages.push(imageObject);

        // this.setState(uploadedImages: uploadedImages)

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: uploadImage } });
            }
        );

    };


    return (
        <div className="AddNewArticle">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Article</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'

                                    />


                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    <br/>
                                    {isUpload ?
                                        <img  src={image} type="image" className={"img-table img-thumbnail mt-2"} controls crossOrigin="anonymous" />:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label className='d-block'>Category*</Form.Label>
                                    <Select
                                        options={category && category?.map(e => ({ label: e.name, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsCategory}

                                    />
                                    {errors.category && <Form.Text className="text-muted validationText hasError">{errors.category.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="cast">
                                    <Form.Label>Content*</Form.Label>
                                    <Editor
                                        {...register('content')}
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            image: {
                                                uploadCallback: handleFileUpload,
                                                previewImage: true,
                                                // alt: { present: true, mandatory: false },
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            },
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                    />
                                    {errors.content && <Form.Text
                                        className=" validationText hasError">{errors.content.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Article"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default AddNewArticle;