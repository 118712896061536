import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Label, Button} from 'react-bootstrap'
import {Logo} from "../../utils/Svg"
import {useForm} from "react-hook-form";
import { useHistory } from "react-router";
import {EmptyLocalStorage, VerifyLoginOtpApi} from "../../services/auth/auth.service"
import {toast} from "react-toastify";
import {FadeLoader} from "react-spinners";
import OTPInput, { ResendOTP } from "otp-input-react";

function VerifyOTP({location}) {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [disabled, setdisabled] = useState(true);
    const [OTP, setOTP] = useState("");
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });


    // useLayoutEffect(() => {
    //     if(!location.state?.email){
    //         history.push('/');
    //     }
    // }, [])

    useLayoutEffect(() => {
        if (OTP.length==5){
            setdisabled(false)
        }else{
            setdisabled(true)
        }
    }, [OTP])

    const onSubmit = async data => {
        setLoader(true)
        let verificationCode = OTP
        let email = location.state.email
        let password = location.state.password
        await VerifyLoginOtpApi(verificationCode, email,password).then((data) => {
            if (data?.status) {
                toast.success(data.message);
                history.push('/dashboard')
                setLoader(false)
            }
            else{
                toast.error(data.message);
                setLoader(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                setLoader(false)
                return toast.error(error.response.data.message);
            }
        })

    };

    return (
        <div className={"text-center authLayout"}>
            <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
                <a href="/">
                    <Logo/>  </a>
                <h1 className="h5 mb-3 font-weight-normal">Login OTP Verification</h1>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <OTPInput  className={"otpInput"} value={OTP} onChange={setOTP}  OTPLength={5} otpType="text"  disabled={false}  />
                    {/*<ResendOTP className={"resendOTP"} onResendClick={() => ("Resend clicked")} />*/}
                </Form.Group>


                <div className="d-grid gap-2">
                    {loader ?<div className="spin-loader"> <FadeLoader color={"#41D2D2"} height={10}/></div>
                        :
                        <Button variant="primary" size="lg" type="submit" className={"btn-green-theme"} disabled={disabled} >
                            Verify OTP
                        </Button>
                    }

                </div>
                <p className="mt-5 mb-3 text-muted">&copy; All rights reserved by Tekrevol {new Date().getFullYear()}</p>
            </Form>
        </div>
    )
}

export default VerifyOTP
