import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import AuthLayoutRoute from "./layouts/AuthLayout";
import Signin from "./views/auth/Signin";
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import Dashboard from "./views/dashboard/Dashboard";
import Users from "./views/users/Users";
import Pages from "./views/pages/Pages";
import AddNewUser from "./views/users/AddNewUser";
import EditUser from "./views/users/EditUser";
import EditPage from "./views/pages/EditPage";
import ChangePassword from "./views/auth/ChangePassword";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./views/auth/ResetPassword";
import ForgotPassword from "./views/auth/ForgotPassword";
import VerifyCode from "./views/auth/VerifyCode";
import PushNotification from "./views/pushNotification/PushNotification";
import ListPushNotification from "./views/pushNotification/ListPushNotification";
import VerifyOTP from "./views/auth/VerifyOTP";
import UserView from "./views/users/UserView";
import ViewProfile from "./views/profile/ViewProfile";
import EditProfile from "./views/profile/EditProfile";
import Goals from "./views/goal/Goals";
import AddNewGoal from "./views/goal/AddNewGoal";
import EditGoal from "./views/goal/EditGoal";
import Music from "./views/music/Music";
import AddNewMusic from "./views/music/AddNewMusic";
import EditMusic from "./views/music/EditMusic";
import Video from "./views/video/Video";
import AddNewVideo from "./views/video/AddNewVideo";
import EditVideo from "./views/video/EditVideo";
import Podcast from "./views/podcast/Podcast";
import EditPodcast from "./views/podcast/EditPodcast";
import AddNewPodcast from "./views/podcast/AddNewPodcast";
import DailyQuote from "./views/dailyQuote/DailyQuote";
import AddNewDailyQuote from "./views/dailyQuote/AddNewDailyQuote";
import EditDailyQuote from "./views/dailyQuote/EditDailyQuote";
import Article from "./views/article/Article";
import AddNewArticle from "./views/article/AddNewArticle";
import EditArticle from "./views/article/EditArticle";
import Plan from "./views/plan/Plan";
import AddNewPlan from "./views/plan/AddNewPlan";
import EditPlan from "./views/plan/EditPlan";
import Category from "./views/category/Category";
import AddNewCategory from "./views/category/AddNewCategory";
import EditCategory from "./views/category/EditCategory";
import Product from "./views/product/Product";
import AddNewProduct from "./views/product/AddNewProduct";
import EditProduct from "./views/product/EditProduct";
import Service from "./views/service/Service";
import AddNewService from "./views/service/AddNewService";
import EditService from "./views/service/EditService";
import Questionnaire from "./views/questionnaire/Questionnaire";
import AddNewQuestion from "./views/questionnaire/AddNewQuestion";
import EditQuestion from "./views/questionnaire/EditQuestion";
import Modules from "./views/module/Modules";
import AddNewModule from "./views/module/AddNewModule";
import EditModule from "./views/module/EditModule";
import ModuleSteps from "./views/moduleSteps/ModuleSteps";
import AddNewModuleStep from "./views/moduleSteps/AddNewModuleStep";
import EditModuleStep from "./views/moduleSteps/EditModuleStep";
import Roles from "./views/role/Roles";
import AddNewRole from "./views/role/AddNewRole";
import EditRole from "./views/role/EditRole";
import Episode from "./views/podcastEpisode/Episode";
import AddNewEpisode from "./views/podcastEpisode/AddNewEpisode";
import EditEpisode from "./views/podcastEpisode/EditEpisode";
import Trainer from "./views/trainer/Trainer";
import AddNewTrainer from "./views/trainer/AddNewTrainer";
import EditTrainer from "./views/trainer/EditTrainer";
import Admin from "./views/admin/Admin";
import AddNewAdmin from "./views/admin/AddNewAdmin";
import EditAdmin from "./views/admin/EditAdmin";
import TrainerView from "./views/trainer/TrainerView";
import TrainerProfile from "./views/trainer/TrainerProfile";
import Journals from "./views/journal/Journals";
import AddNewJournal from "./views/journal/AddNewJournal";
import EditJournal from "./views/journal/EditJournal";
import EditPost from "./views/post/EditPost";
import Posts from "./views/post/Posts";
import AddNewPost from "./views/post/AddNewPost";
import Training from "./views/training/Training";
import AddNewTraining from "./views/training/AddNewTraining";
import EditTraining from "./views/training/EditTraining";
import TrainingSteps from "./views/trainingSteps/TrainingSteps";
import AddNewTrainingStep from "./views/trainingSteps/AddNewTrainingStep";
import EditTrainingStep from "./views/trainingSteps/EditTrainingStep";
import Sessions from "./views/session/Sessions";
import AddNewSession from "./views/session/AddNewSession";
import EditSession from "./views/session/EditSession";
import TrainerVerification from "./views/trainerVerification/TrainerVerification";
import AddNewCode from "./views/trainerVerification/AddNewCode";
import EditCode from "./views/trainerVerification/EditCode";
import Comments from "./views/post/Comments";
import Likes from "./views/post/Likes";
import Order from "./views/order/Order";
import EditOrder from "./views/order/EditOrder";
import EditFaq from "./views/faq/EditFAQ";
import Faq from "./views/faq/FAQ";
import AddNewFaq from "./views/faq/AddFAQ";
import TrainerAvailability from "./views/trainerAvailability/TrainerAvailability";
import AddTrainerAvailability from "./views/trainerAvailability/AddTrainerAvailability";
import EditTrainerAvailability from "./views/trainerAvailability/EditTrainerAvailability";
import Appointments from "./views/appointment/Appointments";
import AddAppointment from "./views/appointment/AddAppointment";
import UpdateAppointment from "./views/appointment/UpdateAppointment";
import Reports from "./views/report/Reports";
import ViewReport from "./views/report/ViewReport";
import Country from "./views/location-data-manager/country/Country";
import AddNewCountry from "./views/location-data-manager/country/AddNewCountry";
import EditCountry from "./views/location-data-manager/country/EditCountry";
import City from "./views/location-data-manager/city/City";
import AddNewCity from "./views/location-data-manager/city/AddNewCity";
import EditCity from "./views/location-data-manager/city/EditCity";
import State from "./views/location-data-manager/state/State";
import AddNewState from "./views/location-data-manager/state/AddNewState";
import EditState from "./views/location-data-manager/state/EditState";
import Transaction from "./views/transaction/Transaction";
import Packages from "./views/package/Packages";
import BreathingVideo from "./views/breathing-video/BreathingVideo";
import Ads from "./views/ads/Ads";
import EditAds from "./views/ads/EditAds";
import EditPackages from "./views/package/EditPackages";
import Notifications from "./views/notification/Notification";
import Vat from "./views/vat/Vat";



function App() {
  return (
    <Router>
      <Switch>


        {/**
         *
         *  Define all the page routes here
         *
        */}

        <DashboardLayoutRoute exact isAuth={false} path={`/profile`} component={ViewProfile} />
        <DashboardLayoutRoute exact isAuth={false} path={`/edit-profile/:id`} component={EditProfile} />
        <DashboardLayoutRoute exact isAuth={false} path={`/change-password`} component={ChangePassword} />

        <DashboardLayoutRoute exact isAuth={false} path={`/dashboard`} component={Dashboard} />


        <DashboardLayoutRoute exact isAuth={false} path={`/manage-users`} component={Users}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/manage-users/add-user`} component={AddNewUser} />
        <DashboardLayoutRoute exact isAuth={false} path={`/manage-users/edit-user/:id`} component={EditUser} />
        <DashboardLayoutRoute exact isAuth={false} path={`/manage-users/view-user/:id`} component={UserView} />

        <DashboardLayoutRoute exact isAuth={false} path={`/admins`} component={Admin}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/admins/add-admin`} component={AddNewAdmin} />
        <DashboardLayoutRoute exact isAuth={false} path={`/admins/edit-admin/:id`} component={EditAdmin} />

        <DashboardLayoutRoute exact isAuth={false} path={`/professionals`} component={Trainer}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/professionals/add-professional`} component={AddNewTrainer} />
        <DashboardLayoutRoute exact isAuth={false} path={`/professionals/edit-professional/:id`} component={EditTrainer} />
        <DashboardLayoutRoute exact isAuth={false} path={`/professionals/view-professional/:id`} component={TrainerView} />
        <DashboardLayoutRoute exact isAuth={false} path={`/professionals/update-professional-status/:id`} component={EditTrainer} />

        <DashboardLayoutRoute exact isAuth={false} path={`/trainer-verification`} component={TrainerVerification}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/trainer-verification/add-code`} component={AddNewCode} />
        <DashboardLayoutRoute exact isAuth={false} path={`/trainer-verification/edit-code/:id`} component={EditCode} />

        <DashboardLayoutRoute exact isAuth={false} path={`/breathing-video`} component={BreathingVideo}/>

        <DashboardLayoutRoute exact isAuth={false} path={`/roles`} component={Roles}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/roles/add-role`} component={AddNewRole} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/roles/edit-role/:id`} component={EditRole} />

        <DashboardLayoutRoute exact isAuth={false} path={`/goals`} component={Goals}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/goals/add-goal`} component={AddNewGoal} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/goals/edit-goal/:id`} component={EditGoal} />


        <DashboardLayoutRoute exact isAuth={false} path={`/musics`} component={Music}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/musics/add-music`} component={AddNewMusic} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/musics/edit-music/:id`} component={EditMusic} />

        <DashboardLayoutRoute exact isAuth={false} path={`/videos`} component={Video}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/videos/add-video`} component={AddNewVideo} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/videos/edit-video/:id`} component={EditVideo} />

        <DashboardLayoutRoute exact isAuth={false} path={`/podcasts`} component={Podcast}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/podcasts/add-podcast`} component={AddNewPodcast} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/podcasts/edit-podcast/:id`} component={EditPodcast} />

        <DashboardLayoutRoute exact isAuth={false} path={`/daily-quotes`} component={DailyQuote}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/daily-quotes/add-daily-quote`} component={AddNewDailyQuote} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/daily-quotes/edit-daily-quote/:id`} component={EditDailyQuote} />

        <DashboardLayoutRoute exact isAuth={false} path={`/articles`} component={Article}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/articles/add-article`} component={AddNewArticle} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/articles/edit-article/:id`} component={EditArticle} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/articles/view-article/:id`} component={EditArticle} />

        <DashboardLayoutRoute exact isAuth={false} path={`/plans`} component={Plan}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/plans/add-plan`} component={AddNewPlan} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/plans/edit-plan/:id`} component={EditPlan} />


        <DashboardLayoutRoute exact isAuth={false} path={`/categories`} component={Category}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/categories/add-category`} component={AddNewCategory} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/categories/edit-category/:id`} component={EditCategory} />

        <DashboardLayoutRoute exact isAuth={false} path={`/products`} component={Product}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/products/add-product`} component={AddNewProduct} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/products/edit-product/:id`} component={EditProduct} />

        <DashboardLayoutRoute exact isAuth={false} path={`/podcast-episodes`} component={Episode}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/podcast-episodes/add-episode`} component={AddNewEpisode} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/podcast-episodes/edit-episode/:id`} component={EditEpisode} />

        <DashboardLayoutRoute exact isAuth={false} path={`/services`} component={Service}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/services/add-service`} component={AddNewService} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/services/edit-service/:id`} component={EditService} />

        <DashboardLayoutRoute exact isAuth={false} path={`/journals`} component={Journals}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/journals/add-journal`} component={AddNewJournal} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/journals/edit-journal/:id`} component={EditJournal} />

        <DashboardLayoutRoute exact isAuth={false} path={`/posts`} component={Posts}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/posts/add-post`} component={AddNewPost} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/posts/edit-post/:id`} component={EditPost} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/posts/view-post/:id`} component={EditPost} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/posts/comments/:id`} component={Comments} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/posts/likes/:id`} component={Likes} />

        <DashboardLayoutRoute exact isAuth={false} path={`/questionnaire`} component={Questionnaire}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/questionnaire/add-question`} component={AddNewQuestion} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/questionnaire/edit-question/:id`} component={EditQuestion} />

        <DashboardLayoutRoute exact isAuth={false} path={`/modules`} component={Modules}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/modules/add-module`} component={AddNewModule} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/modules/edit-module/:id`} component={EditModule} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/modules/view-module/:id`} component={EditModule} />


        <DashboardLayoutRoute exact isAuth={false} path={`/module-steps`} component={ModuleSteps}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/module-steps/add-module-step`} component={AddNewModuleStep} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/module-steps/edit-module-step/:id`} component={EditModuleStep} />


        <DashboardLayoutRoute exact isAuth={false} path={`/trainings`} component={Training}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/trainings/add-training`} component={AddNewTraining} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/trainings/edit-training/:id`} component={EditTraining} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/trainings/view-training/:id`} component={EditTraining} />

        <DashboardLayoutRoute exact isAuth={false} path={`/training-steps`} component={TrainingSteps}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/training-steps/add-training-step`} component={AddNewTrainingStep} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/training-steps/edit-training-step/:id`} component={EditTrainingStep} />

        <DashboardLayoutRoute exact isAuth={false} path={`/sessions`} component={Sessions}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/sessions/add-session`} component={AddNewSession} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/sessions/edit-session/:id`} component={EditSession} />

        <DashboardLayoutRoute exact isAuth={false} path={`/countries`} component={Country}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/countries/add-country`} component={AddNewCountry} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/countries/edit-country/:id`} component={EditCountry} />

        <DashboardLayoutRoute exact isAuth={false} path={`/cities`} component={City}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/cities/add-city`} component={AddNewCity} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/cities/edit-city/:id`} component={EditCity} />

        <DashboardLayoutRoute exact isAuth={false} path={`/states`} component={State}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/states/add-state`} component={AddNewState} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/states/edit-state/:id`} component={EditState} />

        <DashboardLayoutRoute exact isAuth={false} path={`/faqs`} component={Faq}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/faqs/add-faq`} component={AddNewFaq} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/faqs/edit-faq/:id`} component={EditFaq} />

        <DashboardLayoutRoute exact isAuth={false} path={`/trainer-availability`} component={TrainerAvailability}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/trainer-availability/add-availability`} component={AddTrainerAvailability} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/trainer-availability/edit-availability/:id`} component={EditTrainerAvailability} />

        <DashboardLayoutRoute exact isAuth={false} path={`/appointments`} component={Appointments}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/appointments/add-appointment`} component={AddAppointment} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/appointments/edit-appointment/:id`} component={UpdateAppointment} />

        <DashboardLayoutRoute exact isAuth={false} path={`/sessions`} component={Sessions}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/sessions/add-session`} component={AddNewSession} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/sessions/edit-session/:id`} component={EditSession} />

        <DashboardLayoutRoute exact isAuth={false} path={`/reports`} component={Reports}/>
        <DashboardLayoutRoute exact  isAuth={false} path={`/reports/view-report/:id`} component={ViewReport} />

        <DashboardLayoutRoute exact isAuth={false} path={`/pages`} component={Pages}/>
        <DashboardLayoutRoute exact  isAuth={false} path={`/pages/edit-page/:id`} component={EditPage} />

        <DashboardLayoutRoute exact isAuth={false} path={`/orders`} component={Order}/>
        <DashboardLayoutRoute exact  isAuth={false} path={`/orders/update-order-status/:id`} component={EditOrder} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/orders/view/:id`} component={EditOrder} />

        <DashboardLayoutRoute exact  isAuth={false} path={`/push-notification`} component={PushNotification} />
        <DashboardLayoutRoute exact  isAuth={false} path={`/push-notification-history`} component={ListPushNotification} />

        <DashboardLayoutRoute exact isAuth={false} path={`/transactions`} component={Transaction}/>

        <DashboardLayoutRoute exact isAuth={false} path={`/packages`} component={Packages}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/packages/edit-package/:id`} component={EditPackages}/>

        <DashboardLayoutRoute exact isAuth={false} path={`/ads`} component={Ads}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/ads/:id`} component={EditAds}/>

        <DashboardLayoutRoute exact isAuth={false} path={`/notifications`} component={Notifications}/>
        <DashboardLayoutRoute exact isAuth={false} path={`/settings/tax`} component={Vat}/>




          {/**
           *
           *  Authentication routes
           *
           */}
        <AuthLayoutRoute exact isAuth={true} path="/forgot-password" component={ForgotPassword} />
        <AuthLayoutRoute exact isAuth={true} path="/verify-code" component={VerifyCode} />
        <AuthLayoutRoute exact isAuth={true} path="/reset-password" component={ResetPassword} />
        <AuthLayoutRoute exact isAuth={true} path="/verify-otp" component={VerifyOTP} />
          <AuthLayoutRoute exact isAuth={true} path="/" component={Signin}/>



          {/**
           *
           *  Error routes
           *
           */}


    



      </Switch>
      <ToastContainer hideProgressBar autoClose={2000} />
    </Router>
  );
}

export default App;
