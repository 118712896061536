import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetModule = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null,category=null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (category) {
        params['category_id'] = category;
    }

    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }

    const response = await GET(apiUrl.module, params);
    return response;
}

export const GetStepsCompleted = async (keyword = '', page = '', perPage = '', is_paginate = 1,user=null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }


    if (user) {
        params['user_id'] = user;
    }

    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }

    const response = await GET(apiUrl.module_step_completed, params);
    return response;
}

export const GetModuleById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.module + '/' + id,params);
    return response;
}

export const CreateModule = async (data) => {
    const response = await POST(apiUrl.module, data);
    return response;
}

export const UpdateModule = async (id, data) => {
    const response = await PUT(apiUrl.module, id, data);
    return response;
}

export const DeleteModule = async (id) => {
    const response = await DELETE(apiUrl.module, id);
    return response;
}
