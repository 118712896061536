import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {constants, customStyles, IS} from "../../utils/constants";
import {useForm} from "react-hook-form";
import {GetTrainerById, GetUserById} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import moment from "moment";
import {Button, Col, Form, Nav, Row, Tab} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {FadeLoader} from "react-spinners";
import {GetStepsCompleted} from "../../services/training.service";
import {GetRating} from "../../services/rating.service";
// import DocViewer from "react-doc-viewer";
// import {DocViewerRenderers} from "react-doc-viewer";

function TrainerView(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [trainingStepData, setTrainingStepsData] = useState([]);
    const [ratingData, setRatingData] = useState([]);
    const [answerData, setAnswerData] = useState([]);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('tab1');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [defaultImage, setDefaultImage] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [rows, setRows] = useState([{
        id: 1,
        name: 'John Doe',
        email: 'johndoe@example.com',
        gender: 'Male',
        dob: '1990-01-01',
        phone: '1234567890',
        address: '123 Main St, City',
    },
        {
            id: 2,
            name: 'Jane Smith',
            email: 'janesmith@example.com',
            gender: 'Female',
            dob: '1995-05-15',
            phone: '9876543210',
            address: '456 Elm St, Town',
        },]);


    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format


    const onSubmit = async data => {
        setLoader(true)
        history.push('/professionals');
    };

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    useEffect(async () => {
        let id = props.match.params.id;
        await getTrainerById(id);
        await getStepsCompleted(id);
        await getRating(id);
        await getUserByid(id);
    }, [])

    const getUserByid = async (id) => {
        await GetUserById(id).then(async (result) => {
            if (result?.status) {
                if (result?.data) {
                    let data=[];
                    data.push(result?.data)
                    setData(data);
                    setValue("username", result.data?.full_name);
                    setValue("email", result.data?.email);
                    setValue("address", result.data?.address);
                    setValue("dob" ,result.data?.dob ? new Date(result.data?.dob).toLocaleDateString('en-CA') : '');
                    setValue("gender", result.data?.gender);
                    setValue("type", result.data?.type);
                    await setDefaultImage(result.data?.image || constants?.USERIMAGENOTFOUND)
                }

            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getTrainerById = async (id) => {
        await GetTrainerById(id).then(async (result) => {
            if (result.status) {
                if (result?.data) {
                    setProfileData(result?.data);
                    console.log(profileData)
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getStepsCompleted = async (id) => {
        await GetStepsCompleted(keyword, page, perPage,1,id).then((result) => {
            if (result.status) {
                if (result.data.user_trainings) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.user_trainings.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            training:dt?.trainings[0]?.title || '-',
                            training_image:dt?.trainings[0]?.attachment[0]?.path || '-',
                            step: dt.steps[0]?.title || '-',
                            step_content: dt.steps[0]?.content || '-',
                            completed_at:  moment(dt.completed_at).format("MM/DD/YYYY hh:mm A") || '-',
                        });

                    })
                    console.log(rowData)
                    setTrainingStepsData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getRating = async (id) => {
        await GetRating(keyword, page, perPage,1,id).then((result) => {
            if (result.status) {
                if (result.data.ratings) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.ratings.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            appointment_id:dt?.appointment_id || '-',
                            rating:dt?.rating || '-',
                            review:dt?.review || '-',
                            rated_by:`${dt?.rated_by?.full_name} (${dt?.rated_by?.email})` || '-',
                            created_at:  moment(dt.created_at).format("MM/DD/YYYY hh:mm A") || '-',
                        });

                    })
                    console.log(rowData)
                    setRatingData(rowData);
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const TrainingStepscolumns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'60px',
            minWidth:'20px',
            selector: rowData => rowData.id,
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth:'100px',
            minWidth:'20px',
            center: true,
            selector: rowData => rowData.training_image,
            format: rowData => <img src={rowData.training_image}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Broken'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'training',
            name: 'Training',
            grow: false,
            center: true,
            selector: rowData => rowData.training,
        },
        {
            id: 'step',
            name: 'Step',
            grow: false,
            center: true,
            selector: rowData => rowData.step,
        },
        {
            id: 'step_content',
            name: 'Step Content',
            maxWidth:'240px',
            minWidth:'360px',
            grow: false,
            center: true,
            selector: rowData => rowData.step_content,
        },
        {
            id: 'completed_at',
            name: 'Completed At',
            grow: false,
            center: true,
            selector: rowData => rowData.completed_at,
        },
    ];

    const Ratingcolumns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'60px',
            minWidth:'20px',
            selector: rowData => rowData.id,
        },
        {
            id: 'appointment_id',
            name: 'Appointment Id',
            grow: false,
            center: true,
            selector: rowData => rowData.appointment_id,
        },
        {
            id: 'rating',
            name: 'Rating',
            grow: false,
            center: true,
            selector: rowData => rowData.rating,
        },
        {
            id: 'review',
            name: 'Review',
            maxWidth:'240px',
            minWidth:'360px',
            grow: false,
            center: true,
            selector: rowData => rowData.review,
        },
        {
            id: 'rated_by',
            name: 'Rated By',
            maxWidth:'200px',
            minWidth:'300px',
            grow: false,
            center: true,
            selector: rowData => rowData.rated_by,
        },
        {
            id: 'created_at',
            name: 'Submitted At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
    ];

    return (
        <div className="ViewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">View Trainer</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <div className="img-cover img-thumbnail">
                                        <img onError={({currentTarget}) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} src={ defaultImage} className="user-profile"/>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        {...register('username')} type="text"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        {...register('email')} type="email" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select
                                        disabled
                                        id={"gender"}
                                        type="text"
                                        placeholder="Select Gender"
                                        {...register("gender")}
                                    >
                                        <option selected disabled value="">Select Gender</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Non-binary"}>Non-binary</option>
                                        <option value={"Transgender"}>Transgender</option>
                                        <option value={"Gender Neutral"}>Gender Neutral</option>
                                        <option value={"Agender"}>Agender</option>
                                        <option value={"Pangender"}>Pangender</option>
                                        <option value={"Two-spirit"}>Two-spirit</option>
                                        <option value={"Genderqueer"}>Genderqueer</option>
                                        <option value={"Third Gender"}>Third Gender</option>
                                        <option value={"Gender Fluid"}>Gender Fluid</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>DOB*</Form.Label>
                                    <Form.Control
                                        disabled
                                        id={"age"}
                                        placeholder="Enter Age"
                                        value={age.date}
                                        max={maxDate}
                                        {...register('dob')} type="date"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Type*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Type"
                                        id={"formSignUpName"}
                                        {...register('type')} type="text"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address*</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Enter Address"
                                        autocomplete="off"
                                        autofill="off"
                                        {...register('address')} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab1">Profile</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab2">Reviews</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab3">Training Step Detail</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tab1">
                                            <h4>Profile</h4>
                                                <ul className={'order-list'}>
                                                    {Array.isArray(data[0]?.license) && data[0]?.license.length > 0 ?
                                                        data[0]?.license.map((item,index)=>{
                                                            return <li className="license-list" key={index}>
                                                                <span className={'license-number'}>{index + 1}</span>
                                                                <div className={'license'} >
                                                                <span className={'order-list-title'}>License: </span> <Button type={'button'} className={"btn btn-filter-theme pt-0 pb-0"} onClick={()=>{
                                                                window.open((item?.path), '_blank');
                                                            }}>
                                                                View License
                                                            </Button>
                                                            </div>
                                                            <div >
                                                                <span className={'order-list-title'}>License Verification: </span>{item?.is_verified ? "Verified" : "Unverified"}
                                                            </div>
                                                            </li>
                                                        })
                                                        :
                                                        <li >
                                                            <span className={'order-list-title'}>License: </span>{ "-"}
                                                        </li>
                                                    }

                                                    <li >
                                                        <span className={'order-list-title'}>Bio: </span>{data[0]?.trainer_profile?.bio || '-' }
                                                    </li>
                                                    <li >
                                                        <span className={'order-list-title'}>Type: </span>{data[0]?.trainer_profile?.type || '-'}
                                                    </li>
                                                    <li >
                                                        <span className={'order-list-title'}>Status: </span>{data[0]?.status || '-'}
                                                    </li>
                                                    <li >
                                                        <span className={'order-list-title'}>Stripe Id: </span>{data[0]?.stripe_customer_id || '-'}
                                                    </li>
                                                    <li >
                                                        <span className={'order-list-title'}>Location: </span>{data[0]?.trainer_profile?.location || '-'}
                                                    </li>
                                                    <li>
                                                        <span className="order-list-title">Services: </span>
                                                        {profileData?.services?.length > 0 ? profileData?.services.map((item, index) => (
                                                            <span key={index}>{item.title || '-'} ,</span>
                                                        )):  <span >{'-'}</span>}
                                                    </li>

                                                    {data[0]?.trainer_profile?.certifications ? data[0]?.trainer_profile?.certifications.map((item,index) => (
                                                    <li key={index} >
                                                        <span className={'order-list-title'}>Certificate {index+1}:
                                                        </span> <Button type={'button'} className={"btn btn-filter-theme pt-0 pb-0"} onClick={()=>{
                                                        window.open((item.path), '_blank');
                                                    }}>
                                                        View Certificate
                                                    </Button>
                                                    </li>
                                                        )): <li >
                                                        <span className={'order-list-title'}>Certifications: </span>{'-'}
                                                    </li>}

                                                    <li >
                                                        <span className={'order-list-title'}>Average Rating: </span>{profileData?.averageRating }
                                                    </li>
                                                    <li >
                                                        <span className={'order-list-title'}>Total Rating: </span>{profileData?.totalRatings }
                                                    </li>


                                                </ul>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab2">
                                            <h4>Reviews</h4>
                                            <div className="theme-dataGrid products-dataGrid">
                                                <DataTable
                                                    pagination
                                                    columns={Ratingcolumns}
                                                    data={ratingData}
                                                    // actions={actionsMemo}
                                                    // data={data}
                                                    progressPending={loading}
                                                    customStyles={customStyles}
                                                    selectableRows={false}
                                                    striped
                                                    highlightOnHover

                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={perPage}
                                                    onChangePage={(page) => {
                                                        setPage(page);
                                                    }}
                                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                                        setPerPage(currentRowsPerPage)
                                                    }}
                                                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                                                  height={'4'} width={'100%'} />}
                                                />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab3">
                                            <h4>Training Step Detail</h4>
                                            <div className="theme-dataGrid products-dataGrid">
                                                <DataTable
                                                    pagination
                                                    columns={TrainingStepscolumns}
                                                    data={trainingStepData}
                                                    // actions={actionsMemo}
                                                    // data={data}
                                                    progressPending={loading}
                                                    customStyles={customStyles}
                                                    selectableRows={false}
                                                    striped
                                                    highlightOnHover

                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={perPage}
                                                    onChangePage={(page) => {
                                                        setPage(page);
                                                    }}
                                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                                        setPerPage(currentRowsPerPage)
                                                    }}
                                                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                                                  height={'4'} width={'100%'} />}
                                                />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Back"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    );
}

export default TrainerView;