import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeleteSession, GetSession} from "../../services/session.service";
import {AVAILABLE, customStyles, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {DeleteTrainerAvailability, GetTrainerAvailability} from "../../services/traineravailability.service";
import {GetUsers} from "../../services/users.service";
import Select from "react-select";
import ExportCsv from "../../components/ExportCSV";

function TrainerAvailability(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');
    const typingTimeoutRef = useRef(null);
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [exportRows, setExportRows] = useState();

    useEffect(async () => {
        await getUsers();
       await getTrainerAvailability();
    }, [keyword, page, perPage,selectedUser])
    

    function convertToLcalTime(time) {
        const utcTime = moment.utc(time, 'HH:mm');

// Convert UTC time to local time
        const localTime = utcTime.local().format('HH:mm');

        const [hours, minutes] = localTime.split(':');

        let formattedHours = parseInt(hours);
        let period = 'AM';

        if (formattedHours === 0) {
            formattedHours = 12;
        } else if (formattedHours === 12) {
            period = 'PM';
        } else if (formattedHours > 12) {
            formattedHours -= 12;
            period = 'PM';
        }

        const formattedTime = `${formattedHours}:${minutes.padStart(2, '0')} ${period}`;
        return formattedTime;
    }

    const getTrainerAvailability = async () => {
        setLoading(true)
        await GetTrainerAvailability(keyword, page, perPage,1,selectedUser).then((result) => {
            if (result?.status) {
                if (result?.data?.trainer_availabilities) {
                    const rowData = [];
                    const exportData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result?.data?.pagination?.total);
                    result?.data?.trainer_availabilities?.map((dt, index) => {
                        rowData.push({
                            id: dt._id || '-',
                            trainer:`${dt?.trainer[0]?.full_name} (${dt?.trainer[0]?.email})` || '-',
                            day: dt.day || '-',
                            start_time:convertToLcalTime(dt?.start_time) || '-',
                            end_time: convertToLcalTime(dt?.end_time) || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            availability: AVAILABLE[dt.availability] || '-',
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-'),
                        });

                        exportData.push({
                            id: dt._id || '-',
                            professional:`${dt?.trainer[0]?.full_name} (${dt?.trainer[0]?.email})` || '-',
                            day: dt.day || '-',
                            start_time:convertToLcalTime(dt?.start_time) || '-',
                            end_time: convertToLcalTime(dt?.end_time) || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            availability: AVAILABLE[dt.availability] || '-',
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-'),
                        });

                    })
                    setRows(rowData);
                    setExportRows(exportData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onTrainerAvailabilityDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this availability?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteTrainerAvailability(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getTrainerAvailability();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/trainer-availability/edit-availability/` + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onTrainerAvailabilityDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            grow: false,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'trainer',
            name: 'Professional',
            minWidth:'220px',
            maxWidth:'300px',
            grow: false,
            center: true,
            selector: rowData => rowData.trainer,
        },
        {
            id: 'day',
            name: 'Day',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.day,
        },
        {
            id: 'start_time',
            name: 'Start Time',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.start_time,
        },
        {
            id: 'end_time',
            name: 'End Time',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.end_time,
        },
        {
            id: 'availability',
            name: 'Availability',
            width: '150px',
            grow: false,
            center: true,
            selector: rowData => rowData.availability,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    const getUsers = async () => {
        await GetUsers(false, 'Trainer', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setUsers(result.data);
            }
        })
    }

    const optionsUsers = (item) => {
        setUserValue({ label: item.label, value:item.value })
        setSelectedUser(item.value);
    }
    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"availabilityPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Professional Availability</h1>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search for day"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                                type="text"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset: 2}} lg={{span: 2, offset: 2}} xl={{span: 2, offset: 2}}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/trainer-availability/add-availability"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline/> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Form.Group className="mb-3" controlId="users">
                            <Select
                                value={userValue}
                                placeholder={'Filter professional'}
                                options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                className="basic-multi-select"
                                onChange={optionsUsers}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={ 3} lg={3} xl={2}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setUserValue(null)
                            setSelectedUser(null)
                        }}>
                            Reset Filter
                        </Button>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 3}} xl={{ span: 2, offset: 4}}>
                        <ExportCsv exportRows={exportRows} fileName={"professionals_availability"}/>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </div>
    )
}

export default TrainerAvailability;