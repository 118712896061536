import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {uploadToS3} from "../../utils/helpers";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetBreathingVideoByid, getBreathingVideoByid, UpdateBreathingVideo} from "../../services/users.service";
import {GetVat, GetVatById, UpdateVat} from "../../services/settings.service";

function Vat(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [id, setId] = useState(null);
    const [isUpload, setIsUpload] = useState(false)
    const [loader, setLoader] = useState(false);
    const [update, setUpdate] = useState(false);
    const {register, watch, handleSubmit, setError, clearErrors,setValue, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        await getVatByid();

    }, [])

    const getVatByid = async () => {
        await GetVat('vat','','',0).then((result) => {
            if (result.status) {
                if (result.data?.length) {
                    setData(result.data[0]);
                    setId(result.data[0]._id)
                    if(result.data[0]?.value){
                        setValue('vat',result.data[0]?.value)
                        setIsUpload(true)
                    }

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }



    const onSubmit = async data => {

        setLoader(true)

        let dt = {
                value:parseFloat(data.vat)
        }

        await UpdateVat(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                setUpdate(false)
                toast.success(data.message);
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };


    return (
        <div className="EditVideo">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Tax Settings</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Tax*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Tax"
                                        id={"formSignUpName"}
                                        disabled={!update}
                                        {...register('vat', {
                                            required: {
                                                value: "required",
                                                message: "Tax is required"
                                            },
                                            pattern: {
                                                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                                message: "Enter a valid tax value"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },

                                        })}
                                        type="number"
                                        step="0.01"
                                    />
                                    {errors.vat && <Form.Text
                                        className="text-muted validationText hasError">{errors.vat.message}</Form.Text>}

                                </Form.Group>
                                </Col>
                            </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"button"} onClick={()=> {
                                    setUpdate(!update)
                                    clearErrors('vat')
                                }} className={"btn btn-green-theme w-100 mt-3"}
                                       value={update ? "Preview" :"Update"}/>
                            </Col>
                            {update &&
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Submit"}/>}
                            </Col>
                            }
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default Vat;