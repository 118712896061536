import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetPost = async (user='',category='',keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['content'] = keyword;
    }

    if (user) {
        params['user_id'] = user;
    }

    if (category) {
        params['category'] = category;
    }


    if (status) {
        params['status'] = status;
    }
    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.post, params);
    return response;
}

export const GetComment = async (id='',keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['keyword'] = keyword;
    }

    if (id) {
        params['post_id'] = id;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.comments, params);
    return response;
}

export const GetLike = async (id='',keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['keyword'] = keyword;
    }

    if (id) {
        params['post_id'] = id;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.likes, params);
    return response;
}

export const GetPostById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.post + '/' + id,params);
    return response;
}

export const CreatePost = async (data) => {
    const response = await POST(apiUrl.post, data);
    return response;
}

export const UpdatePost = async (id, data) => {
    const response = await PUT(apiUrl.post, id, data);
    return response;
}

export const DeletePost = async (id) => {
    const response = await DELETE(apiUrl.post, id);
    return response;
}

export const DeleteComment = async (id) => {
    const response = await DELETE(apiUrl.comments, id);
    return response;
}