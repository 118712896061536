import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetSessionById, UpdateSession} from "../../services/session.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetOrderById, UpdateOrder} from "../../services/order.service";
import {GetType} from "../../services/users.service";
import moment from "moment";
import {log10} from "chart.js/helpers";

function EditOrder(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [slug, setSlug] = useState(false);
    const [type, setType] = useState();
    const [address, setAddress] = useState('');
    const {register, watch, handleSubmit,setValue, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const selectedStatus = watch('status');
    const getType = async () => {
        await GetType("OrderStatus").then((result) => {
            if (result.status && result.data) {
                const OrderStatus =  result.data[0].OrderStatus;
                setType(Object.values(OrderStatus));
            }
        })
    }

    useEffect(async () => {
        let id = props.match.params.id;
        const endpoint = window.location.href;
        const hasViewSegment = endpoint.includes("view");
        if(hasViewSegment){
            setSlug(true)
        }else{
            setSlug(false)
        }
        await getType();
        await getOrderByid(id)


    }, [])

    const getAddress = async (latitude, longitude) => {
        console.log(latitude,longitude)
        const apiKey = 'YOUR_GOOGLE_MAPS_API_KEY';
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.results.length > 0) {
                return data.results[0].formatted_address;
            } else {
                return 'No address found';
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };



    const getOrderByid = async (id) => {
        await GetOrderById(id).then( async (result) => {
            if (result?.status) {
                if (result?.data) {
                    let orderData=[]
                    orderData.push(result?.data)
                    setData(orderData);
                    setValue("status", (result?.data?.status));
                    setValue("reason", (result?.data?.reason));
                    let location = await getAddress(result?.data?.shipping_address?.latitude , result?.data?.shipping_address?.longitude)
                    setAddress(location)
                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }

    const onSubmit = async data => {
        setLoader(true)
        if(slug){
            history.push('/orders');
            return false
        }
        let id = props.match.params.id;
        let dt = {
            reason: data.reason || '-',
            status:data.status,
        }

        await UpdateOrder(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/orders');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };
    return (
        <div className="Order">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">{slug ? "View Order" : "Update Order Status"}</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {
                                    data.length == 0 ?  <ul className={'order-list'} >
                                        <li >
                                            <span className={'order-list-title'}>Order Number: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Tracking Id: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Customer Name: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Customer Email: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Phone: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Shipping Address Details: </span>
                                            <ul className={'shipping-list'}>
                                                <li >
                                                    <span className={'shipping-list-title'}>Name: </span>-
                                                </li>
                                                <li >
                                                    <span className={'shipping-list-title'}>Email: </span>-
                                                </li>
                                                <li >
                                                    <span className={'shipping-list-title'}>Address: </span>-
                                                </li>
                                                <li >
                                                    <span className={'shipping-list-title'}>Country: </span>-
                                                </li>
                                                <li >
                                                    <span className={'shipping-list-title'}>State: </span>-
                                                </li>
                                                <li >
                                                    <span className={'shipping-list-title'}>City: </span>-
                                                </li>

                                                <li >
                                                    <span className={'shipping-list-title'}>Zip Code: </span>-
                                                </li>
                                            </ul>
                                        </li>

                                        <li >
                                            <span className={'order-list-title'}>Stripe Customer Id: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Location: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Amount: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Payment Status: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Status: </span>-
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Order Date: </span>-
                                        </li>

                                    </ul> :
                                        (data?.map((item) => (
                                            <ul className={'order-list'} key={item._id}>
                                                <li >
                                                    <span className={'order-list-title'}>Order Number: </span>{item.order_number}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Tracking Id: </span>{item?.tracking_id || '-'}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Customer Name: </span>{item.user?.full_name}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Customer Email: </span>{item.user?.email}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Phone: </span>{item.shipping_address?.phone}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Shipping Address Details: </span>
                                                    <ul className={'shipping-list'}>
                                                        <li >
                                                            <span className={'shipping-list-title'}>Name: </span>{item.shipping_address?.name || '-'}
                                                        </li>
                                                        <li >
                                                            <span className={'shipping-list-title'}>Email: </span>{item.shipping_address?.email_address || '-'}
                                                        </li>
                                                        <li >
                                                            <span className={'shipping-list-title'}>Address: </span>{item.shipping_address?.address || '-'}
                                                        </li>
                                                        <li >
                                                            <span className={'shipping-list-title'}>Country: </span>{item.shipping_address?.country || '-'}
                                                        </li>
                                                        <li >
                                                            <span className={'shipping-list-title'}>State: </span>{item.shipping_address?.state || '-'}
                                                        </li>
                                                        <li >
                                                            <span className={'shipping-list-title'}>City: </span>{item.shipping_address?.city || '-'}
                                                        </li>

                                                        <li >
                                                            <span className={'shipping-list-title'}>Zip Code: </span>{item.shipping_address?.zip_code || '-'}
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li >
                                                    <span className={'order-list-title'}>Stripe Customer Id: </span>{item.user?.stripe_customer_id}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Location: </span>{address}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Amount: </span>{item.amount}$
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Payment Status: </span>{item?.payment_status}
                                                </li>
                                                <li >
                                                    <span className={'order-list-title'}>Status: </span>{item.status}
                                                </li>
                                                {item.status === "Cancelled" &&
                                                    <li >
                                                        <span className={'order-list-title'}>Cancelled By: </span>{`${item?.cancelled_by?.full_name} (${item?.cancelled_by?.email})`}
                                                    </li>
                                                }
                                                <li >
                                                    <span className={'order-list-title'}>Order Date: </span>{ moment(item.created_at).format("MM/DD/YYYY hh:mm A")}
                                                </li>

                                            </ul>
                                        )))
                                }



                            </Col>
                        </Row>
                        <Row>
                            {data[0]?.order_items?.map((item, index) => (
                                <Col xs={12} sm={12} md={4} lg={3} xl={2} key={index} className={"mt-3"}>
                                    <div className="card" style={{ width: '100%',height: '100%' }}>
                                        <img src={item.product.attachments[0].path} className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">{item.product.title}</h5>
                                            <p className="card-text">  <span className={'order-list-title'}>Price: </span>{item.product.price}$</p>
                                            <p className="card-text">  <span className={'order-list-title'}>Quantity: </span>{item.quantity}</p>
                                            <p className="card-text">  <span className={'order-list-title'}>Total Amount: </span>{item.amount} $</p>

                                        </div>
                                    </div>
                                </Col>
                            ))}

                        </Row>
                        {!slug ?  <Row>
                            <Col  xs={12} sm={12} md={8} lg={3} xl={3}>
                                <Form.Group className={"mt-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option selected disabled value="">--Select--</option>
                                        {type && type?.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>: null}

                        {selectedStatus === "Cancelled" ?
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Form.Group className="mt-3" controlId="text">
                                        <Form.Label>Reason*</Form.Label>
                                        <textarea  id='custom-text-area' rows={6} cols={55}
                                                   placeholder="Enter Reason"
                                                   {...register('reason', {
                                                       required: {
                                                           value: "required",
                                                           message: "Reason is required"
                                                       },
                                                       pattern:{
                                                           value:/^(?!\s).*$/,
                                                           message:"Reason should not start with a space"
                                                       },
                                                       minLength: {
                                                           value: 3,
                                                           message: "Min length is 3"
                                                       },
                                                       maxLength: {
                                                           value: 500,
                                                           message: "Max length is 500"
                                                       },
                                                   })} type="text" />
                                        {errors.reason && <Form.Text
                                            className=" validationText hasError">{errors.reason.message}</Form.Text>}

                                    </Form.Group>
                                </Col>
                            </Row>: null
                        }
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={3} xl={3} className={"mt-3"}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={slug ? "Back" : "Update Order Status"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditOrder;