import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {uploadToS3} from "../../utils/helpers";
import {toast} from "react-toastify";
import {CreatePodcast, GetPodcast} from "../../services/podcast.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {CreateMusic} from "../../services/music.service";
import {CreatePodcastEpisode} from "../../services/podcastepisode.service";
import Select from "react-select";
import {GetModule} from "../../services/module.service";

function AddNewEpisode(props) {
    let history = useHistory();
    const audioRef = useRef(null);
    const [image, setImage] = useState()
    const [imageFile, setImageFile] = useState(null)
    const [music, setMusic] = useState()
    const [duration, setDuration] = useState(0);
    const [isUpload, setIsUpload] = useState(false)
    const [isImageUpload, setIsImageUpload] = useState(false)
    const [file, setFile] = useState(null)
    const [loader, setLoader] = useState(false);
    const [podcast, setPodcast] = useState([]);
    const [selectedPodcast, setSelectedPodcast] = useState();
    const {register, watch, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });


    const handleLoadedMetadata = () => {
        setDuration( Math.round(audioRef.current?.duration));
    }
    useEffect(  ()=>{
        getPodcast()
    },[])


    const onSubmit = async data => {
        if(file){
            clearErrors('media')
        }else{
            setError('media',{message:"Audio is required"})
        }
        if(imageFile){
            clearErrors('image')
        }else{
            setError('image',{message:"Image is required"})
        }
        if(file.type.startsWith('audio/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }

        if(imageFile?.type.startsWith('image/')){
            clearErrors('image')
        }else{
            setError('image',{message:'Invalid file type'})
            return false;
        }
        setLoader(true)

        let uploadAudio = await uploadToS3(file.type,file.name,file,'episode');

        if (uploadAudio) {
            if (!uploadAudio) {
                setLoader(false)
                toast.error(uploadAudio.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Audio');
            return false;
        }
        data.media=uploadAudio;

        let uploadImage = await uploadToS3(imageFile.type,imageFile.name,imageFile,'episode');

        if (uploadImage) {
            if (!uploadImage) {
                setLoader(false)
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        data.image=uploadImage;
        await CreatePodcastEpisode({
            title: data.title || '-',
            podcast_id:selectedPodcast || null,
            image: data.image,
            attachments: [{
                duration: duration,
                is_feature: true,
                mime_type: "audio",
                path: data.media,
                type: 120
            }],
            status: (data?.status && data?.status==1 ? true: false ),


        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/podcast-episodes');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };


    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setMusic(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(file){
            clearErrors('media')
        }else{
            setError('media',{message:"Audio is required"})
        }

    }

    const getPodcast = async () => {
        await GetPodcast(null, null, null, 0).then((result) => {
            if (result.status && result.data) {
                setPodcast(result.data);
            }
        })
    }
    const optionsPodcast = (item) => {
        setSelectedPodcast(item.value);
        if(!item.value){
            setError('podcast' ,{message:"Podcast is required"})
            return false
        }else{
            clearErrors('podcast')
        }
    }

    const uploadImageFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setImageFile(fileInput[0])
        setIsImageUpload(true)
        if(file){
            clearErrors('image')
        }else{
            setError('image',{message:"Image is required"})
        }

    }

    return (
        <div className="AddNewEpisode">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Podcast Episode</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('image')}
                                        onChange={(e) => uploadImageFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'

                                    />

                                    {errors.image && <Form.Text
                                        className=" validationText hasError">{errors.image.message}</Form.Text>}
                                    <br/>
                                    {isImageUpload ?
                                        <img  src={image} type="image" className={"img-table img-thumbnail mt-2"} controls crossOrigin="anonymous" />:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Audio*<Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                   <br/>
                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.mp3'}
                                        class="custom-file-input"
                                        id='image'

                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    {isUpload ?
                                        <audio ref={audioRef} src={music} type="audio/acc" className={"mt-2"} controls crossOrigin="anonymous" onLoadedMetadata={handleLoadedMetadata}/>:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="podcast">
                                    <Form.Label className='d-block'>Podcast*</Form.Label>
                                    <Select
                                        options={podcast && podcast?.map(e => ({ label: e.title, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsPodcast}

                                    />
                                    {errors.podcast && <Form.Text className="text-muted validationText hasError">{errors.podcast.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Podcast Episode"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default AddNewEpisode;