import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetTransaction = async (keyword = '', page = '', perPage = '', is_paginate = 1, type = null,user_id='') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (user_id) {
        params['user_id'] = user_id;
    }

    if (type) {
        params['status'] = type;
    }

    if (keyword) {
        params['transaction_id'] = keyword;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.transaction, params);
    return response;
}

export const DeleteTransaction = async (id) => {
    const response = await DELETE(apiUrl.transaction, id);
    return response;
}