import React, {useEffect, useRef, useState} from 'react'
import {Row, Col, Button, Form} from 'react-bootstrap';
import NavLink from "react-bootstrap/NavLink";
import DashboardStat from "../../components/DashboardStat";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Link, useHistory} from "react-router-dom";
import ThemeChart from "../../components/Chart";
import {useForm} from "react-hook-form";
import {GetDashboardData} from "../../services/dashboard.service";

function Dashboard() {
    let history = useHistory();
    const bottomDivRef = useRef(null);
    const [userCount, setUserCount] = useState(0);
    const [productCount, setProductCount] = useState(0);
    const [categoryCount, setCategoryCount] = useState(0);
    const [musicCount, setMusicCount] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [podcastCount, setPodcastCount] = useState(0);
    const [articleCount, setArticleCount] = useState(0);
    const [dailyQuoteCount, setDailyQuoteCount] = useState(0);
    const [serviceCount, setServiceCount] = useState(0);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [totalTrainerCount, setTotalTrainerCount] = useState(0);
    const [totalAdminCount, setTotalAdminCount] = useState(0);
    const [search, setSearch] = useState("daily");

    const {register, watch, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });







    useEffect(async () => {
        getDashboardData()

    }, [search])



    const getDashboardData = async () => {


        await GetDashboardData({search},0).then((result) => {

            if (result?.status) {
                if (result?.data) {
                    setUserCount(result?.data?.userCount);
                    setProductCount(result?.data?.productCount);
                    setCategoryCount(result?.data?.categoryCount);
                    setMusicCount(result?.data?.musicCount);
                    setVideoCount(result?.data?.videoCount);
                    setPodcastCount(result?.data?.podcastCount);
                    setArticleCount(result?.data?.articleCount);
                    setDailyQuoteCount(result?.data?.dailyQuoteCount);
                    setServiceCount(result?.data?.serviceCount);
                    setTotalUserCount(result?.data?.totalUserCount);
                    setTotalTrainerCount(result?.data?.totalTrainerCount);
                    setTotalAdminCount(result?.data?.totalAdminCount);


                }
            } else {

                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }
    const handleChange=(e)=>{
        const value = e.target.value;
        setSearch(value)
        (value,'value')

    }
    function handleClick() {
        bottomDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div className='dashboard'>
            <Row  className={"mt-3"} >
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <h1 className="page-heading">Dashboard</h1>
                    <hr/>
                </Col>
            </Row>
            <Row className={"mb-4 mt-2 "}>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/manage-users"} className={"linkStats"}>
                        <DashboardStat title={"Total Registered Accounts"} countMain={userCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/manage-users"} className={"linkStats"}>
                        <DashboardStat title={"Total Users"} countMain={totalUserCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/professionals"} className={"linkStats"}>
                        <DashboardStat title={"Total Professionals"} countMain={totalTrainerCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/admins"} className={"linkStats"}>
                        <DashboardStat title={"Total Admin Accounts"} countMain={totalAdminCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/products"} className={"linkStats"}>
                        <DashboardStat title={"Total Products"} countMain={productCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/categories"} className={"linkStats"}>
                        <DashboardStat title={"Total Categories"} countMain={categoryCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/musics"} className={"linkStats"}>
                        <DashboardStat title={"Total Musics Uploaded"} countMain={musicCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/videos"} className={"linkStats"}>
                        <DashboardStat title={"Total Videos Uploaded"} countMain={videoCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/Podcasts"} className={"linkStats"}>
                        <DashboardStat title={"Total Podcasts Uploaded"} countMain={podcastCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/Articles"} className={"linkStats"}>
                        <DashboardStat title={"Total Articles Uploaded"} countMain={articleCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/daily-quotes"} className={"linkStats"}>
                        <DashboardStat title={"Total Daily Quotes Uploaded"} countMain={dailyQuoteCount} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/services"} className={"linkStats"}>
                        <DashboardStat title={"Total Services"} countMain={serviceCount} />
                    </Link>
                </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-3"}>*/}
            {/*                <Row>*/}
            {/*                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className={""}>*/}
            {/*                        <h3 className="page-heading dashboard-heading">Current Active/Inactive Users</h3>*/}
            {/*                    </Col>*/}
            {/*                    <Col xs={12} sm={12} md={2} lg={2} xl={2} className={""}>*/}
            {/*                        <Form.Group  controlId="filter">*/}
            {/*                            <Form.Select className='formselect' aria-label="status" {...register('filter',{*/}
            {/*                                onChange: handleChange,*/}
            {/*                            })}>*/}
            {/*                                <option value="daily" selected>Daily</option>*/}
            {/*                                <option value="weekly">Weekly</option>*/}
            {/*                                <option value="monthly">Monthly</option>*/}
            {/*                                <option value="yearly">Yearly</option>*/}
            {/*                            </Form.Select>*/}
            {/*                        </Form.Group>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*    </Col>*/}

            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-4"}>*/}
            {/*        <ThemeChart datasets={usersDataSets} />*/}
            {/*    </Col>*/}
            {/*</Row>*/}


            {/*<Row>*/}
            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-3"} ref={bottomDivRef}>*/}
            {/*                <h3 className="page-heading dashboard-heading">Total Queries to the AI</h3>*/}
            {/*    </Col>*/}

            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-4"}>*/}
            {/*        <ThemeChart datasets={totalQueriesDataSets}  />*/}
            {/*    </Col>*/}
            {/*</Row>*/}

        </div>
    )
}

export default Dashboard
