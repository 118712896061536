import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {GetType, GetUsers} from "../../services/users.service";
import {DeleteOrder, GetOrder} from "../../services/order.service";
import moment from "moment";
import Papa from "papaparse";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import {MenuDots} from "../../components/SvgIcons";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {customStyles, TransactionStatus} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import {DeleteTransaction, GetTransaction} from "../../services/transaction.service";
import ExportCsv from "../../components/ExportCSV";

function Transaction(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [type, setType] = useState();
    const [selectedType, setSelectedType] = useState('');
    const [exportRows, setExportRows] = useState();


    useEffect(  async () => {
        await getUsers();
        await getTransaction();
        window.scrollTo(0, 0);
    }, [keyword, page, perPage,selectedUser,selectedType])


    const getType = async () => {
        await GetType("OrderStatus").then((result) => {
            if (result.status && result.data) {
                const OrderStatus =  result.data[0].OrderStatus;
                setType(Object.values(OrderStatus));
            }
        })
    }

    const getTransaction = async () => {
        setLoading(true)
        await GetTransaction(keyword, page, perPage,1,selectedType,selectedUser).then((result) => {
            if (result.status) {
                if (result.data.transactions) {
                    const rowData = [];
                    const exportData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.transactions.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_name: `${dt?.user?.full_name} (${dt?.user?.email})` || '-',
                            package_name: dt.package.name || '-',
                            transaction_id: dt.transaction_id || '-',
                            ends_on: moment(dt.ends_on).format("MM/DD/YYYY hh:mm A") || '-',
                            status: dt?.status.replace(/_/g, ' '),
                            price: dt?.price,
                            store: dt?.store.replace(/_/g, ' '),
                            created_at: moment(dt.created_at).format("MM/DD/YYYY"),
                        });

                        exportData.push({
                            user:`${dt?.user?.full_name} (${dt?.user?.email})` || '-',
                            package: dt?.package.name || '-',
                            transaction_id: dt?.transaction_id || '-',
                            ends_on:dt?.ends_on || '-',
                            status:dt?.status || '-',
                            price:dt?.price || '-',
                            payload:dt?.payload || '-',
                            store:dt?.store || '-',
                            created_at: moment(dt?.created_at).format('MM/DD/YYYY hh:mm A'),
                        });

                    })

                    setRows(rowData);
                    setExportRows(exportData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onTransactionDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this transaction?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteTransaction(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getTransaction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onTransactionDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }


    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '60px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'user_name',
            name: 'User',
            width: '260px',
            sortable: true,
            selector: rowData => rowData.user_name,
        },
        {
            id: 'package_name',
            name: 'Package',
            center: true,
            width: '120px',
            selector: rowData => rowData.package_name,
        },
        {
            id: 'transaction_id',
            name: 'Transaction Id',
            width: '340px',
            grow: true,
            center: true,
            selector: rowData => rowData.transaction_id,
        },
        {
            id: 'ends_on',
            name: 'Ends On',
            width: '200px',
            center: true,
            selector: rowData => rowData.ends_on,
        },
        {
            id: 'status',
            name: 'Status',
            width: '220px',
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'price',
            name: 'Price ($)',
            maxWidth: '120px',
            center: true,
            selector: rowData => rowData.price,
        },
        {
            id: 'store',
            name: 'Store',
            maxWidth: '120px',
            center: true,
            selector: rowData => rowData.store,
        },
        {
            id: 'created_at',
            name: 'Created At',
            maxWidth: '140px',
            minWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];


    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setUsers(result.data);
            }
        })
    }

    const optionsUsers = (item) => {
        setUserValue({ label: item.label, value:item.value })
        setSelectedUser(item.value);
    }

    const filterType = async (value) => {
        setSelectedType(value)
    }

    return (
        <div className={"transactionPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Transactions</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>
                        <Form.Group className="mb-3" controlId="users">
                            <Select
                                value={userValue}
                                placeholder={'Filter user'}
                                options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                className="basic-multi-select"
                                onChange={optionsUsers}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select id={"type"}
                                         type="text"
                                         placeholder="Filter Type"
                                         onChange={(e) => filterType(e.target.value)}
                                         value={selectedType}
                            >
                                <option selected disabled value="">Filter Type</option>
                                {TransactionStatus && TransactionStatus?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={ ()=>{
                            setUserValue(null)
                            setSelectedUser(null)
                            setSelectedType('')
                        }}>
                            Reset Search
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={{ span: 4, offset: 8 }} lg={{ span: 2, offset: 10 }} xl={{ span: 2, offset: 10 }}>
                        <ExportCsv exportRows={exportRows} fileName={"transactions"}/>
                    </Col>
                </Row>



            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Transaction;