import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateGoal} from "../../services/goal.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetType, GetUsers} from "../../services/users.service";
import {GetCategory} from "../../services/category.service";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {GetSession} from "../../services/session.service";
import {CreateAppointment, GetAvailableTimeslots} from "../../services/appointment.service";
import {GetTrainerTimeslots} from "../../services/users.service";
import {date} from "yup";
import moment from "moment";
import {DAYS} from "../../utils/constants";

function AddAppointment(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [startDate, setStartDate] = useState({ format: "mm/dd/yyyy" })
    const [date, setDate] = useState(false)
    const [loader, setLoader] = useState(false);
    const [selectedTrainer, setSelectedTrainer] = useState();
    const [trainer, setTrainer] = useState([]);
    const [users, setUsers] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [timeslots, setTimeslots] = useState([]);
    const [trainerAvailability,setTrainerAvailability] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState();
    const [selectedSession, setSelectedSession] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [type, setType] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    // Format the next day as "YYYY-MM-DD" for setting the minDate
    const minDate = new Date().toISOString().split('T')[0];

    const getAppointmentType = async () => {
        await GetType("AppointmentType").then((result) => {
            if (result.status && result.data) {
                const AppointmentType =  result.data[0].AppointmentType;
                setType(Object.values(AppointmentType));
            }
        })
    }

    const getPaymentMethod = async () => {
        await GetType("PaymentMethod").then((result) => {
            if (result.status && result.data) {
                const PaymentMethod =  result.data[0].PaymentMethod;
                setPaymentMethod(Object.values(PaymentMethod));
            }
        })
    }


    useEffect(  async ()=>{
        await getAppointmentType();
        await getPaymentMethod();
        await getUsers();
        await getTrainers();
        await getSessions();
    },[])

    // useEffect(  async ()=>{
    //     await getTrainerAvailability()
    // },[selectedTrainer])

    useEffect(  async ()=>{
        if (selectedTrainer && selectedSession && startDate && date) {
            await getAvailableTimeslots();
        }
    },[selectedTrainer,selectedSession,startDate,date])

    const onSubmit = async data => {
        if(!selectedTrainer && !selectedUser && !selectedSession ){
            setError('trainer' ,{message:"Trainer is required"})
            setError('user' ,{message:"User is required"})
            setError('session' ,{message:"Session is required"})
            return false
        }else{
            clearErrors('trainer')
            clearErrors('user')
            clearErrors('session')
        }
        if(!selectedTrainer){
            setError('trainer' ,{message:"Trainer is required"})
            return false
        }else{
            clearErrors('category')
        }
        if(!selectedUser){
            setError('user' ,{message:"User is required"})
            return false
        }else{
            clearErrors('user')
        }
        if(!selectedSession){
            setError('session' ,{message:"Session is required"})
            return false
        }else{
            clearErrors('session')
        }
        setLoader(true)


        const combinedDateTime = `${data?.start_date}T${data?.start_time}:00.000Z`;
        const utcDate = new Date(combinedDateTime);
        const formattedDateTime = utcDate.toISOString().slice(0, 16).replace("T", " ");
        await CreateAppointment({
            user_id:selectedUser,
            trainer_id:selectedTrainer,
            session_id:selectedSession,
            date: data.start_date  || '-',
            start_time: data.start_time  || '-',
            end_time: data.end_time  || '-',
            duration_in_mins: data.duration_in_mins  || '-',
            timestamp: formattedDateTime  || '-',
            // payment_method: data.payment_method  || '-',
            // user_card_id: data.user_card_id  || '-',

        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/appointments');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }

    const getTrainers = async () => {
        await GetUsers(false, 'Trainer', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setTrainer(result.data);
            }
        })
    }

    const getAvailableTimeslots= async () => {

        const date = moment(startDate, 'DD/MM/YYYY').format( 'MM/DD/YYYY');
         // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        await GetAvailableTimeslots( date,selectedSession,selectedTrainer).then((result) => {
            if (result.status) {
                if( result.data != null && result.data.length > 0){
                    setSelectedAvailability(null)
                    setTimeslots(result.data);
                }
            }
        }).catch((error) => {
            console.log(error,"err")
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                    setTimeslots([]);
                setSelectedAvailability(null)

            }
        })
    }
    const getSessions= async () => {
        await GetSession(null,  null,null,null, null).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setSessions(result.data);
            }
        })
    }

    const getTrainerAvailability= async () => {
        await GetTrainerTimeslots(selectedTrainer,  selectedSession,startDate).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setTrainerAvailability(result.data);
            }
        })
    }

    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setDate(true)
        setStartDate(date.toLocaleDateString())
    }

    const optionsUsers = (item) => {
        setSelectedUser(item.value);
        if(!item.value){
            setError('user' ,{message:"User is required"})
        }else{
            clearErrors('user')
        }
    }

    const optionsTrainer = (item) => {
        setSelectedTrainer(item.value);
        if(!item.value){
            setError('trainer' ,{message:"Trainer is required"})
        }else{
            clearErrors('trainer')
        }
    }

    const optionsSession= (item) => {
        setSelectedSession(item.value);
            const matchingObject = sessions.find(obj => obj._id === item.value);
            if (matchingObject) {
                setValue('duration_in_mins', matchingObject.time_in_mins);
            }
        if(!item.value){
            setError('session' ,{message:"Session is required"})
        }else{
            clearErrors('session')
        }
    }

    const optionsAvailability= (item) => {
        setSelectedAvailability({label :item.label , value:item.value});
        const matchingObject = timeslots.find((obj, index) => obj.id === item.value);
        if (matchingObject) {
            setValue('start_time', matchingObject.start_time);
            setValue('end_time', matchingObject.end_time);
        }
        if(!item.value){
            setError('time_slot' ,{message:"Time Slot is required"})
        }else{
            clearErrors('time_slot')
        }
    }

    function convertTo12HourFormat(time) {
        const utcTime = moment.utc(time, 'HH:mm');

// Convert UTC time to local time
        const localTime = utcTime.local().format('HH:mm');

        const [hours, minutes] = localTime.split(':');

        let formattedHours = parseInt(hours);
        let period = 'AM';

        if (formattedHours === 0) {
            formattedHours = 12;
        } else if (formattedHours === 12) {
            period = 'PM';
        } else if (formattedHours > 12) {
            formattedHours -= 12;
            period = 'PM';
        }

        const formattedTime = `${formattedHours}:${minutes.padStart(2, '0')} ${period}`;
        return formattedTime;
    }

    return (
        <div className="AddNewAppointment">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Appointment</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>User*</Form.Label>
                                    <Select
                                        options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}
                                    />
                                    {errors.user && <Form.Text className="text-muted validationText hasError">{errors.user.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="trainer">
                                    <Form.Label className='d-block'>Professional*</Form.Label>
                                    <Select
                                        options={trainer && trainer?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsTrainer}
                                    />
                                    {errors.trainer && <Form.Text className="text-muted validationText hasError">{errors.trainer.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="trainer">
                                    <Form.Label className='d-block'>Session*</Form.Label>
                                    <Select
                                        options={sessions && sessions?.map(e => ({ label: `${e.price}$ (${e.time_in_mins} min)`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsSession}
                                    />
                                    {errors.session && <Form.Text className="text-muted validationText hasError">{errors.session.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date*</Form.Label>
                                    <Form.Control
                                        id={"start_date"}
                                        placeholder="Enter Date"
                                        value={startDate.date}
                                        min={minDate}
                                        maxLength={3}
                                        {...register('start_date', {
                                            required: {
                                                value: "required",
                                                message: "Date is required"
                                            },
                                            onChange: (e) => onDateChange(e),
                                        })} type="date"
                                    />
                                    {errors.start_date && <Form.Text
                                        className=" validationText hasError">{errors.start_date.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {selectedTrainer && selectedSession && startDate && date ?
                                <>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="trainer">
                                        <Form.Label className='d-block'>Select Time Slot*</Form.Label>
                                        <Select
                                            value={selectedAvailability}
                                            options={timeslots && timeslots?.map((e) => ({ label: `${convertTo12HourFormat(e.start_time)} - ${convertTo12HourFormat(e.end_time)}`, value:e.id }))}
                                            className="basic-multi-select"
                                            onChange={optionsAvailability}
                                        />
                                        {errors.time_slot && <Form.Text className="text-muted validationText hasError">{errors.time_slot.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                                {timeslots.length > 0  ?  null :   <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                        <Form.Text className="text-muted mb-3">Time slots for selected date is not available</Form.Text>
                                    </Col> }
                                    </>
                                : null}
                        </Row>
                        {/*<Row>*/}
                        {/*    /!*<Col  xs={12} sm={12} md={8} lg={6} xl={6}>*!/*/}
                        {/*    /!*    <Form.Group className={"mb-3"} controlId="status">*!/*/}
                        {/*    /!*        <Form.Label className='d-block'>Select Appointment Type*</Form.Label>*!/*/}
                        {/*    /!*        <Form.Select className='formselect' aria-label="type" {...register('appointment_type', {*!/*/}
                        {/*    /!*            required: {*!/*/}
                        {/*    /!*                value: "required",*!/*/}
                        {/*    /!*                message: "Appointment Type is required"*!/*/}
                        {/*    /!*            }*!/*/}
                        {/*    /!*        })}>*!/*/}
                        {/*    /!*            <option value={""} disabled selected hidden>-- Select --</option>*!/*/}
                        {/*    /!*            {type && type.map(value => (*!/*/}
                        {/*    /!*                <option key={value} value={value}>{value}</option>*!/*/}
                        {/*    /!*            ))}*!/*/}
                        {/*    /!*        </Form.Select>*!/*/}
                        {/*    /!*        {errors.appointment_type && <Form.Text*!/*/}
                        {/*    /!*            className=" validationText hasError">{errors.appointment_type.message}</Form.Text>}*!/*/}
                        {/*    /!*    </Form.Group>*!/*/}
                        {/*    /!*</Col>*!/*/}
                        {/*    /!*<Col  xs={12} sm={12} md={8} lg={6} xl={6}>*!/*/}
                        {/*    /!*    <Form.Group className={"mb-3"} controlId="status">*!/*/}
                        {/*    /!*        <Form.Label className='d-block'>Select Payment Method*</Form.Label>*!/*/}
                        {/*    /!*        <Form.Select className='formselect' aria-label="type" {...register('payment_method', {*!/*/}
                        {/*    /!*            required: {*!/*/}
                        {/*    /!*                value: "required",*!/*/}
                        {/*    /!*                message: "Payment Method is required"*!/*/}
                        {/*    /!*            }*!/*/}
                        {/*    /!*        })}>*!/*/}
                        {/*    /!*            <option value={""} disabled selected hidden>-- Select --</option>*!/*/}
                        {/*    /!*            {paymentMethod && paymentMethod.map(value => (*!/*/}
                        {/*    /!*                <option key={value} value={value}>{value}</option>*!/*/}
                        {/*    /!*            ))}*!/*/}
                        {/*    /!*        </Form.Select>*!/*/}
                        {/*    /!*        {errors.payment_method && <Form.Text*!/*/}
                        {/*    /!*            className=" validationText hasError">{errors.payment_method.message}</Form.Text>}*!/*/}
                        {/*    /!*    </Form.Group>*!/*/}
                        {/*    /!*</Col>*!/*/}
                        {/*    /!*<Col xs={12} sm={12} md={12} lg={12} xl={12}>*!/*/}
                        {/*    /!*    <Form.Group className="mb-3" controlId="text">*!/*/}
                        {/*    /!*        <Form.Label>User Card Id*</Form.Label>*!/*/}
                        {/*    /!*        <Form.Control*!/*/}
                        {/*    /!*            placeholder="Enter User Card Id"*!/*/}

                        {/*    /!*            {...register('user_card_id', {*!/*/}
                        {/*    /!*                // required: {*!/*/}
                        {/*    /!*                //     value: "required",*!/*/}
                        {/*    /!*                //     message: "User Card Id is required"*!/*/}
                        {/*    /!*                // },*!/*/}
                        {/*    /!*                pattern:{*!/*/}
                        {/*    /!*                    value:/^(?!\s).*$/,*!/*/}
                        {/*    /!*                    message:"User Card Id should not start with a space"*!/*/}
                        {/*    /!*                },*!/*/}
                        {/*    /!*                minLength: {*!/*/}
                        {/*    /!*                    value: 1,*!/*/}
                        {/*    /!*                    message: "Min length is 1"*!/*/}
                        {/*    /!*                },*!/*/}
                        {/*    /!*                maxLength: {*!/*/}
                        {/*    /!*                    value: 300,*!/*/}
                        {/*    /!*                    message: "Max length is 300"*!/*/}
                        {/*    /!*                },*!/*/}
                        {/*    /!*            })} type="text"*!/*/}
                        {/*    /!*        />*!/*/}
                        {/*    /!*        {errors.user_card_id && <Form.Text*!/*/}
                        {/*    /!*            className=" validationText hasError">{errors.user_card_id.message}</Form.Text>}*!/*/}

                        {/*    /!*    </Form.Group>*!/*/}
                        {/*    /!*</Col>*!/*/}
                        {/*</Row>*/}

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Create Appointment"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddAppointment;