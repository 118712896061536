import React from 'react';
import {Modal} from "react-bootstrap";
import {constants} from "../utils/constants";

function ImagePreview(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size={props.size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`Image-Modal ${props?.className}` }
        >
            <Modal.Header className={`Image-Modal-Header`} closeButton>
            </Modal.Header>
            <Modal.Body className={`Image-Modal-Body`}>
                <img src={props.content} id='img-preview' alt="" onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = constants.IMAGENOTFOUND;
                }}
                     alt={'Broken'} crossOrigin={"anonymous"}/>
            </Modal.Body>
        </Modal>
    );
}

export default ImagePreview;