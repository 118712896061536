import { apiUrl,s3Credential } from '../utils/constants';
import {DELETE, GET, POST, POST_S3, PUT} from './api.service.wrapper';
import { GetAuthUser, GetUserRole } from "../services/auth/auth.service"

const user = GetAuthUser();
let role = GetUserRole();

export const GetProfile = async () => {
    let response = await GET(apiUrl.profile);
    return response;
}

export const UpdateProfile = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.update_profile, id, data, config);
    return response;
}
export const GetType = async (enumType = '') => {
    let params = {};

    if (enumType) {
        params['enumType'] = enumType;
    }

    const response = await GET(apiUrl.enum_type, params);
    return response;
}

export const GetTrainerTimeslots = async (id,session_id = '',date='') => {
    let params = {};

    if (session_id) {
        params['session_id'] = session_id;
    }
    if (date) {
        params['date'] = date;
    }
    const response = await GET(apiUrl.timeslots+ '/' + id, params);
    return response;
}

export const GetBreathingVideoByid = async () => {
    const response = await GET(apiUrl.breathing_video);
    return response;
}
export const GetUsers = async (email = false,type = '',gender = '',keyword = '', page = '', perPage = '', is_paginate = 1,status='',trainer_type='') => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }


    if (gender) {
        params['gender'] = gender;
    }

    if (type) {
        params['type'] = type;
    }

    if (trainer_type) {
        params['trainer_type'] = trainer_type;
    }

    if (status) {
        params['status'] = status;
    }

    if(is_paginate==1){
       params['paginate'] = is_paginate;
     }

    const response = await GET(apiUrl.users, params);
    return response;
}

export const GetUserById = async (id) => {
    let response = '';
    response = await GET(apiUrl.users + '/' + id);
    return response;
}

export const GetTrainerById = async (id) => {
    let response = '';
    response = await GET(apiUrl.trainer + '/' + id);
    return response;
}

export const CreateUser = async (data) => {
    const response = await POST(apiUrl.users, data);
    return response;
}

export const UpdateUser = async (id, data) => {
    const response = await PUT(apiUrl.users, id, data);
    return response;
}

export const UpdateBreathingVideo = async (id, data) => {
    const response = await PUT(apiUrl.breathing_video, id, data);
    return response;
}

export const UpdateTrainerProfile = async (id, data) => {
    const response = await PUT(apiUrl.trainer, id, data);
    return response;
}

export const UpdateProfilePartial = async (id, data) => {
    const response = await PUT(apiUrl.partial, id, data);
    return response;
}
export const DeleteUser = async (id) => {
    const response = await DELETE(apiUrl.users, id);
    return response;
}

export const ChatNotification = async (data) => {
    const response = await POST(apiUrl.chat_notification, data);
    return response;
}


export const getPresignedPostUrl = async (fileType, fileName) => {
    const config = {
        headers: {
            'x-access-token': s3Credential.app_token
        }
    }
    let response = '';
    response = await POST_S3(apiUrl.get_s3_token,fileType,config);
    return response.data;
}