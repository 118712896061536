import React, {useContext, useEffect, useState} from 'react'
import {Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {GetProfile, UpdateProfile, UpdateUser} from "../../services/users.service";
import {UploadIcon} from "../../components/SvgIcons";
import {toast} from "react-toastify";
import {EmptyLocalStorage, GetAccessToken, GetUserRole, SetAuthUser} from "../../services/auth/auth.service";
import {constants} from "../../utils/constants";
import {uploadToS3} from "../../utils/helpers";
import {FadeLoader} from "react-spinners";

function EditProfile(props) {
    let history = useHistory();
    const [formData, setFormData] = useState([]);
    const [file, setFileData] = useState(false);
    const [image, setImage] = useState();
    const [loadedImage, setloadedImage] = useState();
    const [defaultImage, setDefaultImage] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format
    useEffect(async () => {
        getProfile();
    }, []);

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data) {
                    setFormData(result.data)
                    let dt = result.data;
                    if (dt) {
                        setValue('id', dt.id);
                        setValue('full_name', dt.full_name);
                        setValue('email', dt.email);
                        setValue('type', dt.type);
                        setValue("address",dt.address);
                        setValue("dob" ,dt.dob ? new Date(dt.dob).toLocaleDateString('en-CA') : '');
                        setValue("gender",dt.gender);
                        setloadedImage(dt?.image)
                        await setDefaultImage(dt?.image|| constants.USERIMAGENOTFOUND)
                    }
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
            toast.error(error.response.data.message);
        })
    }

    const onSubmit = async data => {
        setLoader(true)
        if(isUploadOverWrite){
            let uploadImage = await uploadToS3(image.type,image.name,image,'user');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            data.media=uploadImage;
        }else{
            data.media= loadedImage
        }
        let id = props.match.params.id;
        let dt = {
            full_name: data.full_name,
            phone: data.phone,
            address: data.address,
            dob:data.dob,
            gender:data.gender,
            image: data.media,
            type:formData.type,
            role_id:formData.role_id,
            device_type: 'web',
            device_token: 'user',
        }

        await UpdateUser(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/profile');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };
    const onAgeChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setAge(date.toLocaleDateString())
    }

    const handleChange = (event) => {
        let uploadfile = URL.createObjectURL(event.target.files[0])
        setImage(event.target.files[0])
        setFileData(URL.createObjectURL(event.target.files[0]));
        if(uploadfile){
            setIsUploadOverWrite(true)
        }
    }


    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">Edit Profile</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form encType="multipart/form-data" className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row className={"mb-3 justify-content-center align-items-center"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations mb-0"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img onError={({currentTarget}) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = constants.IMAGENOTFOUND;
                                            }} src={file ? file : defaultImage} width="50%" height="50%" crossOrigin={"anonymous"}/>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group controlId="formFile" onChange={(e) => handleChange(e)}
                                                className="custom-file-upload mb-0">
                                        <Form.Label className={"toggleFileUpload mb-0"}>
                                            <UploadIcon/>
                                            Upload Image
                                        </Form.Label>
                                        <Form.Control type="file"
                                                      accept="image/png, image/jpeg, image/jpg, image/gif" {...register('media')} />

                                    </Form.Group>
                                </Col>

                            </Row>
                        </Col>
                        <Form.Group className="mb-3" controlId="formFullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Full Name"
                                name="full_name"
                                {...register("full_name", {
                                        onChange: (e) => {
                                            setValue('full_name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Full name is required"
                                        },
                                    pattern:{
                                        value:/^(?!\s).*$/,
                                        message:"Full Name should not start with a space"
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "Min length is 3"
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: "Max length is 50"
                                    },
                                    }
                                )}
                                maxLength={"255"}/>
                            <Form.Text className="text-muted validationText hasError">
                                {errors.full_name && <Form.Text
                                    className="text-muted validationText hasError">{errors.full_name.message}</Form.Text>}
                            </Form.Text>
                        </Form.Group>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter Email Address"
                                        readOnly
                                        {...register("email")}
                                        maxLength={"255"}/>
                                    <Form.Text className="text-muted validationText hasError">
                                        {errors.email && <Form.Text
                                            className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="formContact">*/}
                            {/*        <Form.Label>Phone Number</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            type="tel"*/}
                            {/*            placeholder="Enter Phone Number"*/}
                            {/*            {...register("phone", {*/}
                            {/*                    onChange: (e) => {*/}
                            {/*                        setValue('phone', e.target.value)*/}
                            {/*                    },*/}
                            {/*                    required: {*/}
                            {/*                        value: "required",*/}
                            {/*                        message: "Phone is required"*/}
                            {/*                    }*/}
                            {/*                }*/}
                            {/*            )}*/}
                            {/*            maxLength={"16"}/>*/}
                            {/*        <Form.Text className="text-muted validationText hasError">*/}
                            {/*            {errors.phone && <Form.Text*/}
                            {/*                className="text-muted validationText hasError">{errors.phone.message}</Form.Text>}*/}
                            {/*        </Form.Text>*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select
                                        id={"gender"}
                                        type="text"
                                        placeholder="Select Gender"
                                        {...register("gender", {
                                                required: {
                                                    value: "required",
                                                    message: "Gender is required"
                                                }
                                            }
                                        )}
                                    >
                                        <option selected disabled value="">Select Gender</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Non-binary"}>Non-binary</option>
                                    </Form.Select>
                                    {errors.gender && <Form.Text
                                        className=" validationText hasError">{errors.gender.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>DOB*</Form.Label>
                                    <Form.Control
                                        id={"age"}
                                        placeholder="Enter Age"
                                        value={age.date}
                                        max={maxDate}
                                        maxLength={3}
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "DOB is required"
                                            },
                                            onChange: (e) => onAgeChange(e),
                                        })} type="date"
                                    />
                                    {errors.dob && <Form.Text
                                        className=" validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('address', {
                                            required: {
                                                value: "required",
                                                message: "Address is required"
                                            },
                                            maxLength: {
                                                value: 500,
                                                message: "Max length is 500"
                                            },
                                        })} type="text" />
                                    {errors.address && <Form.Text
                                        className="text-muted validationText hasError">{errors.address.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#41D2D2"} height={10} />
                                </div> :
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                       value={"Save"} />}
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default EditProfile

