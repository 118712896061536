import { GET} from "./api.service.wrapper";
import {apiUrl} from "../utils/constants";

export const GetDashboardData = async (keyword = '', is_paginate = 1) => {
    let params = {};

    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }
    const response = await GET(apiUrl.dashboard, params);
    return response;
}

