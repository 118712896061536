import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetJournal = async (user ='', keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['text'] = keyword;
    }

    if (user) {
        params['user_id'] = user;
    }

    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }

    const response = await GET(apiUrl.journal, params);
    return response;
}

export const GetJournalById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.journal + '/' + id,params);
    return response;
}

export const CreateJournal = async (data) => {
    const response = await POST(apiUrl.journal, data);
    return response;
}

export const UpdateJournal = async (id, data) => {
    const response = await PUT(apiUrl.journal, id, data);
    return response;
}

export const DeleteJournal = async (id) => {
    const response = await DELETE(apiUrl.journal, id);
    return response;
}
