import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetCategory = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null, type = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['name'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (type) {
        params['type'] = type;
    }


    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }
    const response = await GET(apiUrl.category, params);
    return response;
}

export const GetCategoryById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.category + '/' + id,params);
    return response;
}

export const CreateCategory = async (data) => {
    const response = await POST(apiUrl.category, data);
    return response;
}

export const UpdateCategory = async (id, data) => {
    const response = await PUT(apiUrl.category, id, data);
    return response;
}

export const UpdateCategoryStatus = async (id, data) => {
    const response = await PUT(apiUrl.category_status, id, data);
    return response;
}


export const DeleteCategory = async (id) => {
    const response = await DELETE(apiUrl.category, id);
    return response;
}
