import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetPlan = async (user = '',category='',keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['title'] = keyword;
    }

    if (user) {
        params['user_id'] = user;
    }

    if (category) {
        params['category_id'] = category;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.plan, params);
    return response;
}

export const GetPlanById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.plan + '/' + id,params);
    return response;
}

export const CreatePlan = async (data) => {
    const response = await POST(apiUrl.plan, data);
    return response;
}

export const UpdatePlan = async (id, data) => {
    const response = await PUT(apiUrl.plan, id, data);
    return response;
}

export const DeletePlan = async (id) => {
    const response = await DELETE(apiUrl.plan, id);
    return response;
}
