import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';




export const GetPages = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['title'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.page, params);
    return response;
}

export const GetPageById = async (id) => {
    let response = '';
    response = await GET(apiUrl.page + '/' + id);
    return response;
}

export const CreatePage = async (data) => {
    const response = await POST(apiUrl.page,data);
    return response;
}


export const UpdatePage = async (id, data) => {
    const response = await PUT(apiUrl.page, id, data);
    return response;
}

export const DeletePage = async (id) => {
    const response = await DELETE(apiUrl.page, id);
    return response;
}
