import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetReport = async (keyword = '', page = '', perPage = '', is_paginate = 1,category = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['module'] = keyword;
    }
    if (category) {
        params['category'] = category;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.report, params);
    return response;
}

export const GetReportById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.report + '/' + id,params);
    return response;
}


export const DeleteReport = async (id) => {
    const response = await DELETE(apiUrl.report, id);
    return response;
}