import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeleteCategory, GetCategory, UpdateCategoryStatus} from "../../services/category.service";
import {constants, customStyles, IS, STATUS} from "../../utils/constants";
import moment from "moment";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {GetType} from "../../services/users.service";
import ImagePreview from "../../components/ImagePreview";

function Category(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState();
    const [selectedType, setSelectedType] = useState('');
    const [status, setStatus] = useState('');
    const typingTimeoutRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');




    useEffect( async () => {
        await getType();
        await getCategory();
    }, [keyword, page, perPage,status,selectedType])

    const getType = async () => {
        await GetType("CategoryTypes").then((result) => {
            if (result.status && result.data) {
                const serviceTypes =  result.data[0].CategoryTypes;
                setType(Object.values(serviceTypes));
            }
        })
    }

    const getCategory = async () => {
        setLoading(true)
        await GetCategory(keyword, page, perPage,1,status,selectedType).then((result) => {
            if (result.status) {
                if (result.data.categories) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.categories.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            name: dt.name || '-',
                            image: dt.image || '-',
                            type: dt.type || '-',
                            //In case of sub category
                            // sub_categories: dt?.sub_categories.map((subCategory) => subCategory.name).join(", ") || '-',
                            status: STATUS[dt?.status],
                            status_key:dt?.status,
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onCategoryDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this category?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteCategory(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getCategory();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onStatusUpdate = (data) => {
        let dt = {
            status: (data?.status_key && data?.status_key==true ? false: true ),
        }

        swal({
            title: "Are you sure?",
            text: "Are you sure you want to update status of this category?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await UpdateCategoryStatus(data.id,dt).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getCategory();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/categories/edit-category/` + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                {/*<Dropdown.Item as="button" onClick={() => onCategoryDelete(data.id)}>Delete</Dropdown.Item>*/}
                <Dropdown.Item as="button" onClick={() => onStatusUpdate(data)}>{data.status_key? "Set Inactive":"Set Active"}</Dropdown.Item>
            </DropdownButton>
        )
    }

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'image',
            name: 'Image',
            selector: rowData => rowData.image,
            format: rowData => <img src={rowData.image}
                                    onClick={() => handleImageClick(rowData.image)}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Background'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'name',
            name: 'Name',
            grow: false,
            center: true,
            sortable: true,
            selector: rowData => rowData.name,
        },
        // {
        //     id: 'sub_categories',
        //     name: 'Sub Category',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData.sub_categories,
        // },
        {
            id: 'type',
            name: 'Type',
            grow: false,
            center: true,
            selector: rowData => rowData.type,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    const filterType = async (value) => {
        setSelectedType(value)
    }

    const filterStatus = async (value) => {
            setStatus(value)

    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"categoryPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Categories</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/categories/add-category"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select className='formselect' aria-label="status"
                                         onChange={(e) => filterStatus(e.target.value)}
                                         value={status}
                            >
                                <option selected disabled value="">Filter Status</option>
                                <option value={""}>All</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select id={"type"}
                                         type="text"
                                         placeholder="Filter Type"
                                         onChange={(e) => filterType(e.target.value)}
                                         value={selectedType}
                            >
                                <option selected disabled value="">Filter Type</option>
                                {type && type?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3} md={{ span: 3, offset: 0 }} lg={{ span: 3, offset: 2 }} xl={{ span: 2, offset: 4 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setStatus('')
                            setSelectedType('')

                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
            <ImagePreview
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                content={selectedImage}
            />
        </div>
    )
}

export default Category;