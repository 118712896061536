import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeletePlan, GetPlan} from "../../services/plan.service";
import {customStyles, IS, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {GetCategory} from "../../services/category.service";
import {GetType, GetUsers} from "../../services/users.service";
import Select from "react-select";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {DeletePost, GetPost} from "../../services/post.service";

function Posts(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [category, setCategory] = useState();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const typingTimeoutRef = useRef(null);




    useEffect(  async () => {
        await getType();
        await getUsers()
        await getPost();
    }, [keyword, page, perPage,selectedUser,selectedCategory])

    const getType = async () => {
        await GetType("PostCategory").then((result) => {
            if (result.status && result.data) {
                const PostCategory =  result.data[0].PostCategory;
                setCategory(Object.values(PostCategory));
            }
        })
    }

    const getPost = async () => {
        setLoading(true)
        await GetPost(selectedUser,selectedCategory,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.posts) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.posts.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:`${dt?.author?.full_name} (${dt?.author?.email})` || '-',
                            category:dt?.category|| '-',
                            content: dt.content || '-',
                            poll: dt.poll || '-',
                            type: dt.type || '-',
                            commentCount: dt.commentCount || '-',
                            likesCount: dt.likesCount || '-',
                            attachments: dt.attachments,
                            status: STATUS[dt?.status],
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onPostDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this post?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeletePost(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPost();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/posts/edit-post/` + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push(`/posts/comments/` + data.id, {
            data: data
        });
    }

    const onLike = (data) => {
        history.push(`/posts/likes/` + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View Comments</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onLike(data)}>View Likes</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onPostDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'50px',
            selector: rowData => rowData.id,
        },
        {
            id: 'user_id',
            name: 'User',
            grow: false,
            minWidth:'320px',
            maxWidth:'350px',
            center: true,
            selector: rowData => rowData.user_id,
        },
        {
            id: 'Content',
            name: 'Content',
            minWidth:'320px',
            maxWidth:'600px',
            grow: false,
            selector: rowData => rowData.content,
        },
        {
            id: 'category',
            name: 'Category',
            grow: false,
            center: true,
            selector: rowData => rowData.category,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'likesCount',
            name: 'Likes',
            grow: false,
            center: true,
            minWidth: "150px",
            selector: rowData => rowData.likesCount,
        },
        {
            id: 'commentCount',
            name: 'Comments',
            grow: false,
            center: true,
            selector: rowData => rowData.commentCount,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];


    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setUsers(result.data);
            }
        })
    }

    const optionsUsers = (item) => {
        setUserValue({ label: item.label, value:item.value })
        setSelectedUser(item.value);
    }

    const filterType = async (value) => {
        setSelectedCategory(value)
    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Posts</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search by content"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/posts/add-post"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Form.Group className="mb-3" controlId="users">
                            <Select
                                value={userValue}
                                placeholder={'Filter user'}
                                options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                className="basic-multi-select"
                                onChange={optionsUsers}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select id={"type"}
                                         type="text"
                                         placeholder="Filter Type"
                                         onChange={(e) => filterType(e.target.value)}
                                         value={selectedCategory}
                            >
                                <option selected disabled value="">Filter Type</option>
                                {category && category?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3} md={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 3 }} xl={{ span: 2, offset: 4 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={ ()=>{
                            setUserValue(null)
                            setSelectedUser(null)
                            setSelectedCategory('')
                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Posts;