import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {constants, customStyles, GENDERS} from "../../utils/constants";
import {DeleteUser, GetType, GetUsers, UpdateProfilePartial} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {useForm} from "react-hook-form";
import {UpdateFAQ} from "../../services/faq.service";
import {FadeLoader} from "react-spinners";
import ImagePreview from "../../components/ImagePreview";
import moment from "moment";

function Trainer(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [gender, setGender] = useState('');
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [selectedStatus,setSelectedStatus ] = useState('');
    const [search, setSearch] = useState('');
    const [trainer_type, setTrainer_type] = useState('');
    const [email, setEmail] = useState(false);
    const typingTimeoutRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const getStatusType = async () => {
        await GetType("StatusTypes").then((result) => {
            if (result.status && result.data) {
                const StatusTypes =  result.data[0].StatusTypes;
                setStatus(Object.values(StatusTypes));
            }
        })
    }

    useEffect(async () => {
        window.scrollTo(0, 0);
        await getStatusType();
        await getUsers();
    }, [keyword, page, perPage,gender,selectedStatus,search,trainer_type])

    const getUsers = async () => {
        setLoading(true)
        await GetUsers(email,'Trainer',gender,keyword, page, perPage,1,selectedStatus,trainer_type).then((result) => {
            if (result?.status) {
                if (result?.data) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.users.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            name: dt.full_name || '-',
                            image: dt.image || '-',
                            email: dt.email || '-',
                            gender: GENDERS[dt?.gender] || '-',
                            dob: new Date(dt.dob).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric"
                            }) || '-',
                            address: dt.address || '-',
                            type: dt.trainer_profile?.type || '-',
                            user_package: dt.user_package && dt.user_package?.name || '-',
                            status: dt.status || '-',
                            role:'user',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-'),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }





    const onUserDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this professional?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteUser(id).then((result) => {
                    if (result.status) {
                        swal("Professional deleted successfully", {
                            icon: "success",
                        });
                        getUsers();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/professionals/edit-professional/' + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push('/professionals/view-professional/' + data.id, {
            data: data
        });
    }

    const onProfileUpdate = (data) => {
        history.push('/professionals/update-professional-status/' + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onProfileUpdate(data)}>Update Status</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '60px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth: '100px',
            selector: rowData => rowData.image,
            format: rowData => <img src={rowData.image}
                                    onClick={() => handleImageClick(rowData.image)}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Broken'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'full_name',
            name: 'Name',
            maxWidth: '160px',
            sortable: true,
            selector: rowData => rowData.name,
        },
        {
            id: 'email',
            name: 'Email',
            minWidth: '180px',
            selector: rowData => rowData.email,
        },
        {
            id: 'gender',
            name: 'Gender',
            maxWidth: '150px',
            center: true,
            selector: rowData => rowData.gender,
        },
        {
            id: 'dob',
            name: 'DOB',
            maxWidth: '100px',
            center: true,
            selector: rowData => rowData.dob,
        },
        {
            id: 'type',
            name: 'Type',
            maxWidth: '120px',
            center: true,
            selector: rowData => rowData.type,
        },
        {
            id: 'user_package',
            name: 'Package',
            maxWidth: '150px',
            center: true,
            selector: rowData => rowData.user_package,
        },
        {
            id: 'address',
            name: 'Address',
            selector: rowData => rowData.address,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];

    const filterGender = async (value) => {
        setGender(value)
    }
    const filterType = async (value) => {
        setTrainer_type(value)
    }

    const onChange= async (value)=>{
        setSelectedStatus(value)
     }

    const filterFrom = async (value) => {
        setSearch(value)
        if(value == 1){
            setEmail(true)
        }else{
            setEmail(false)
        }

    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Professionals</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={{span:2 ,offset:2}} lg={{span:2 ,offset:2}} xl={{span:2 ,offset:2}}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/professionals/add-professional"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    {/*<Col xs={12} sm={6} md={3} lg={3} xl={2}>*/}
                    {/*    <Form.Group className="mb-3" >*/}
                    {/*        <Form.Select*/}
                    {/*            id={"filter"}*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Search From"*/}
                    {/*            onChange={(e) => filterFrom(e.target.value)}*/}
                    {/*            value={search}*/}
                    {/*        >*/}
                    {/*            <option selected disabled value="">Search From</option>*/}
                    {/*            <option value={"0"}>Name</option>*/}
                    {/*            <option value={"1"}>Email</option>*/}
                    {/*        </Form.Select>*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={6} md={3} lg={3} xl={2}>
                        <Form.Group className="mb-3" >
                            <Form.Select
                                id={"gender"}
                                type="text"
                                placeholder="Filter Gender"
                                onChange={(e) => filterGender(e.target.value)}
                                value={gender}
                            >
                                <option selected disabled value="">Filter Gender</option>
                                <option value={""}>All</option>
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                                <option value={"Non-binary"}>Non-binary</option>
                                <option value={"Transgender"}>Transgender</option>
                                <option value={"Gender Neutral"}>Gender Neutral</option>
                                <option value={"Agender"}>Agender</option>
                                <option value={"Pangender"}>Pangender</option>
                                <option value={"Two-spirit"}>Two-spirit</option>
                                <option value={"Genderqueer"}>Genderqueer</option>
                                <option value={"Third Gender"}>Third Gender</option>
                                <option value={"Gender Fluid"}>Gender Fluid</option>
                                <option value={"Other"}>Other</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={3} lg={3} xl={2}>
                        <Form.Group className="mb-3" >
                            <Form.Select
                                value={selectedStatus}
                                className='trainerformselect'
                                aria-label="type"
                                {...register('status')}
                                onChange={(event) => onChange(event.target.value)}
                            >
                                <option value={""} disabled selected hidden>Filter Status</option>
                                {status && status.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={3} lg={3} xl={2}>
                        <Form.Group className="mb-3" >
                            <Form.Select
                                id={"trainer_type"}
                                type="text"
                                placeholder="Filter Type"
                                onChange={(e) => filterType(e.target.value)}
                                value={trainer_type}
                            >
                                <option selected disabled value="">Filter Type</option>
                                <option value={"Therapist"}>Therapist</option>
                                <option value={"Coach"}>Coach</option>
                                <option value={"Both"}>Both</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={{ span: 3, offset: 3 }} lg={{ span: 3, offset: 3 }} xl={{ span: 3, offset: 3 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setSearch('')
                            setType('')
                            setGender('')
                            setSelectedStatus('')
                            setTrainer_type('')
                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    pagination
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover

                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
            <ImagePreview
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                content={selectedImage}
            />
        </div>
    )
}

export default Trainer;