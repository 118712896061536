import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetType} from "../../services/users.service";
import {GetOrderById, UpdateOrder} from "../../services/order.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Button, Col, Form, Row} from "react-bootstrap";
import moment from "moment";
import {FadeLoader} from "react-spinners";
import {GetReportById} from "../../services/report.service";

function ViewReport(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const {register, watch, handleSubmit,setValue, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getReportByid(id)


    }, [])


    const getReportByid = async (id) => {
        await GetReportById(id).then( async (result) => {
            if (result?.status) {
                if (result?.data) {
                    setLoader(false)
                    let reportData=[]
                    reportData.push(result?.data)
                    setData(reportData);
                }
            } else {
                setLoader(false)
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }
    const handleClick = (id, module) => {
        if(module === "articles"){
            history.push(`/articles/view-article/` + id);
        }else if(module === "posts"){
            history.push(`/posts/view-post/` + id);
        }else if(module === "modules"){
            history.push(`/modules/view-module/` + id);
        }else if(module === "products"){
            history.push(`/products/edit-product/` + id);
        }else if(module === "users"){
            history.push(`/manage-users/view-user/` + id);
        }else if(module === "chats"){
            history.push(`/manage-users/view-user/` + id);
        }else{
            history.push(`/trainings/view-training/` + id);
        }

    };
    const onSubmit = async data => {
        setLoader(true)
            history.push('/reports');
            return false
    };

    return (
        <div className="Re">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">View Report</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        {loader ? <Row>
                            <Col  xs={12} sm={12} md={8} lg={3} xl={3} className={"mt-3"}>
                            <div className="spin-loader">
                                <FadeLoader color={"#41D2D2"} height={10}/>
                            </div>    </Col>
                            </Row>:
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {data?.map((item) => (
                                    <ul className={'order-list'} key={item._id}>
                                        <li >
                                            <span className={'order-list-title'}>Reporting User Name: </span>{item.user[0]?.full_name || '-'}
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Reporting User Email: </span>{item.user[0]?.email || '-'}
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>Module: </span>{item.module || '-'}
                                        </li>
                                        <li >
                                            <span className={'order-list-title'}>{item?.module === 'chats' ? "Reported User Details:" : "Module Detail:"} </span>  <Button type={'button'} className={"btn btn-filter-theme pt-0 pb-0"} onClick={()=>handleClick(item.module_id,item.module)}>
                                            {(() => {
                                                switch (item.module) {
                                                    case 'articles':
                                                        return 'View Article Detail';
                                                    case 'posts':
                                                        return 'View Post Detail';
                                                    case 'modules':
                                                        return 'View Module Detail';
                                                    case 'users':
                                                        return 'View User Detail';
                                                    case 'products':
                                                        return 'View Product Detail';
                                                    case 'chats':
                                                        return 'View User Detail';
                                                    case 'trainings':
                                                        return 'View Training Detail';
                                                    // Add more cases as needed
                                                    default:
                                                        return 'View Detail';
                                                }
                                            })()}
                                        </Button>
                                        </li>
                                        { item.category[0] ?
                                        <li >
                                            <span className={'order-list-title'}>Category: </span>{item.category[0]?.name || '-'}
                                        </li> : null}
                                        <li >
                                            <span className={'order-list-title'}>Content: </span>{item.content || '-'}
                                        </li>

                                        <li >
                                            <span className={'order-list-title'}>Reported At: </span>{ moment(item.created_at).format("MM/DD/YYYY hh:mm A")}
                                        </li>

                                    </ul>
                                ))}
                            </Col>
                        </Row>
                        }

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={3} xl={3} className={"mt-3"}>
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Back"}/>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default ViewReport;