import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetUserStat = async (id='',startDate = '',endDate = '',keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['keyword'] = keyword;
    }
    if (startDate) {
        params['startDate'] = startDate;
    }
    if (endDate) {
        params['endDate'] = endDate;
    }

    if (id) {
        params['user_id'] = id;
    }
    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.user_stats, params);
    return response;
}

export const GetUserStatById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.user_stats + '/' + id,params);
    return response;
}

export const CreateUserStat = async (data) => {
    const response = await POST(apiUrl.user_stats, data);
    return response;
}

export const UpdateUserStat = async (id, data) => {
    const response = await PUT(apiUrl.user_stats, id, data);
    return response;
}

export const DeleteUserStat = async (id) => {
    const response = await DELETE(apiUrl.user_stats, id);
    return response;
}
