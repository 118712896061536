import React, {useEffect, useRef, useState} from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row, Form } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {customStyles, IS, MediaType, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {DeletePlan, GetPlan} from "../../services/plan.service";
import {GetCategory} from "../../services/category.service";
import {GetUsers} from "../../services/users.service";
import Select from "react-select";

function Plan() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [selectedCategory, setSelectedCategory] = useState();
    const [categoryValue, setCategoryValue] = useState();
    const [category, setCategory] = useState([]);
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const typingTimeoutRef = useRef(null);




    useEffect(  () => {
        getUsers()
        getCategory()
        getPlan();
    }, [keyword, page, perPage,selectedUser,selectedCategory])

    const getPlan = async () => {
        setLoading(true)
        await GetPlan(selectedUser,selectedCategory,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.plans) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.plans.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:`${dt?.user[0]?.full_name} (${dt?.user[0]?.email})` || '-',
                            category_id:dt?.category[0]?.name || '-',
                            title: dt.title || '-',
                            duration: dt.duration || '-',
                            start_date: dt.start_date || '-',
                            time: dt.time || '-',
                            repeat_weekly: IS[dt.repeat_weekly]|| '-',
                            meals: dt?.meals,
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onPlanDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this plan?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeletePlan(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPlan();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/plans/edit-plan/` + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onPlanDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'50px',
            selector: rowData => rowData.id,
        },
        {
            id: 'user_id',
            name: 'User',
            grow: false,
            minWidth:'220px',
            maxWidth:'300px',
            center: true,
            selector: rowData => rowData.user_id,
        },
        {
            id: 'title',
            name: 'Title',
            grow: false,
            selector: rowData => rowData.title,
        },
        {
            id: 'category_id',
            name: 'Category',
            grow: false,
            center: true,
            selector: rowData => rowData.category_id,
        },
        {
            id: 'duration',
            name: 'Duration',
            grow: false,
            center: true,
            selector: rowData => rowData.duration,
        },    {
            id: 'start_date',
            name: 'Start Date',
            grow: false,
            center: true,
            selector: rowData => rowData.start_date,
        },
        {
            id: 'time',
            name: 'Time',
            grow: false,
            center: true,
            selector: rowData => rowData.time,
        },
        {
            id: ' repeat_weekly',
            name: 'Repeat Weekly',
            grow: false,
            center: true,
            selector: rowData => rowData.repeat_weekly,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    const getCategory = async () => {
        await GetCategory(null, null, null, 0, true,'Wellness').then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ name:'All', _id:null })
                setCategory(result.data);
            }
        })
    }

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setUsers(result.data);
            }
        })
    }

    const optionsUsers = (item) => {
        setUserValue({ label: item.label, value:item.value })
        setSelectedUser(item.value);
    }

    const optionsCategory = (item) => {
        setCategoryValue({label:item.label ,value:item.value})
        setSelectedCategory(item.value);
    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Plans</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/plans/add-plan"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Form.Group className="mb-3" controlId="users">
                            <Select
                                value={userValue}
                                placeholder={'Filter user'}
                                options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                className="basic-multi-select"
                                onChange={optionsUsers}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                        <Form.Group className="mb-3" controlId="category">
                            <Select
                                value={categoryValue}
                                placeholder={'Filter category'}
                                options={category && category?.map(e => ({ label: e.name, value:e._id }))}
                                className="basic-multi-select"
                                onChange={optionsCategory}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3} md={{ span: 3, offset: 0 }} lg={{ span: 3, offset: 2 }} xl={{ span: 2, offset: 4 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={ ()=>{
                            setCategoryValue(null)
                            setUserValue(null)
                            setSelectedUser(null)
                            setSelectedCategory(null)
                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Plan
