import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {CreateDailyQuote} from "../../services/dailyqoute.service";

function AddNewDailyQuote(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [date, setDate] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const {register, watch, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });



    const onSubmit = async data => {

        setLoader(true)
        await CreateDailyQuote({
            author: data.author || '-',
            title: data.title || '-',
            content: data.content ,
            date: data.date,
            status: (data?.status && data?.status==1 ? true: false ),


        }).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/daily-quotes');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };

    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setDate(date.toLocaleDateString())
    }




    return (
        <div className="AddNewDailyQuote">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Daily Quote</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="author">
                                    <Form.Label>Author*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Author"

                                        {...register('author', {
                                            required: {
                                                value: "required",
                                                message: "Author is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Author should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.author && <Form.Text
                                        className=" validationText hasError">{errors.author.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date*</Form.Label>
                                    <Form.Control
                                        id={"start_date"}
                                        placeholder="Enter Age"
                                        value={date.date}
                                        maxLength={3}
                                        {...register('date', {
                                            required: {
                                                value: "required",
                                                message: "Date is required"
                                            },
                                            onChange: (e) => onDateChange(e),
                                        })} type="date"
                                    />
                                    {errors.date && <Form.Text
                                        className=" validationText hasError">{errors.date.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="author">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="cast">
                                    <Form.Label>Content*</Form.Label>
                                    <textarea  id='custom-text-area' rows={8} cols={63}
                                               placeholder="Enter Content"
                                               {...register('content', {
                                                   required: {
                                                       value: "required",
                                                       message: "Content is required"
                                                   },
                                                   // pattern:{
                                                   //     value:/^(?!\s).*$/,
                                                   //     message:"Content should not start with a space"
                                                   // },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 1000,
                                                       message: "Max length is 1000"
                                                   },
                                               })} type="text" />
                                    {errors.content && <Form.Text
                                        className=" validationText hasError">{errors.content.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Daily Quote"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default AddNewDailyQuote;