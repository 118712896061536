import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row, Form } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {customStyles, STATUS} from "../../utils/constants";
import {GetPages} from "../../services/pages.service";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";

function Pages() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');







    useEffect(async () => {
        getPages();
    }, [keyword, page, perPage])

    const convertHtmlToString = (html) => {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(html, 'text/html');
        return parsedHtml.body.textContent || '';
    };


    const getPages = async () => {
        setLoading(true)
        await GetPages(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.pages) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result?.data?.pagination?.total);
                    result.data.pages.map((dt, index) => {
                        rowData.push({
                            id: dt._id || '-',
                            slug: dt.slug || '-',
                            title: dt.title || '-',
                            content: convertHtmlToString(dt.content) || '-',
                            status: STATUS[dt?.status],
                            created_at: moment(dt.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }




    const onEdit = (data) => {
        history.push('/pages/edit-page/' + data.id, {
            data: data
        });
    }



    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'ID',
            sortable: true,
            maxWidth: '60px',
            selector: rowData => rowData.id,
        },
        {
            id: 'slug',
            name: 'Slug',
            sortable: true,
            maxWidth: '160px',
            selector: rowData => rowData.slug,
        },
        {
            id: 'title',
            name: 'Title',
            maxWidth: '180px',
            selector: rowData => rowData.title,
        },
        {
            id: 'content',
            name: 'Content',
            maxWidth: '240px',
            sortable: true,
            selector: rowData => rowData.content,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'created_at',
            name: 'Created At',
            maxWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Pages</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Pages
