import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {convertDateUTCToLocal, uploadToS3} from "../../utils/helpers";
import {toast} from "react-toastify";
import {GetType, UpdateUser} from "../../services/users.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetSession} from "../../services/session.service";
import {GetTrainerAvailability} from "../../services/traineravailability.service";
import {EditAppointment, GetAppointmentById} from "../../services/appointment.service";
import moment from "moment";

function UpdateAppointment(props) {

    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [startDate, setStartDate] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [selectedTrainer, setSelectedTrainer] = useState();
    const [trainer, setTrainer] = useState([]);
    const [users, setUsers] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [trainerAvailability,setTrainerAvailability] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState();
    const [selectedSession, setSelectedSession] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [type, setType] = useState();
    const [status, setStatus] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const {register, setValue, handleSubmit, setError,watch, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });
    const selectedStatus = watch('status');
    const currentDate = new Date();
    const nextDay = new Date();
    nextDay.setDate(currentDate.getDate() + 1);

    // Format the next day as "YYYY-MM-DD" for setting the minDate
    const minDate = nextDay.toISOString().split('T')[0];

    const getAppointmentType = async () => {
        await GetType("AppointmentType").then((result) => {
            if (result.status && result.data) {
                const AppointmentType =  result.data[0].AppointmentType;
                setType(Object.values(AppointmentType));
            }
        })
    }
    const getPaymentMethod = async () => {
        await GetType("PaymentMethod").then((result) => {
            if (result.status && result.data) {
                const PaymentMethod =  result.data[0].PaymentMethod;
                setPaymentMethod(Object.values(PaymentMethod));
            }
        })
    }
    const getStatusType = async () => {
        await GetType("AppointmentStatus").then((result) => {
            if (result.status && result.data) {
                const AppointmentStatus =  result.data[0].AppointmentStatus;
                setStatus(Object.values(AppointmentStatus));
            }
        })
    }

    const getTrainerAvailability= async () => {
        await GetTrainerAvailability(null,  null,null,null, selectedTrainer).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setTrainerAvailability(result.data);
            }
        })
    }

    useEffect(  async ()=>{
        let id = props.match.params.id;
        await getAppointmentType();
        await getPaymentMethod();
        await getStatusType();
        await getAppointmentByid(id)
    },[])


    function convertTo12HourFormat(time) {
        const utcTime = moment.utc(time, 'HH:mm');

// Convert UTC time to local time
        const localTime = utcTime.local().format('HH:mm');

        const [hours, minutes] = localTime.split(':');

        let formattedHours = parseInt(hours);
        let period = 'AM';

        if (formattedHours === 0) {
            formattedHours = 12;
        } else if (formattedHours === 12) {
            period = 'PM';
        } else if (formattedHours > 12) {
            formattedHours -= 12;
            period = 'PM';
        }

        const formattedTime = `${formattedHours}:${minutes.padStart(2, '0')} ${period}`;
        return formattedTime;
    }

    const getAppointmentByid = async (id) => {
        await GetAppointmentById(id).then( async (result) => {
            if (result?.status) {
                if (result?.data) {
                    setSelectedUser({ label:`${result.data?.user.full_name} (${result.data?.user.email})`, value: result.data?.user._id })
                    setSelectedTrainer({ label:`${result.data?.trainer.full_name} (${result.data?.trainer.email})`, value: result.data?.trainer._id })
                    setSelectedSession({ label: `${result.data?.fee}$ (${result.data?.duration_in_mins} min)`, value:'123' })
                    setSelectedAvailability({ label: `${convertTo12HourFormat(result.data?.start_time)} - ${convertTo12HourFormat(result.data?.end_time)}`, value:"12" })
                    setValue("status", (result?.data?.status));
                    setValue("start_date", (convertDateUTCToLocal(result?.data?.date,result.data?.start_time)));
                    // setValue("appointment_type", (result?.data?.appointment_type));
                    setValue("payment_method", (result?.data?.payment_method));
                    setValue("reason", (result?.data?.reason));
                    setValue("user_card_id", (result?.data?.user_card_id));
                    setValue("reason", (result?.data?.reason));
                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }

    const onSubmit = async data => {
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            user_id: data.username,
            status: data.status,
            reason: data?.reason || '-'
        }
        await EditAppointment(id, dt).then(async (data) => {
            setLoader(false)
            if(data.status){
                toast.success(data.message);
                history.push('/appointments');
            } else{
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setStartDate(date.toLocaleDateString())
    }
    return (
        <div className="AddNewAppointment">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Update Appointment</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>User*</Form.Label>
                                    <Select
                                        value={selectedUser}
                                        isDisabled
                                        className="basic-multi-select"
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="trainer">
                                    <Form.Label className='d-block'>Professional*</Form.Label>
                                    <Select
                                        value={selectedTrainer}
                                        isDisabled
                                        className="basic-multi-select"
                                    />
                                </Form.Group>
                            </Col>
                            {selectedTrainer ?  <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="trainer">
                                    <Form.Label className='d-block'>Select Time Slot*</Form.Label>
                                    <Select
                                        value={selectedAvailability}
                                        isDisabled
                                        className="basic-multi-select"
                                    />
                                    </Form.Group>
                            </Col> : null}


                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="trainer">
                                    <Form.Label className='d-block'>Session*</Form.Label>
                                    <Select
                                        value={selectedSession}
                                        isDisabled
                                        className="basic-multi-select"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date*</Form.Label>
                                    <Form.Control
                                        id={"start_date"}
                                        placeholder="Enter Date"
                                        value={startDate.date}
                                        disabled
                                        min={minDate}
                                        maxLength={3}
                                        {...register('start_date', {
                                            onChange: (e) => onDateChange(e),
                                        })} type="date"
                                    />
                                    {errors.start_date && <Form.Text
                                        className=" validationText hasError">{errors.start_date.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col  xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className={"mb-3"} controlId="status">*/}
                            {/*        <Form.Label className='d-block'>Appointment Type*</Form.Label>*/}
                            {/*        <Form.Select className='formselect' aria-label="type" disabled {...register('appointment_type')}>*/}
                            {/*            <option value={""} disabled selected hidden>-- Select --</option>*/}
                            {/*            {type && type.map(value => (*/}
                            {/*                <option key={value} value={value}>{value}</option>*/}
                            {/*            ))}*/}
                            {/*        </Form.Select>*/}
                            {/*        {errors.appointment_type && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.appointment_type.message}</Form.Text>}*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col  xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className={"mb-3"} controlId="status">*/}
                            {/*        <Form.Label className='d-block'>Payment Method*</Form.Label>*/}
                            {/*        <Form.Select className='formselect' aria-label="type"  disabled {...register('payment_method')}>*/}
                            {/*            <option value={""} disabled selected hidden>-- Select --</option>*/}
                            {/*            {paymentMethod && paymentMethod.map(value => (*/}
                            {/*                <option key={value} value={value}>{value}</option>*/}
                            {/*            ))}*/}
                            {/*        </Form.Select>*/}
                            {/*        {errors.payment_method && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.payment_method.message}</Form.Text>}*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={12} sm={12} md={8} lg={8} xl={12}>*/}
                            {/*    <Form.Group className="mb-3" controlId="text">*/}
                            {/*        <Form.Label>User Card Id*</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter User Card Id"*/}
                            {/*            disabled*/}
                            {/*            {...register('user_card_id')} type="text"*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type"  {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value={""} disabled selected hidden>-- Select --</option>
                                        {status && status.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {selectedStatus === 'Cancelled' ?  <Col xs={12} sm={12} md={8} lg={10} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Reason*</Form.Label>
                                    <textarea id='custom-text-area' rows={8} cols={60}
                                               placeholder="Enter Reason"
                                               {...register('reason', {
                                                   required: {
                                                       value: "required",
                                                       message: "Reason is required"
                                                   },
                                                   pattern:{
                                                       value:/^(?!^\s).*$/s,
                                                       message:"Reason should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 4000,
                                                       message: "Max length is 4000"
                                                   },
                                               })} type="text" />
                                    {errors.reason && <Form.Text
                                        className=" validationText hasError">{errors.reason.message}</Form.Text>}
                                </Form.Group>
                            </Col>: null}

                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Appointment"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default UpdateAppointment;