import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import TagInputComponent from "../../components/TagInputComponent";
import {FadeLoader} from "react-spinners";
import {GetCategory} from "../../services/category.service";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UpdatePlan} from "../../services/plan.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetPodcastById} from "../../services/podcast.service";
import {GetProductById, UpdateProduct} from "../../services/product.service";
import {uploadToS3} from "../../utils/helpers";

function EditProduct(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [file, setFile] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState();
    const [image, setImage] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [category, setCategory] = useState([]);
    const [loader, setLoader] = useState(false);
    const [tags, setTags] = useState([]);
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [editSelectedCategory, setEditSelectedCategory] = useState();
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(  async ()=>{
        let id = props.match.params.id;
        await getCategory();
        await getProductByid(id);
    },[])

    const getProductByid = async (id) => {
        await GetProductById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("title", result.data?.title);
                    setValue("description", result.data?.description);
                    setValue("price", result.data?.price);
                    // setValue("discounted_price", result.data?.discounted_price);
                    setValue("qq_composition", result.data?.qq_composition);
                    setValue("pharmaceutical_form", result.data?.pharmaceutical_form);
                    // setValue("sku", result.data?.sku);
                    setValue("tablet_core", result.data?.tablet_core);
                    setValue("tablet_coating", result.data?.tablet_coating);
                    setValue("incompatiblities", result.data?.incompatiblities);
                    setValue("shelf_life", result.data?.shelf_life);
                    setValue("special_precautions_for_storage", result.data?.special_precautions_for_storage);
                    setValue("manufacturer", result.data?.manufacturer);
                    setValue("active_ingredient", result.data?.active_ingredient);
                    setValue("status", (result.data?.status && result.data?.status == true ? "1":"0"));

                    setEditSelectedCategory({ label:result.data?.category.name, value: result.data?.category._id })
                    setSelectedCategory(result.data?.category._id)

                    // if(result.data?.tags){
                    //     setTags(result.data?.tags)
                    // }
                    if(result.data?.attachments[0].path){
                        setImage(result.data?.attachments[0].path)
                        setIsUpload(true)
                    }

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }

    const onSubmit = async data => {
        if(isUploadOverWrite){
            if(file?.type.startsWith('image/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }
        }
        if(!selectedCategory){
            setError('category' ,{message:"Category is required"})
            return false
        }else{
            clearErrors('category')
        }
        setLoader(true)
        if(isUploadOverWrite){
            let uploadImage = await uploadToS3(file.type,file.name,file,'product');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            data.media=uploadImage;
        }

        let id = props.match.params.id;
        let dt = {
            title: data.title || null,
            description: data.description ,
            category_id: selectedCategory || null,
            price: data.price || null,
            // discounted_price: data.discounted_price || null,
            qq_composition: data.qq_composition || null,
            pharmaceutical_form: data.pharmaceutical_form || null,
            // sku: data.sku || null,
            tablet_core: data.tablet_core || null,
            tablet_coating: data.tablet_coating || null,
            incompatiblities: data.incompatiblities || null,
            shelf_life: data.shelf_life || null,
            special_precautions_for_storage: data.special_precautions_for_storage || null,
            manufacturer: data.manufacturer || null,
            active_ingredient: data.active_ingredient || null,
            // tags: tags || null,
            ...(isUploadOverWrite && {
                attachments: [{
                duration: 1,
                is_feature: true,
                mime_type: "image",
                path: data.media ,
                type: 40
            }]
            }),
            status: (data?.status && data?.status==1 ? true: false ),

        }

        await UpdateProduct(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/products');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };
    const getCategory = async () => {
        await GetCategory(null, null, null, 0, true,'Product').then((result) => {
            if (result.status && result.data) {
                setCategory(result.data);
            }
        })
    }

    const optionsCategory = (item) => {
        setSelectedCategory(item.value);
        setEditSelectedCategory({ label:item.label, value:item.value })
        if(!item.value){
            setError('category' ,{message:"Category is required"})
        }else{
            clearErrors('category')
        }
    }

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
            clearErrors('media')
        }

    }

    return (
        <div className="EditProduct">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Product</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <br/>
                                    <input
                                        {...register('media')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    <br/>
                                    {isUpload ?
                                        <img  src={image} type="image" className={"img-table img-thumbnail mt-2"} controls crossOrigin="anonymous" />:  ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 600,
                                                message: "Max length is 600"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>Price*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Price"

                                        {...register('price', {
                                            required: {
                                                value: "required",
                                                message: "Price is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="number"
                                    />
                                    {errors.price && <Form.Text
                                        className=" validationText hasError">{errors.price.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="discounted_price">*/}
                            {/*        <Form.Label>Discounted Price</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Discounted Price"*/}

                            {/*            {...register('discounted_price')} type="number"*/}
                            {/*        />*/}
                            {/*        {errors.discounted_price && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.discounted_price.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="manufacturer">
                                    <Form.Label>Manufacturer</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Manufacturer"

                                        {...register('manufacturer',{
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Manufacturer should not start with a space"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            }
                                        })} type="text"
                                    />
                                    {errors.manufacturer && <Form.Text
                                        className=" validationText hasError">{errors.manufacturer.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="active_ingredient">*/}
                            {/*        <Form.Label>Active Ingredient</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Active Ingredient"*/}

                            {/*            {...register('active_ingredient',{*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"Active Ingredient should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 100,*/}
                            {/*                    message: "Max length is 100"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.active_ingredient && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.active_ingredient.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="shelf_life">*/}
                            {/*        <Form.Label>Shelf Life</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Shelf Life"*/}

                            {/*            {...register('shelf_life',{*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"Shelf Life should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 100,*/}
                            {/*                    message: "Max length is 100"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.shelf_life && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.shelf_life.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="incompatiblities">*/}
                            {/*        <Form.Label>Incompatiblities</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Incompatiblities"*/}

                            {/*            {...register('incompatiblities',{*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"Incompatiblities should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 100,*/}
                            {/*                    message: "Max length is 100"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.incompatiblities && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.incompatiblities.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={12} sm={12} md={12} lg={12} xl={12}>*/}
                            {/*    <Form.Group className="mb-3" controlId="tablet_coating">*/}
                            {/*        <Form.Label>Tablet Coating</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Tablet Coating"*/}

                            {/*            {...register('tablet_coating',{*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"Tablet Coating should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 600,*/}
                            {/*                    message: "Max length is 600"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.tablet_coating && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.tablet_coating.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={12} sm={12} md={12} lg={12} xl={12}>*/}
                            {/*    <Form.Group className="mb-3" controlId="tablet_core">*/}
                            {/*        <Form.Label>Tablet Core</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Tablet Core"*/}

                            {/*            {...register('tablet_core',{*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"Tablet Core should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 600,*/}
                            {/*                    message: "Max length is 600"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.tablet_core && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.tablet_core.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="sku">*/}
                            {/*        <Form.Label>SKU</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter SKU"*/}

                            {/*            {...register('sku',{*/}
                            {/*                pattern:{*/}
                            {/*                    value:/^(?!\s).*$/,*/}
                            {/*                    message:"SKU should not start with a space"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 3"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 100,*/}
                            {/*                    message: "Max length is 100"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.sku && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.sku.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label className='d-block'>Category*</Form.Label>
                                    <Select
                                        value={editSelectedCategory}
                                        options={category && category?.map(e => ({ label: e.name, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsCategory}

                                    />
                                    {errors.category && <Form.Text className="text-muted validationText hasError">{errors.category.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col  xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                        {/*        <Form.Group className={"mb-3"} controlId="status">*/}
                        {/*            <Form.Label className='d-block'>Tags</Form.Label>*/}
                        {/*            <TagInputComponent tags={tags} setTags={setTags}/>*/}
                        {/*            {errors.tag && <Form.Text*/}
                        {/*                className=" validationText hasError">{errors.tag.message}</Form.Text>}*/}
                        {/*        </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="special_precautions_for_storage">
                                    <Form.Label>Special Precautions For Storage</Form.Label>
                                    <textarea id='custom-text-area'  rows={6} cols={63}
                                               placeholder="Enter Special Precautions For Storage"
                                               {...register('special_precautions_for_storage', {
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"Special Precautions For Storage should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 600,
                                                       message: "Max length is 600"
                                                   },
                                               })} type="text" />
                                    {errors.special_precautions_for_storage && <Form.Text
                                        className=" validationText hasError">{errors.special_precautions_for_storage.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description*</Form.Label>
                                    <textarea id='custom-text-area'  rows={6} cols={63}
                                               placeholder="Enter Description"
                                               {...register('description', {
                                                   required: {
                                                       value: "required",
                                                       message: "Description is required"
                                                   },
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"Description should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 600,
                                                       message: "Max length is 600"
                                                   },
                                               })} type="text" />
                                    {errors.description && <Form.Text
                                        className=" validationText hasError">{errors.description.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="pharmaceutical_form">
                                    <Form.Label>Pharmaceutical Form</Form.Label>
                                    <textarea id='custom-text-area'  rows={6} cols={63}
                                               placeholder="Enter Pharmaceutical Form"
                                               {...register('pharmaceutical_form', {
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"Pharmaceutical Form should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 600,
                                                       message: "Max length is 600"
                                                   },
                                               })} type="text" />
                                    {errors.pharmaceutical_form && <Form.Text
                                        className=" validationText hasError">{errors.pharmaceutical_form.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="qq_composition">
                                    <Form.Label>QQ Composition</Form.Label>
                                    <textarea id='custom-text-area'  rows={6} cols={63}
                                               placeholder="Enter QQ Composition"
                                               {...register('qq_composition', {
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"QQ Composition should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 600,
                                                       message: "Max length is 600"
                                                   },
                                               })} type="text" />
                                    {errors.qq_composition && <Form.Text
                                        className=" validationText hasError">{errors.qq_composition.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Product"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditProduct;