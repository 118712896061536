import {apiUrl, constants} from '../../utils/constants';
import {POST} from '../api.service.wrapper';

const storageKey = constants.LOCAL_STORAGE_TOKEN;
const storageUser = constants.LOCAL_STORAGE_USER;

export const Login = async (email, password) => {
    const response = await POST(apiUrl.login, {email, password});
    return response;
}

export const SetToken = (token) => {
    if (token) localStorage.setItem(storageKey, token);
    else localStorage.removeItem(storageKey);
}

export const GetToken = () => {
    return localStorage.getItem(storageKey);
}

export const SetAuthUser = (user_data) => {
    if (user_data) localStorage.setItem(storageUser, user_data);
    else localStorage.removeItem(storageUser);
}

export const GetAuthUser = () => {
    return JSON.parse(localStorage.getItem(storageUser));
}

export const GetAccessToken = () => {
    return JSON.parse(localStorage.getItem(storageUser))?.access_token;
}

export const Logout = async (device_type,device_token) => {
    const response = await POST(apiUrl.logout, { device_token, device_type});
    return response;
}

export const EmptyLocalStorage = async () => {
    await localStorage.removeItem(storageUser);
    return await localStorage.removeItem(storageKey)
}

export const GetCurrentUser = () => {
    return JSON.parse(localStorage.getItem(storageUser));
}

export const ForgetPasswordApi = async (email) => {
    const response = await POST(apiUrl.forgot_password, {email});
    return response;
}

export const VerifyOtpApi = async (otp, email) => {
    let type="forgotPassword"
    const response = await POST(apiUrl.verify_otp, {otp, email,type});

    return response;
}

export const VerifyLoginOtpApi = async (otp, email,password) => {
    let type = "login"
    const response = await POST(apiUrl.login_verify_otp, {otp, email,type});
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data));
    return response;
}

export const ResetPasswordApi = async (email, otp_code, password, password_confirmation) => {
    const response = await POST(apiUrl.reset_password, {email, password, password_confirmation});
    return response;
}

export const ChangePasswordApi = async (old_password, password, password_confirmation) => {
    const response = await POST(apiUrl.change_password, {old_password, password, password_confirmation});
    return response;
}

export const GetUserRole = () => {
    const user = GetAuthUser();
    let role;
    if (user && user.roles) {
        let role_id = user.roles[0].id;
        if (role_id == constants.ROLES.ROLE_ADMIN) {
            role = constants.ROLES.ROLE_ADMIN;
        } else if (role_id == constants.ROLES.ROLE_VENDOR) {
            role = constants.ROLES.ROLE_VENDOR;
        } else if (role_id == constants.ROLES.ROLE_CSA) {
            role = constants.ROLES.ROLE_CSA;
        } else {
            role = constants.ROLES.ROLE_USER;
        }
    }

    return role;
}
