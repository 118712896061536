import imgNotFound from "../assets/images/broken.jpg"
import brokenImage from "../assets/images/img-broken.jpg"

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`
export const S3_BASE_URL = "https://s3-presigned-svc.tekstagearea.com/api/v1/"


const roles = {
    ROLE_ADMIN: "1",
    ROLE_USER: "2",
}




const page = {
    PRIVACY_POLICY: 'privacy-policy',
    TERMS: 'terms-and-conditions'
}




 export const s3Credential = {
    app_name:"libido-health-stagging",
    bucketName: "libido-health",
    region: "us-east-1",
    accessKeyId: "AKIAUPLSLQUZEVJ6Z5VH",
    secretAccessKey: "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",
    s3EndPoint: "https://s3-presigned-svc.tekstagearea.com/api/v1/",
    app_token:"O6K568MXt1xpN2GQWLR2",
    fileSize: '10000000'
}

const errorMessages = {
    fileSize: 'The file size is too large',
    fileSuccess: 'file uploaded successfully',
    fileError: 'Error in uploading file',
}

const contentType = {
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'image/png': 'image',
    'image/gif': 'image',
    'application/pdf': 'doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    'audio/mpeg': 'audio',
    'video/mp4': 'video',
}

const chatMessage = {
    CONTENT: 0,
    CONTENT_SOFT_DELETE: 1,
    CONTENT_HARD_DELETE: 2,
}

const AdType = {
    IMAGE: "image",
    VIDEO: "video"
}

export const Media={
    IMAGE: 20,
    VIDEO: 10

}

export const MediaType = {
    [Media.IMAGE]: "Image",
    [Media.VIDEO]: "Video",
}
export const constants = {
    LOCAL_STORAGE_TOKEN: 'AUTH_ACCESS_TOKEN',
    LOCAL_STORAGE_USER: 'USER_DATA',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    CSA_URL: process.env.REACT_APP_CSA_BASE_URL,
    ADMIN_URL: process.env.REACT_APP_ADMIN_BASE_URL,
    VENDOR_URL: process.env.REACT_APP_VENDOR_BASE_URL,
    ROLES: roles,
    NOTIFICATIONTYPES: '',
    LOCALE: 'en-OM',
    CURRENCY: 'OMR',
    PAGES: page,
    PERPAGE: 20,
    ORDER_BY_VALUE: 'desc',
    ORDER_BY_COLUMN: 'id',
    //IMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found.png',
    IMAGENOTFOUND: imgNotFound,
    //USERIMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found-user.png',
    USERIMAGENOTFOUND: imgNotFound,
    COLLECTION: '',
    S3CREDENTIAL: s3Credential,
    ERRORMESSAGES: errorMessages,
    CONTENTTYPE: contentType,
    CHATMESSAGE: chatMessage,
    ADTYPE: AdType,
};

export const genders = {
    MALE: 10,
    FEMALE: 20,
}

export const weight_unit = {
    KG: 10,
    LBS: 20,
}

export const apiUrl = {
    login: 'login',
    logout: 'logout',
    forgot_password: 'forgot-password',
    verify_otp: 'verify-otp',
    login_verify_otp: 'login-verify-otp',
    reset_password: 'reset-password',
    change_password: 'change-password',
    dashboard: 'dashboard',
    dashboard_summary: 'dashboard-summary',
    users: 'user',
    trainer: 'user/trainer',
    partial: 'user/update-partial',
    timeslots: 'user/trainer-timeslots',
    breathing_video: 'user/breathing-video',
    profile:'profile',
    goals: 'goal',
    report: 'report',
    rating: 'rating',
    register: 'register',
    category:'category',
    category_status:'category/status',
    package_status:'package/status',
    countries:'country',
    cities:'city',
    states:'state',
    music:'music',
    podcast:'podcast',
    podcast_episode:'podcast/episode',
    product:'product',
    questionnaire:'questionnaire',
    answer:'questionnaire/answers',
    article:'article',
    video:'video',
    plan:'plan',
    transaction:'transaction',
    package:'package',
    advertisement:'advertisement',
    appointment:'appointment',
    appointment_time_slots:'appointment/time-slots',
    module:'module',
    module_step:'module/step',
    module_details:'module/step-details',
    module_step_completed:'module/steps-completed',
    training_step_completed:'training/steps-completed',
    daily_quote:'daily-quote',
    user_stats:'user-stats',
    faq:'faq',
    service:'service',
    journal:'journal',
    page:'page',
    post:'post',
    order:'order',
    permission:'permission',
    session:'session',
    settings:'settings',
    trainer_availability:'trainer-availability',
    training:'training',
    training_step:'training/step',
    training_verification:'trainer-verification',
    role:'role',
    enum_type:'type',
    comments:'post/comments',
    likes:'post/likes',

    ads: 'ads',
    generate_otp:'generate_otp',
    mark_as_default: 'mark-as-default',
    privacy_policy:'page-by-slug/',
    terms_and_conditions:'page-by-slug/terms-and-conditions',
    push_notifications:'push-notifications',
    notifications:'notification/send-bulk',
    get_s3_token:'files'

}

export const customStyles = {
    rows: {
        style: {
            minHeight: '50px',
        },
    },
    headCells: {
        style: {
            color: '#000',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
            fontFamily: 'Poppins',
            padding: '5px',
            textTransform: 'uppercase',
            'align-items': 'left'
        },
    },
    cells: {
        style: {
            padding: '5px',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '19px',
            fontFamily: 'Poppins',
            'align-items': 'left'
        },
    },
}

export const POST_TYPES = {
    COMMUNITY: "Community",
    FORUM: "Forum",
}

export const GENDER_VALUES = {
    MALE: "Male",
    FEMALE: "Female",
    NONE: "Non-binary",
    TRANSGENDER: "Transgender",
    GENDER_NEUTRAL: "Gender Neutral",
    AGENDER: "Agender",
    PANGENDER: "Pangender",
    TWO_SPIRIT: "Two-spirit",
    GENDERQUEER: "Genderqueer",
    THIRD_GENDER: "Third Gender",
    GENDER_FLUID: "Gender Fluid",
    OTHER: "Other",
}

export const GENDERS = {
    [GENDER_VALUES.NONE]: "Non-binary",
    [GENDER_VALUES.MALE]: "Male",
    [GENDER_VALUES.FEMALE]: "Female",
    [GENDER_VALUES.TRANSGENDER]: "Transgender",
    [GENDER_VALUES.GENDER_NEUTRAL]: "Gender Neutral",
    [GENDER_VALUES.AGENDER]: "Agender",
    [GENDER_VALUES.PANGENDER]: "Pangender",
    [GENDER_VALUES.TWO_SPIRIT]: "Two-spirit",
    [GENDER_VALUES.GENDERQUEER]: "Genderqueer",
    [GENDER_VALUES.THIRD_GENDER]: "Third Gender",
    [GENDER_VALUES.GENDER_FLUID]: "Gender Fluid",
    [GENDER_VALUES.OTHER]: "Other",
}


export const STATUS_VALUE = {
    ACTIVE: true,
    INACTIVE: false,
}
export const STATUS = {
    [STATUS_VALUE.ACTIVE]: "Active",
    [STATUS_VALUE.INACTIVE]: "Inactive",
}


export const SESSION_TYPE_VALUE = {
    COACH: 10,
    THERAPIST: 20,
}
export const SESSION_TYPE = {
    [SESSION_TYPE_VALUE.COACH]: "Coach",
    [SESSION_TYPE_VALUE.THERAPIST]: "Therapist",
}

export const AVAILABLE_VALUE = {
    ACTIVE: true,
    INACTIVE: false,
}
export const AVAILABLE = {
    [AVAILABLE_VALUE.ACTIVE]: "Available",
    [AVAILABLE_VALUE.INACTIVE]: "Unavailable",
}


export const IS_VALUE = {
    ACTIVE: true,
    INACTIVE: false,
}
export const IS = {
    [IS_VALUE.ACTIVE]: "Yes",
    [IS_VALUE.INACTIVE]: "No",
}
export const DEFAULT_VALUE = {
    DEFAULT: 1,
    NOT_DEFAULT: 0,
}
export const DEFAULT = {
    [DEFAULT_VALUE.DEFAULT]: "Yes",
    [DEFAULT_VALUE.NOT_DEFAULT]: "No",
}
export const IS_VERIFIED_VALUE = {
    VERIFIED: 1,
    UN_VERIFIED: 0,
}

export const IS_VERIFIED = {
    [IS_VERIFIED_VALUE.VERIFIED]: "Verified",
    [IS_VERIFIED_VALUE.UN_VERIFIED]: "No",
}

export const DAYS_OF_WEEK = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
}

export const DAYS = {
    [DAYS_OF_WEEK.SUNDAY]: "Sunday",
    [DAYS_OF_WEEK.MONDAY]: "Monday",
    [DAYS_OF_WEEK.TUESDAY]: "Tuesday",
    [DAYS_OF_WEEK.WEDNESDAY]: "Wednesday",
    [DAYS_OF_WEEK.THURSDAY]: "Thursday",
    [DAYS_OF_WEEK.FRIDAY]: "Friday",
    [DAYS_OF_WEEK.SATURDAY]: "Saturday",
}

export const IDENTIFIERS_VALUE = {
    ANNUAL: 'annual_subscription',
    MONTHLY: 'monthly_subscription',
}
export const IDENTIFIERS = {
    [IDENTIFIERS_VALUE.ANNUAL]: "Annual Subscription",
    [IDENTIFIERS_VALUE.MONTHLY]: "Monthly Subscription",
}

export const NotificationType = {
    USER:10,
    TRAINER:20,
    ALL:30,
    CUSTOM:40
}

export const TransactionStatus = [
    "EXPIRATION",
    "RENEWAL",
    "CANCELLATION",
    "UNCANCELLATION",
    "PRODUCT_CHANGE",
]

