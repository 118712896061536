import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeleteSession, GetSession} from "../../../services/session.service";
import {customStyles, STATUS} from "../../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {DeleteCountry, GetCountry} from "../../../services/country.service";

function Country(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');
    const typingTimeoutRef = useRef(null);

    useEffect(() => {
        getCountry();
    }, [keyword, page, perPage,status])


    const getCountry = async () => {
        setLoading(true)
        await GetCountry(keyword, page, perPage,1,status).then((result) => {
            if (result?.status) {
                if (result?.data?.countries) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result?.data?.pagination?.total);
                    result?.data?.countries?.map((dt, index) => {
                        rowData.push({
                            id: dt._id || '-',
                            name:dt?.name || '-',
                            iso2: dt?.iso2 || '-',
                            iso3: dt?.iso3 || '-',
                            status: STATUS[dt?.status],
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-'),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onCountryDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this country?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteCountry(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getCountry();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/countries/edit-country/` + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onCountryDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            grow: false,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'name',
            name: 'Name',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.name,
        },
        {
            id: 'iso2',
            name: 'iso2',
            grow: false,
            center: true,
            selector: rowData => rowData.iso2,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    const filterStatus = async (value) => {
        setStatus(value)
    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"sessionPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Countries</h1>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                                type="text"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset: 2}} lg={{span: 2, offset: 2}} xl={{span: 2, offset: 2}}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/countries/add-country"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline/> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} sm={12} md={8} lg={3} xl={3}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select className='formselect' aria-label="status"
                                         onChange={(e) => filterStatus(e.target.value)}
                                         value={status}
                            >
                                <option selected disabled value="" >Filter Status</option>
                                <option value={""}>All</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 8 }} lg={{ span: 2, offset: 7}} xl={{ span: 2, offset: 7 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setStatus('')
                        }}>
                            Reset Filter
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </div>
    )
}

export default Country;