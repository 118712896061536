import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetMusic = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }
    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.music, params);
    return response;
}

export const GetMusicById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.music + '/' + id,params);
    return response;
}

export const CreateMusic = async (data) => {
    const response = await POST(apiUrl.music, data);
    return response;
}

export const UpdateMusic = async (id, data) => {
    const response = await PUT(apiUrl.music, id, data);
    return response;
}

export const DeleteMusic = async (id) => {
    const response = await DELETE(apiUrl.music, id);
    return response;
}
