import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {GetType} from "../../services/users.service";
import {DeleteCategory, GetCategory, UpdateCategoryStatus} from "../../services/category.service";
import {constants, customStyles, IDENTIFIERS, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {GetPackage, UpdatePackageStatus} from "../../services/package.service";

function Packages(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');
    const typingTimeoutRef = useRef(null);





    useEffect( async () => {
        await getPackage();
    }, [keyword, page, perPage,status])



    const getPackage = async () => {
        setLoading(true)
        await GetPackage(keyword, page, perPage,1,status).then((result) => {
            if (result.status) {
                if (result.data.packages) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.packages.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            name: dt.name || '-',
                            price: dt.price || '-',
                            description: dt.description || '-',
                            best_value: dt.best_value || '-',
                            identifiers: IDENTIFIERS[dt.identifiers] || '-',
                            status: STATUS[dt?.status],
                            status_key:dt?.status,
                            created_at: moment(dt.created_at).format("MM/DD/YYYY"),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }


    const onStatusUpdate = (data) => {
        let dt = {
            status: (data?.status_key && data?.status_key==true ? false: true ),
        }

        swal({
            title: "Are you sure?",
            text: "Are you sure you want to update status of this package?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await UpdatePackageStatus(data.id,dt).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPackage();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/packages/edit-package/` + data.id, {
            data: data
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                {/*<Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>*/}
                <Dropdown.Item as="button" onClick={() => onStatusUpdate(data)}>{data.status_key? "Set Inactive":"Set Active"}</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '60px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'name',
            name: 'Name',
            maxWidth: '160px',
            sortable: true,
            selector: rowData => rowData.name,
        },
        {
            id: 'price',
            name: 'Price ($)',
            minWidth: '180px',
            selector: rowData => rowData.price,
        },
        {
            id: 'description',
            name: 'Description',
            // maxWidth: '400px',
            // minWidth: '300px',
            center: true,
            wrap: true,
            selector: rowData => rowData.description,
        },
        {
            id: 'identifiers',
            name: 'Identifiers',
            width: '160px',
            center: true,
            selector: rowData => rowData.identifiers,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'created_at',
            name: 'Created At',
            maxWidth: '140px',
            minWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];


    const filterStatus = async (value) => {
        setStatus(value)

    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"packagesPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Subscriptions</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={8} md={4} lg={{ span: 3, offset: 1 }} xl={{  span: 3, offset: 1 }}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select className='formselect' aria-label="status"
                                         onChange={(e) => filterStatus(e.target.value)}
                                         value={status}
                            >
                                <option selected disabled value="">Filter Status</option>
                                <option value={""}>All</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={3} lg={3} xl={3}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setStatus('')

                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Packages;