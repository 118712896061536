import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeleteOrder, GetOrder} from "../../services/order.service";
import {customStyles, IS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {GetCategory} from "../../services/category.service";
import {GetType, GetUsers} from "../../services/users.service";
import Select from "react-select";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import Papa from 'papaparse';

function Order(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [type, setType] = useState();
    const [selectedType, setSelectedType] = useState('');
    const [exportRows, setExportRows] = useState();


    useEffect(  async () => {
        await getType();
        await getUsers();
        await getOrder();
        window.scrollTo(0, 0);
    }, [keyword, page, perPage,selectedUser,selectedType])


    const getType = async () => {
        await GetType("OrderStatus").then((result) => {
            if (result.status && result.data) {
                const OrderStatus =  result.data[0].OrderStatus;
                setType(Object.values(OrderStatus));
            }
        })
    }

    const getOrder = async () => {
        setLoading(true)
        await GetOrder(keyword, page, perPage,1,selectedType,selectedUser).then((result) => {
            if (result.status) {
                if (result.data.orders) {
                    const rowData = [];
                    const exportData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.orders.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:`${dt?.user?.full_name} (${dt?.user?.email})` || '-',
                            order_number:dt?.order_number || '-',
                            shipping_address: dt?.shipping_address.address || '-',
                            amount: dt?.amount || '-',
                            // vat: dt?.vat || '-',
                            total_payable: dt?.vat + dt?.amount || '-',
                            payment_status: dt?.payment_status|| '-',
                            user_card_id: dt?.user?.stripe_customer_id|| '-',
                            status: dt?.status,
                            cancelled_by: (dt?.cancelled_by ? `${dt?.cancelled_by?.full_name} (${dt?.cancelled_by?.email})` : "-"),
                            created_at: moment(dt.created_at).fromNow(),
                        });

                        const productEntries = {};

                        dt.order_items.forEach((item, productIndex) => {
                            const productKeyPrefix = `product_${productIndex + 1}`;
                            const productEntry = {
                                [`${productKeyPrefix}_title(Price,Quantity,Total_Amount)`]: `${item?.product?.title} (${item?.product?.price + '$'},${item?.quantity},${item?.amount + ' $'})` || '-',
                                // [`${productKeyPrefix}_price`]: item?.product?.price + '$' || '-',
                                // [`${productKeyPrefix}_quantity`]: item?.quantity || '-',
                                // [`${productKeyPrefix}_total_amount`]: item?.amount + ' $' || '-',
                            };

                            Object.assign(productEntries, productEntry);

                        });
                        const orderEntry = {
                            s_no: index + 1,
                            customer_name: dt?.user?.full_name || '-',
                            customer_email: dt?.user?.email || '-',
                            order_number: dt?.order_number || '-',
                            tracking_id: dt?.tracking_id || '-',
                            shipping_address: dt?.shipping_address?.address || '-',
                            country: dt?.shipping_address?.country || '-',
                            state: dt?.shipping_address?.state || '-',
                            city: dt?.shipping_address?.city || '-',
                            zip_code: dt?.shipping_address?.zip_code || '-',
                            phone: dt?.shipping_address.phone || dt?.user?.phone || '-',
                            amount: '$'+ dt?.amount  || '-',
                            // vat: dt?.vat?.toFixed(2) + '$' || '-',
                            total_payable: '$'+ dt?.vat + dt?.amount  || '-',
                            payment_status: dt?.payment_status || '-',
                            status: dt?.status,
                            cancelled_by: dt?.cancelled_by
                                ? `${dt?.cancelled_by?.full_name} (${dt?.cancelled_by?.email})`
                                : '-',
                            order_date: moment(dt?.created_at).format('MM/DD/YYYY hh:mm A'),
                        };

                        Object.assign(orderEntry, productEntries);

                        exportData.push(orderEntry);

                    })

                    const csvColumns = [
                        's_no',
                        'customer_name',
                        'customer_email',
                        'order_number',
                        'tracking_id',
                        'shipping_address',
                        'zip_code',
                        'country',
                        'state',
                        'city',
                        'phone',
                        'amount',
                        'total_payable',
                        'payment_status',
                        'status',
                        'cancelled_by',
                        'order_date',
                    ];

                    const csvColumnsSet = new Set(csvColumns); // Create a Set to track added columns

                    exportData.forEach((orderEntry) => {
                        for (const key in orderEntry) {
                            if (key.startsWith('product_') && !csvColumnsSet.has(key)) {
                                csvColumns.push(key);
                                csvColumnsSet.add(key); // Add the key to the Set to prevent duplicates
                            }
                        }
                    });

                    const csv = Papa.unparse({
                        fields: csvColumns,
                        data: exportData,
                    });

                    setRows(rowData);
                    setExportRows(csv);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onOrderDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this order?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteOrder(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getOrder();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/orders/update-order-status/` + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push(`/orders/view/` + data.id, {
            data: data
        });
    }


    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Update Status</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View Order</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onOrderDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'50px',
            selector: rowData => rowData.id,
        },
        {
            id: 'user_id',
            name: 'User',
            grow: false,
            minWidth:'220px',
            maxWidth:'300px',
            center: true,
            selector: rowData => rowData.user_id,
        },
        {
            id: 'order_number',
            name: 'order number',
            width:'200px',
            center: true,
            grow: false,
            selector: rowData => rowData.order_number,
        },
        {
            id: 'payment_status',
            name: 'payment status',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.payment_status,
        },
        {
            id: 'amount',
            name: 'amount ($)',
            grow: false,
            center: true,
            selector: rowData => rowData.amount,
        },
        // {
        //     id: 'vat',
        //     name: 'vat ($)',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData.vat,
        // },
        {
            id: 'total_payable',
            name: 'total payable($)',
            minWidth: '150px',
            grow: false,
            center: true,
            selector: rowData => rowData.total_payable,
        },
        // {
        //     id: 'user_card_id',
        //     name: 'user card id',
        //     width:'200px',
        //     center: true,
        //     grow: false,
        //     selector: rowData => rowData.user_card_id,
        // },
        {
            id: 'shipping_address',
            name: 'shipping address',
            minWidth: '200px',
            maxWidth: '350px',
            grow: false,
            center: true,
            selector: rowData => rowData.shipping_address,
        },
        {
            id: ' status',
            name: 'status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'cancelled_by',
            name: 'cancelled by',
            minWidth: '200px',
            maxWidth: '350px',
            grow: false,
            center: true,
            selector: rowData => rowData.cancelled_by,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];


    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setUsers(result.data);
            }
        })
    }

    const optionsUsers = (item) => {
        setUserValue({ label: item.label, value:item.value })
        setSelectedUser(item.value);
    }

    const filterType = async (value) => {
        setSelectedType(value)
    }
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(exportRows[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(csv) {
        const link = document.createElement('a');
        // let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = 'orders-export.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    return (
        <div className={"ordersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Orders</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>
                        <Form.Group className="mb-3" controlId="users">
                            <Select
                                value={userValue}
                                placeholder={'Filter user'}
                                options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                className="basic-multi-select"
                                onChange={optionsUsers}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select id={"type"}
                                         type="text"
                                         placeholder="Filter Type"
                                         onChange={(e) => filterType(e.target.value)}
                                         value={selectedType}
                            >
                                <option selected disabled value="">Filter Type</option>
                                {type && type?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={ ()=>{
                            setUserValue(null)
                            setSelectedUser(null)
                            setSelectedType('')
                        }}>
                            Reset Search
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={{ span: 4, offset: 8 }} lg={{ span: 2, offset: 10 }} xl={{ span: 2, offset: 10 }}>
                        <Button className={"btn btn-green-theme h40 w-100 mb-1 mb-sm-3 mb-3"} onClick={() => downloadCSV(exportRows)}>Export CSV</Button>
                    </Col>
                </Row>



            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Order;