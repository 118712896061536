import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetQuestionnaire = async (keyword = '', page = '', perPage = '', is_paginate = 1, type = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['text'] = keyword;
    }

    if (type) {
        params['type'] = type;
    }
    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.questionnaire, params);
    return response;
}

export const GetAnswers = async (keyword = '', page = '', perPage = '', is_paginate = 1, type = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['user_id'] = keyword;
    }

    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }


    const response = await GET(apiUrl.answer, params);
    return response;
}

export const GetQuestionnaireById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.questionnaire + '/' + id,params);
    return response;
}

export const CreateQuestionnaire = async (data) => {
    const response = await POST(apiUrl.questionnaire, data);
    return response;
}

export const UpdateQuestionnaire = async (id, data) => {
    const response = await PUT(apiUrl.questionnaire, id, data);
    return response;
}

export const DeleteQuestionnaire = async (id) => {
    const response = await DELETE(apiUrl.questionnaire, id);
    return response;
}
