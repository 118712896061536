import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeleteModule, GetModule} from "../../services/module.service";
import {constants, customStyles, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {GetCategory} from "../../services/category.service";
import Select from "react-select";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {DeleteTraining, GetTraining} from "../../services/training.service";
import ImagePreview from "../../components/ImagePreview";

function Training(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');
    const [selectedCategory, setSelectedCategory] = useState();
    const [categoryValue, setCategoryValue] = useState();
    const [category, setCategory] = useState([]);
    const typingTimeoutRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        getTraining();
    }, [keyword, page, perPage,status])


    const getTraining = async () => {
        setLoading(true)
        await GetTraining(keyword, page, perPage,1,status).then((result) => {
            if (result?.status) {
                if (result?.data?.trainings) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result?.data?.pagination?.total);
                    result?.data?.trainings?.map((dt, index) => {
                        rowData.push({
                            id: dt._id || '-',
                            title: dt?.title || '-',
                            content: dt?.content || '-',
                            status: STATUS[dt?.status],
                            attachment: dt?.attachment,
                            steps: Array.isArray(dt?.steps) && dt?.steps.length > 0 ? dt.steps.map((step) => step.title).join(", ") : '-',
                            image: dt?.attachment && dt?.attachment.length > 0 ? dt?.attachment[0]?.path : null,
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-'),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }


    const onTrainingDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this training?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteTraining(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getTraining();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/trainings/edit-training/` + data.id, {
            data: data
        });
    }


    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onTrainingDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth: '60px',
            selector: rowData => rowData.id,
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth: '100px',
            center: true,
            selector: rowData => rowData.image,
            format: rowData => <img src={rowData.image}
                                    onClick={() => handleImageClick(rowData.image)}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Broken'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'title',
            name: 'Title',
            minWidth: '180px',
            maxWidth: '280px',
            sortable: true,
            grow: false,
            selector: rowData => rowData.title,
        },
        {
            id: 'content',
            name: 'Content',
            grow: false,
            minWidth: '180px',
            maxWidth: '280px',
            selector: rowData => rowData.content,
        },
        {
            id: 'steps',
            name: 'Steps',
            grow: false,
            center: true,
            minWidth: '180px',
            maxWidth: '280px',
            selector: rowData => rowData.steps,
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];


    const filterStatus = async (value) => {
        setStatus(value)
    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"trainingPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Trainings</h1>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{span: 2, offset: 2}} lg={{span: 2, offset: 2}} xl={{span: 2, offset: 2}}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/trainings/add-training"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline/> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select className='formselect' aria-label="status"
                                         onChange={(e) => filterStatus(e.target.value)}
                                         value={status}
                            >
                                <option selected disabled value="">Filter Status</option>
                                <option value={""}>All</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={{ span: 3, offset: 5 }} lg={{ span: 3, offset: 6}} xl={{ span: 2, offset: 7 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setStatus('')
                            setCategoryValue(null)
                            setSelectedCategory(null)
                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
            <ImagePreview
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                content={selectedImage}
            />
        </div>
    )
}

export default Training;