import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {GetUserById, GetUsers, UpdateUser} from "../../services/users.service";
import {GetSuggestionById, UpdateSuggestion} from "../../services/suggestions.service";
import {GetGoalById, UpdateGoal} from "../../services/goal.service";
import Select from "react-select";
import {GetCategory} from "../../services/category.service";



function EditGoal(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [startDate, setStartDate] = useState({ format: "mm/dd/yyyy" })
    const [selectedUser, setSelectedUser] = useState();
    const [editSelectedUser, setEditSelectedUser] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [editSelectedCategory, setEditSelectedCategory] = useState();
    const [category, setCategory] = useState([]);
    const [users, setUsers] = useState([]);

    const {register, watch,setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getUsers()
        await getCategory()
        await getGoalByid(id);

    }, [])

    const getGoalByid = async (id) => {
        await GetGoalById(id).then((result) => {
                if (result.status) {
                    if (result.data) {
                        setData(result.data);
                        setValue("title", result.data?.title);
                        setValue("duration", result.data?.duration);
                        // setValue("time", result.data?.time);
                        // setValue("repetition_schedule", result.data?.repetition_schedule);
                        setValue("start_date" ,result.data?.date ? new Date(result.data.date).toLocaleDateString('en-CA') : '')
                        setEditSelectedUser({ label:`${result.data?.user[0].full_name} (${result.data?.user[0].email})`, value: result.data?.user[0]._id })
                        setSelectedUser(result.data?.user[0]._id)
                        setEditSelectedCategory({ label:result.data?.category[0].name, value: result.data?.category[0]._id })
                        setSelectedCategory(result.data?.category[0]._id)

                    }
                } else {
                    return toast.error(result.message);
                }


        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }



    const onSubmit = async data => {
        if(!selectedCategory && !selectedUser ){
            setError('category' ,{message:"Category is required"})
            setError('user' ,{message:"User is required"})
            return false
        }else{
            clearErrors('category')
            clearErrors('user')
        }
        if(!selectedCategory){
            setError('category' ,{message:"Category is required"})
            return false
        }else{
            clearErrors('category')
        }
        if(!selectedUser){
            setError('user' ,{message:"User is required"})
            return false
        }else{
            clearErrors('user')
        }
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            category_id:selectedCategory,
            user_id:selectedUser,
            title: data.title || '-',
            duration: data.duration || '-',
            date: data.start_date ,

        }

        await UpdateGoal(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/goals');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }

    const getCategory = async () => {
        await GetCategory(null, null, null, 0, true,'Wellness').then((result) => {
            if (result.status && result.data) {
                setCategory(result.data);
            }
        })
    }

    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setStartDate(date.toLocaleDateString())
    }

    const optionsUsers = (item) => {
        setSelectedUser(item.value);
        setEditSelectedUser({ label:item.label, value:item.value })
        if(!item.value){
            setError('user' ,{message:"User is required"})
        }else{
            clearErrors('User')
        }
    }
    const optionsCategory = (item) => {
        setSelectedCategory(item.value);
        setEditSelectedCategory({ label:item.label, value:item.value })
        if(!item.value){
            setError('category' ,{message:"Category is required"})
        }else{
            clearErrors('category')
        }
    }
    return (
        <div className="AddNewGoal">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Goal</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>User*</Form.Label>
                                    <Select
                                        value={editSelectedUser}
                                        options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}

                                    />
                                    {errors.sports && <Form.Text className="text-muted validationText hasError">{errors.sports.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label className='d-block'>Category*</Form.Label>
                                    <Select
                                        value={editSelectedCategory}
                                        options={category && category?.map(e => ({ label: e.name, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsCategory}

                                    />
                                    {errors.category && <Form.Text className="text-muted validationText hasError">{errors.category.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Duration In Days*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter number of days"

                                        {...register('duration', {
                                            required: {
                                                value: "required",
                                                message: "Duration is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="number"
                                    />
                                    {errors.duration && <Form.Text
                                        className=" validationText hasError">{errors.duration.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Date*</Form.Label>
                                    <Form.Control
                                        id={"start_date"}
                                        placeholder="Enter Age"
                                        value={startDate.date}
                                        maxLength={3}
                                        {...register('start_date', {
                                            required: {
                                                value: "required",
                                                message: "Start Date is required"
                                            },
                                            onChange: (e) => onDateChange(e),
                                        })} type="date"
                                    />
                                    {errors.start_date && <Form.Text
                                        className=" validationText hasError">{errors.start_date.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="text">*/}
                            {/*        <Form.Label>Time In Min*</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter time in minutes"*/}

                            {/*            {...register('time', {*/}
                            {/*                required: {*/}
                            {/*                    value: "required",*/}
                            {/*                    message: "Time is required"*/}
                            {/*                },*/}
                            {/*                minLength: {*/}
                            {/*                    value: 1,*/}
                            {/*                    message: "Min length is 1"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 100,*/}
                            {/*                    message: "Max length is 100"*/}
                            {/*                },*/}
                            {/*            })} type="number"*/}
                            {/*        />*/}
                            {/*        {errors.time && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.time.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                        </Row>
                        {/*<Row>*/}

                        {/*    <Col  xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                        {/*        <Form.Group className={"mb-3"} controlId="status">*/}
                        {/*            <Form.Label className='d-block'>Select Repetition Schedule*</Form.Label>*/}
                        {/*            <Form.Select className='formselect' aria-label="status" {...register('repetition_schedule', {*/}
                        {/*                required: {*/}
                        {/*                    value: "required",*/}
                        {/*                    message: "Repetition Schedule is required"*/}
                        {/*                }*/}
                        {/*            })}>*/}
                        {/*                <option value="weekly">Weekly</option>*/}
                        {/*                <option value="daily">Daily</option>*/}
                        {/*            </Form.Select>*/}
                        {/*            {errors.repetition_schedule && <Form.Text*/}
                        {/*                className=" validationText hasError">{errors.repetition_schedule.message}</Form.Text>}*/}
                        {/*        </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Goal"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditGoal;