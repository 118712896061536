import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateGoal} from "../../services/goal.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetType, GetUsers} from "../../services/users.service";
import {GetDay} from "../../services/category.service";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {CreateTrainerAvailability} from "../../services/traineravailability.service";
import TimeDropdown from "../../components/TimeDropdown";
import {convertFromLocalToUTC, convertFromUTCToLocal} from "../../utils/helpers";

function AddTrainerAvailability(props) {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [selectedDay, setSelectedDay] = useState([]);
    const [day, setDay] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedStartTime, setSelectedStartTime] = useState('');
    const [selectedEndTime, setSelectedEndTime] = useState('');
    const {register, watch, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const getDay = async () => {
        await GetType("AvailabilityDays").then((result) => {
            if (result.status && result.data) {
                const AvailabilityDays = result.data[0].AvailabilityDays;
                const options = Object.entries(AvailabilityDays).map(([key, value]) => ({
                    label: value,
                    value: key,
                }));
                setDay(options);
            }
        });
    };
    const getUsers = async () => {
        await GetUsers(false, 'Trainer', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
            }
        })
    }

    useEffect(  async ()=>{
       await getUsers()
        await getDay()
    },[])

    useEffect(   ()=>{
        if(selectedEndTime){
            if (selectedStartTime === selectedEndTime) {
                setError('end_time', {message: 'Start time and end time cannot be the same.'});
                return false;
            }else{
                clearErrors('end_time')
            }
            // if (selectedStartTime > selectedEndTime) {
            //     setError('end_time', {message: 'End time must be greater than start time.'});
            //     return false;
            // }else{
            //     clearErrors('end_time')
            // }
        }

    },[selectedEndTime])

    const onSubmit = async formData => {
        if (!selectedDay && !selectedUser && !selectedStartTime && !selectedEndTime) {
            setError('day', { message: 'Day is required' });
            setError('user', { message: 'User is required' });
            setError('start_time', { message: 'Start Time is required' });
            setError('end_time', { message: 'End Time is required' });
            return false;
        } else {
            clearErrors('day');
            clearErrors('user');
            clearErrors('start_time');
            clearErrors('end_time');
        }

        if (!selectedDay) {
            setError('day', { message: 'Day is required' });
            return false;
        } else {
            clearErrors('day');
        }

        if (!selectedUser) {
            setError('user', { message: 'User is required' });
            return false;
        } else {
            clearErrors('user');
        }

        if (!selectedStartTime) {
            setError('start_time', { message: 'Start Time is required' });
            return false;
        } else {
            clearErrors('start_time');
        }

        if (!selectedEndTime) {
            setError('end_time', { message: 'End Time is required' });
            return false;
        } else {
            clearErrors('end_time');
        }

        if (selectedStartTime === selectedEndTime) {
            setError('end_time', {message: 'Start time and end time cannot be the same.'});
            return false;
        }else{
            clearErrors('end_time')
        }
        // if (selectedStartTime > selectedEndTime) {
        //     setError('end_time', {message: 'End time must be greater than start time.'});
        //     return false;
        // }else{
        //     clearErrors('end_time')
        // }

        setLoader(true)

        const data = [];
        console.log(formData ,"formData?.availability ")

        if (selectedDay.length > 1) {
            selectedDay.forEach(day => {
                data.push({
                    day: day.label,
                    trainer_id: selectedUser,
                    start_time: convertFromLocalToUTC(selectedStartTime),
                    end_time: convertFromLocalToUTC(selectedEndTime),
                    availability: formData?.availability === "1" ? true : false,
                });
            });
        } else {
            data.push({
                day: selectedDay[0].label,
                trainer_id: selectedUser,
                start_time: convertFromLocalToUTC(selectedStartTime),
                end_time: convertFromLocalToUTC(selectedEndTime),
                availability: formData?.availability === "1" ? true : false,
            });
        }

        await CreateTrainerAvailability({data}).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/trainer-availability');

            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };



    const optionsUsers = (item) => {
        setSelectedUser(item.value);
        if(!item.value){
            setError('user' ,{message:"User is required"})
        }else{
            clearErrors('user')
        }
    }
    const optionsDay = (selectedOptions) => {
        setSelectedDay(selectedOptions || []);

        if(!selectedOptions){
            setError('day' ,{message:"Day is required"})
        }else{
            clearErrors('day')
        }
    }


    return (
        <div className="trainerAvailability">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Availability</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="sports">
                                    <Form.Label className='d-block'>Professional*</Form.Label>
                                    <Select
                                        options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsUsers}

                                    />
                                    {errors.user && <Form.Text className="text-muted validationText hasError">{errors.user.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="day">
                                    <Form.Label className='d-block'>Day*</Form.Label>
                                    <Select
                                        options={day}
                                        className="basic-multi-select"
                                        onChange={optionsDay}
                                        value={selectedDay}
                                        isMulti
                                    />
                                    {errors.day && <Form.Text className="text-muted validationText hasError">{errors.day.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Start Time*</Form.Label>
                                   <TimeDropdown selectedTime={selectedStartTime} setSelectedTime={setSelectedStartTime}/>
                                    {errors.start_time && <Form.Text className="validationText hasError">{errors.start_time.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>End Time*</Form.Label>
                                    <TimeDropdown selectedTime={selectedEndTime} setSelectedTime={setSelectedEndTime}/>
                                    {errors.end_time && <Form.Text className="validationText hasError">{errors.end_time.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>

                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Availability Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('availability', {
                                        required: {
                                            value: "required",
                                            message: "Availability Status is required"
                                        }
                                    })}>
                                        <option value="1">Available</option>
                                        <option value="0">Unavailable</option>
                                    </Form.Select>
                                    {errors.availability && <Form.Text
                                        className=" validationText hasError">{errors.availability.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Availability"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddTrainerAvailability;