import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetModuleStep = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null,module_id) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['title'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (module_id) {
        params['module_id'] = module_id;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.module_step, params);
    return response;
}

export const GetModuleStepById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.module_step + '/' + id,params);
    return response;
}

export const CreateModuleStep = async (data) => {
    const response = await POST(apiUrl.module_step, data);
    return response;
}

export const UpdateModuleStep = async (id, data) => {
    const response = await PUT(apiUrl.module_step, id, data);
    return response;
}

export const DeleteModuleStep = async (id) => {
    const response = await DELETE(apiUrl.module_step, id);
    return response;
}
