import React from 'react'
import {Link, NavLink, useHistory} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import {EmptyLocalStorage, Logout} from "../services/auth/auth.service";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import {RiUserStarLine,RiAdvertisementLine,RiAdminLine,RiArticleLine,RiPagesLine,RiProductHuntLine,RiQuestionnaireLine} from "react-icons/ri";
import {BiBookContent,BiBell,BiMusic,BiVideo,BiCategoryAlt} from "react-icons/bi";
import {MdOutlineHomeRepairService,MdReportGmailerrorred,MdOutlineDashboard,MdOutlineViewModule,MdPostAdd,MdModelTraining,MdOutlineClass,MdOutlineVerified,MdOutlineVideoLibrary } from "react-icons/md";
import {HiOutlineUsers} from "react-icons/hi";
import {GrPlan, GrMapLocation, GrTransaction} from "react-icons/gr";
import {GiAudioCassette} from "react-icons/gi";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {BsMic,BsChatLeftQuote,BsQuestionSquare,BsBarChartSteps,BsJournals,BsClock,BsCalendar4Range} from "react-icons/bs";
import {TbReceiptTax} from "react-icons/tb";
import swal from "sweetalert";
import {Submenu} from "./Submenu";
import {GoPackage} from "react-icons/go";

function SideBar() {
    let history = useHistory();
    async function handleLogout() {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                toast.success("logged out successfully");
                await EmptyLocalStorage();
                history.push('/');
            }
        })
        // await Logout().then(async (data) => {
        //     if (data.status) {
        //         toast.success(data.message);
        //         await EmptyLocalStorage();
        //         history.push('/');
        //     } else {
        //         toast.error(data.message);
        //     }
        // }).catch((error) => {
        //     if (error.response.status == 401) {
        //         EmptyLocalStorage()
        //         history.push('/');
        //     } else {
        //         (error);
        //         return toast.error(error.response.data.message);
        //     }
        // })
    }

    const locationDataManagerItems = [
        { path: '/countries', label: 'Country' ,icon: null },
        { path: '/states', label: 'State' ,icon: null },
        { path: '/cities', label: 'City' ,icon: null },
        // Add more user-related submenu items as needed
    ];
    return (

        <div className="d-flex flex-column flex-shrink-0  bg-light">
                <ul className="nav nav-pills flex-column mb-auto">
                    <li className="nav-item">
                        <NavLink to="/dashboard" className="nav-link " activeClassName="active" >
                            <MdOutlineDashboard/>
                            Dashboard
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/manage-users" className="nav-link  " activeClassName="active" >
                            <HiOutlineUsers/>
                            Users
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admins" className="nav-link  " activeClassName="active" >
                            <RiAdminLine/>
                            Admins
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/professionals" className="nav-link  " activeClassName="active" >
                            <RiUserStarLine/>
                            Professionals
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/trainer-verification" className="nav-link  " activeClassName="active" >
                            <MdOutlineVerified/>
                            Professional SignUp Verification
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/trainer-availability" className="nav-link  " activeClassName="active" >
                            <BsClock/>
                            Professional Availability
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/appointments" className="nav-link  " activeClassName="active" >
                            <BsCalendar4Range/>
                            Appointments
                        </NavLink>
                    </li>
                    <Submenu items={locationDataManagerItems} name={'Location Data Manager'} icon={ <GrMapLocation />}/>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/goals" className="nav-link  " activeClassName="active" >*/}
                    {/*        <IoMdCheckmarkCircleOutline/>*/}
                    {/*        Goals*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <NavLink to="/musics" className="nav-link  " activeClassName="active" >
                            <BiMusic/>
                            Music
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/videos" className="nav-link  " activeClassName="active" >
                            <BiVideo/>
                            Videos
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/podcasts" className="nav-link  " activeClassName="active" >
                            <BsMic/>
                            Podcasts
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/podcast-episodes" className="nav-link  " activeClassName="active" >
                            <GiAudioCassette/>
                            Podcast Episodes
                        </NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink to="/daily-quotes" className="nav-link  " activeClassName="active" >
                        <BsChatLeftQuote/>
                        Daily Quotes
                    </NavLink>
                </li>
                    <li className="nav-item">
                        <NavLink to="/articles" className="nav-link  " activeClassName="active" >
                            <RiArticleLine/>
                            Articles
                        </NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/plans" className="nav-link  " activeClassName="active" >*/}
                    {/*        <GrPlan/>*/}
                    {/*        Plans*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/journals" className="nav-link  " activeClassName="active" >*/}
                    {/*        <BsJournals/>*/}
                    {/*        Journals*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <NavLink to="/posts" className="nav-link  " activeClassName="active" >
                            <MdPostAdd/>
                            Posts
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/orders" className="nav-link  " activeClassName="active" >
                            <AiOutlineShoppingCart/>
                            Order
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/categories" className="nav-link  " activeClassName="active" >
                            <BiCategoryAlt/>
                            Categories
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/products" className="nav-link  " activeClassName="active" >
                            <RiProductHuntLine/>
                            Products
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/services" className="nav-link  " activeClassName="active" >
                            <MdOutlineHomeRepairService/>
                            Services
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/sessions" className="nav-link  " activeClassName="active" >
                            <MdOutlineClass/>
                            Sessions
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/questionnaire" className="nav-link  " activeClassName="active" >
                            <BsQuestionSquare/>
                            Self-Assessment
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/modules" className="nav-link" activeClassName="active" >
                            <MdOutlineViewModule/>
                            Modules
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/module-steps" className="nav-link" activeClassName="active" >
                            <BsBarChartSteps/>
                            Module Steps
                        </NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/trainings" className="nav-link" activeClassName="active" >*/}
                    {/*        <MdModelTraining/>*/}
                    {/*        Trainings*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/training-steps" className="nav-link" activeClassName="active" >*/}
                    {/*        <BsBarChartSteps/>*/}
                    {/*        Training Steps*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li>
                        <NavLink to="/packages" className="nav-link" activeClassName="active">
                            <GoPackage/>
                            Subscriptions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/ads" className="nav-link" activeClassName="active">
                            <RiAdvertisementLine />
                            Advertisement
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/transactions" className="nav-link" activeClassName="active">
                            <GrTransaction/>
                            Transactions
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/notifications" className="nav-link " activeClassName="active">
                            <BiBell />
                            Notification
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/breathing-video" className="nav-link " activeClassName="active">
                            <MdOutlineVideoLibrary />
                            Breathing Video
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/settings/tax" className="nav-link " activeClassName="active">
                            <TbReceiptTax />
                            Tax
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/reports" className="nav-link " activeClassName="active">
                            <MdReportGmailerrorred/>
                            Reports
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/faqs" className="nav-link " activeClassName="active">
                            <RiQuestionnaireLine/>
                            FAQs
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/pages" className="nav-link " activeClassName="active">
                            <RiPagesLine/>
                            Pages
                        </NavLink>
                    </li>

                    {/*<li>*/}
                    {/*    <NavLink to="/push-notification-history" className="nav-link " activeClassName="active">*/}
                    {/*        <AiOutlineBell/>*/}
                    {/*        Push Notifications*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                </ul>

        </div>
    )
}

export default SideBar
