import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {CreatePushNotification} from "../../services/pushnotification.service";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
function PushNotification(props) {

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 1);
    let history = useHistory();
    const [isSchedule, setIsSchedule] = useState(null);
    const [scheduleDate, setScheduleDate] = useState(currentDate);
    const [loader, setLoader] = useState(false);
    const { register, reset, setError, clearErrors, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });


    useEffect(async () => {

    }, [])

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }
    const DirectSchedule = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const Scheduled = scheduleDate.toISOString().slice(0, 19).replace('T', ' ');
    // DirectSchedule.setMinutes(DirectSchedule.getMinutes() + 15);

    const schedule_date = isSchedule ==1 ? Scheduled :DirectSchedule;





    const onSubmit = async data => {

        setLoader(true)
        await CreatePushNotification({
            message: data.message,
            is_schedule:data.is_schedule,
            schedule_date: schedule_date,
            type:"10"
        }).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                reset({
                    message: '',
                })
                history.push('/push-notification-history');
            } else {
                setLoader(false)

                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            (error);
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const handleSchedule=(e)=>{
        if(e.target.value==1){
            setIsSchedule('1')
        }else{
            setIsSchedule('0')
        }
    }

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Push Notifications</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Schedule Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('is_schedule', {
                                        required: {
                                            value: "required",
                                            message: "Schedule Status is required"
                                        },
                                        onChange: (e) => handleSchedule(e),

                                    })}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="0">Direct</option>
                                        <option value="1">Schedule</option>
                                    </Form.Select>
                                    {errors.is_schedule && <Form.Text
                                        className=" validationText hasError">{errors.is_schedule.message}</Form.Text>}
                                </Form.Group>
                                {isSchedule == 0 ?
                                    <p  className="text-muted">Please note this will be send directly to the user within 15 mins of submitting.</p>
                                    : ''}
                            </Col>
                        </Row>
                        {isSchedule == 1 ?<Row> <Col className="" xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Form.Label>Select Schedule Time*</Form.Label> <br/>
                            <DateTimePicker className={"date-time-picker mb-3"} onChange={setScheduleDate} value={scheduleDate} minDate={currentDate} />
                            {errors.schedule_time && <Form.Text
                                className="text-muted validationText hasError">{errors.schedule_time.message}</Form.Text>}
                        </Col></Row> : ''}

                        <Row>
                            <Col className="mb-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="description">
                                    <Form.Label>Message*</Form.Label>
                                    <Form.Control as="textarea" id='custom-text-area' rows={5} {...register('message', {
                                        required: {
                                            value: "required",
                                            message: "Message is required"
                                        },
                                        minLength: {
                                            value: 1,
                                            message: "Min length is 1"
                                        },
                                        maxLength: {
                                            value: 450,
                                            message: "Max length is 450"
                                        },
                                    })} />
                                    {errors.message && <Form.Text
                                        className="text-muted validationText hasError">{errors.message.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#4169e1"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Push"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default PushNotification
