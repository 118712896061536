import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateQuestionnaire, GetQuestionnaireById, UpdateQuestionnaire} from "../../services/questionnaire.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {GetType} from "../../services/users.service";


function EditQuestion(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [optionInput, setOptionInput] = useState('');
    const [options, setOptions] = useState([]);
    const [type, setType] = useState();
    const {register, watch,setValue, handleSubmit, setError, clearErrors,control, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const selectedType = watch('type');
    useEffect(async () => {
        let id = props.match.params.id;
        await getType();
        await getQuestionnaireByid(id);

    }, [])

    const getQuestionnaireByid = async (id) => {
        await GetQuestionnaireById(id).then((result) => {
            if (result?.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("text", result.data?.text);
                    setValue("type", result.data?.type);
                    if(result.data?.options){
                        setOptions(result.data?.options)
                    }

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async data => {
        if(data.type == 'dropdown' || data.type == 'radio' ){
            if(options?.length == 0){
                setError('options' ,{message:"Options is required"})
                return false
            }else if(options?.length == 1){
                setError('options' ,{message:"A minimum of 2 options is required"})
                return false
            }else{
                clearErrors('options')
            }

        }

        setLoader(true)
        if (selectedType === 'text') {
            setOptions([])
        }
        let id = props.match.params.id;
        let dt = {
            text: data.text || '-',
            type: data.type ,
            ...(selectedType != 'text' && { options:options }),

        }

        await UpdateQuestionnaire(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/questionnaire');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };


    const handleInputChange = (event) => {
        setOptionInput(event.target.value);
    };

    const handleTagAdd = () => {
        if (optionInput.trim() !== '') {
            setOptions([...options, optionInput.trim()]);
            setOptionInput('');

        }
        clearErrors('options')
    };

    const handleTagRemove = (tag) => {
        const updatedOptions = options.filter((t) => t !== tag);
        setOptions(updatedOptions);
        clearErrors('options')
    };

    const renderOptions = () => {
        if (selectedType === 'dropdown' || selectedType === 'radio') {

            return (
                <>
                    <Form.Label className='d-block'>Options</Form.Label>
                    <input
                        className="form-control"
                        type="text"
                        value={optionInput}
                        onChange={handleInputChange}
                        placeholder="Enter Option"
                    />
                    <button className={"btn btn-green-theme w-50 mt-3"} type={'button'} onClick={handleTagAdd}>Add Option</button>
                    <div className="tag-list">
                        {options.map((option, index) => (
                            <span key={index} className="tag">
                                {option}
                                <button
                                    className="remove-button"
                                    type={'button'}
                                    onClick={() => handleTagRemove(option)}
                                >
              x
            </button>
          </span>
                        ))}
                    </div>
                </>
            );
        }

        return null;
    };

    const getType = async () => {
        await GetType("QuestionnaireTypes").then((result) => {
            if (result.status && result.data) {
                const serviceTypes =  result.data[0].QuestionnaireTypes;
                setType(Object.values(serviceTypes));
            }
        })
    }

    return (
        <div className="EditQuestion">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Question</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="text">
                                    <Form.Label>Question*</Form.Label>
                                    <textarea
                                        id='custom-text-area'
                                        rows={6} cols={55}
                                               placeholder="Enter Question"
                                               {...register('text', {
                                                   required: {
                                                       value: "required",
                                                       message: "Question is required"
                                                   },
                                                   pattern:{
                                                       value:/^(?!\s).*$/,
                                                       message:"Question should not start with a space"
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       message: "Min length is 3"
                                                   },
                                                   maxLength: {
                                                       value: 500,
                                                       message: "Max length is 500"
                                                   },
                                               })} type="text" />
                                    {errors.text && <Form.Text
                                        className=" validationText hasError">{errors.text.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="type">
                                    <Form.Label className='d-block'>Select Type*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type" {...register('type', {
                                        required: {
                                            value: "required",
                                            message: "Type is required"
                                        }
                                    })}>
                                        {type && type.map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.type && <Form.Text
                                        className=" validationText hasError">{errors.type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="options">
                                    {renderOptions()}
                                    {errors.options && <Form.Text
                                        className=" validationText hasError">{errors.options.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Question"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditQuestion;