import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {DeleteComment, GetComment, GetLike} from "../../services/post.service";
import {constants, customStyles, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {MenuDots} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";

function Likes(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');





    useEffect(  async () => {
        let id = props.match.params.id;
        await getLike(id);
    }, [keyword, page, perPage,])


    const getLike = async (id) => {
        setLoading(true)
        await GetLike(id,keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.likes) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.likes.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            name:dt?.user?.full_name || '-',
                            email:dt?.user?.email || '-',
                            post_id:dt?.post_id|| '-',
                            image: dt?.user?.image || null,
                            // created_at: moment(dt.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }



    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'50px',
            selector: rowData => rowData.id,
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth: '100px',
            selector: rowData => rowData.image,
            format: rowData => <img src={rowData.image}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Broken'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'name',
            name: 'Name',
            grow: false,
            minWidth:'220px',
            maxWidth:'300px',
            center: true,
            selector: rowData => rowData.name,
        },
        {
            id: 'email',
            name: 'Email',
            grow: false,
            minWidth:'220px',
            maxWidth:'400px',
            selector: rowData => rowData.email,
        },
        // {
        //     id: 'created_at',
        //     name: 'Liked At',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData.created_at,
        // }
    ];




    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Post Likes</h1>
            <hr />
            <div className={"customOrderFilters"}>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Likes;