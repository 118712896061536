import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {GetType} from "../../services/users.service";
import {DeleteCategory, GetCategory, UpdateCategoryStatus} from "../../services/category.service";
import {constants, customStyles, IDENTIFIERS, STATUS} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {GetPackage, UpdatePackageStatus} from "../../services/package.service";
import {GetAds, UpdateAds} from "../../services/ads.service";
import ImagePreview from "../../components/ImagePreview";

function Ads(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');
    const typingTimeoutRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');




    useEffect( async () => {
        await getAds();
    }, [keyword, page, perPage,status])



    const getAds = async () => {
        setLoading(true)
        await GetAds(keyword, page, perPage,1,status).then((result) => {
               console.log(result,"result")
            if (result.status) {
                if (result.data.advertisements) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.advertisements?.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            Sno:index + 1|| '-',
                            image: dt.image_url || '-',
                            content: dt.content || '-',
                            status: STATUS[dt.is_active],
                            status_key:dt?.is_active,
                            created_at: moment(dt.created_at).format("MM/DD/YYYY"),
                            updated_at: moment(dt.updated_at).format("MM/DD/YYYY"),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }


    const onStatusUpdate = (data) => {
        let dt = {
            content:"content",
            image_url:data.image,
            is_active: (data?.status_key && data?.status_key==true ? false: true ),
        }

        swal({
            title: "Are you sure?",
            text: "Are you sure you want to update status of this ad?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await UpdateAds(data.id,dt).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getAds();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/ads/' + data.id, {
            data: data
        });
    }
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onStatusUpdate(data)}>{data.status_key? "Set Inactive":"Set Active"}</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '60px',
            sortable: true,
            selector: rowData => rowData.Sno,
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth: '100px',
            selector: rowData => rowData.image,
            format: rowData => <img src={rowData.image}
                                    onClick={() => handleImageClick(rowData.image)}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    alt={'Broken'}
                                    className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        },
        {
            id: 'status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'created_at',
            name: 'Created At',
            maxWidth: '140px',
            minWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            maxWidth: '140px',
            minWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];


    const filterStatus = async (value) => {
        setStatus(value)

    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    return (
        <div className={"packagesPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Advertisement</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/*<InputGroup className="mb-3 search-group">*/}
                        {/*    <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>*/}
                        {/*    <FormControl*/}
                        {/*        placeholder="Search"*/}
                        {/*        aria-label="Search"*/}
                        {/*        aria-describedby="Search"*/}
                        {/*        maxLength={"255"}*/}
                        {/*        onKeyUp={handleKeyUp}*/}
                        {/*    />*/}
                        {/*</InputGroup>*/}
                    </Col>
                    <Col xs={12} sm={8} md={4} lg={{ span: 3, offset: 1 }} xl={{  span: 3, offset: 1 }}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select className='formselect' aria-label="status"
                                         onChange={(e) => filterStatus(e.target.value)}
                                         value={status}
                            >
                                <option selected disabled value="">Filter Status</option>
                                <option value={""}>All</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={3} lg={3} xl={3}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setStatus('')

                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
            <ImagePreview
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                content={selectedImage}
            />
        </div>
    )
}

export default Ads;