import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetState = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null, country_id = null,state_id = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }


    if (keyword) {
        params['name'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }
    if (country_id) {
        params['country_id'] = country_id;
    }
    if(is_paginate==1){
        params['paginate'] = is_paginate;
    }
    const response = await GET(apiUrl.states, params);
    return response;
}

export const GetStateById = async (id) => {
    let params = {};
    let response = '';
    response = await GET(apiUrl.states + '/' + id,params);
    return response;
}

export const CreateState = async (data) => {
    const response = await POST(apiUrl.states, data);
    return response;
}

export const UpdateState = async (id, data) => {
    const response = await PUT(apiUrl.states, id, data);
    return response;
}

export const DeleteState = async (id) => {
    const response = await DELETE(apiUrl.states, id);
    return response;
}
