import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {GetCountry, GetCountryById, UpdateCountry} from "../../../services/country.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../../services/auth/auth.service";
import {GetStateById, UpdateState} from "../../../services/state.service";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";

function EditState(props) {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [country, setCountry] = useState([]);
    const [data, setData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [editSelectedCountry, setEditSelectedCountry] = useState();
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });


    useEffect(async () => {
        let id = props.match.params.id;
        await getCountry();
        await getStateByid(id);


    }, [])

    const getCountry = async () => {
        await GetCountry(null, null, null, 0).then((result) => {
            if (result.status && result.data) {
                setCountry(result.data);
            }
        })
    }
    const getStateByid = async (id) => {
        await GetStateById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("name", result.data?.name);
                    setEditSelectedCountry({ label:result.data?.country.name, value: result.data?.country._id })
                    setSelectedCountry(result.data?.country_id)
                    setValue("status", (result.data?.status && result.data?.status == true ? "1":"0"));

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }

    const onSubmit = async data => {
        if(!selectedCountry){
            setError('country' ,{message:"Country is required"})
            return false
        }else{
            clearErrors('country')
        }
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            name: data.name || '-',
            country_id: selectedCountry ,
            status: (data?.status && data?.status==1 ? true: false ),
        }

        await UpdateState(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/states');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const optionsCountry = (item) => {
        setSelectedCountry(item.value);
        if(!item.value){
            setError('country' ,{message:"Country is required"})
        }else{
            clearErrors('country')
        }
    }
    return (
        <div className="AddNewState">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit State</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label className='d-block'>Country*</Form.Label>
                                    <Select
                                        value={editSelectedCountry}
                                        options={country && country?.map(e => ({ label: e.name, value:e._id }))}
                                        className="basic-multi-select"
                                        onChange={optionsCountry}

                                    />
                                    {errors.country && <Form.Text className="text-muted validationText hasError">{errors.country.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="duration">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Name"

                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 60,
                                                message: "Max length is 60"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status" {...register('status', {
                                        required: {
                                            value: "required",
                                            message: "Status is required"
                                        }
                                    })}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update State"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditState;