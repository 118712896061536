import React, {useEffect, useState} from 'react';
import {GetUsers} from "../../services/users.service";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetGoalById, UpdateGoal} from "../../services/goal.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetTrainerVerificationById, UpdateTrainerVerification} from "../../services/trainerVerification.service";

function EditCode(props) {
    let history = useHistory();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });


    useEffect(  async ()=>{
        let id = props.match.params.id;
        // await getUsers()
        await getTrainerVerificationByid(id);

    },[])

    const getTrainerVerificationByid = async (id) => {
        await GetTrainerVerificationById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("fullname", result.data?.fullname);
                    setValue("email", result.data?.email);
                    setValue("code", result.data?.code);

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }



    const onSubmit = async data => {
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            fullname: data.fullname || '-',
            email: data.email || '-',
            code: data.code,
        }

        await UpdateTrainerVerification(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {

                toast.success(data.message);
                history.push('/trainer-verification');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };


    const getUsers = async () => {
        await GetUsers(false, 'Trainer', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                setUsers(result.data);
                console.log(result.data,"trainer array")
            }
        })
    }

    // const optionsUsers = (item) => {
    //     const matchingObject = users.find(obj => obj._id === item.value);
    //     if (matchingObject) {
    //         setValue('email', matchingObject.email);
    //         setValue('fullname', matchingObject.full_name);
    //     }
    //     if(!item.value){
    //         setError('user' ,{message:"Trainer is required"})
    //     }else{
    //         clearErrors('user')
    //     }
    // }

    return (
        <div className="TrainerVerification">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Verification Code</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            {/*<Col xs={12} sm={12} md={12} lg={10} xl={10}>*/}
                            {/*    <Form.Group className="mb-3" controlId="sports">*/}
                            {/*        <Form.Label className='d-block'>Trainer*</Form.Label>*/}
                            {/*        <Select*/}
                            {/*            options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id }))}*/}
                            {/*            className="basic-multi-select"*/}
                            {/*            onChange={optionsUsers}*/}

                            {/*        />*/}
                            {/*        {errors.user && <Form.Text className="text-muted validationText hasError">{errors.user.message}</Form.Text>}*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        {...register('fullname', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Full Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.fullname && <Form.Text
                                        className="text-muted validationText hasError">{errors.fullname.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        disabled
                                        {...register('email')} type="email" />
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="text">aw
                                    <Form.Label>Code*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Code"

                                        {...register('code', {
                                            required: {
                                                value: "required",
                                                message: "Code is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Code should not start with a space"
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "Min length is 4"
                                            },
                                            maxLength: {
                                                value: 4,
                                                message: "Max length is 4"
                                            },
                                        })} type="number"
                                    />
                                    {errors.code && <Form.Text
                                        className=" validationText hasError">{errors.code.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col  xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Code"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditCode;