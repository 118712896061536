import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetUserById, UpdateUser} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {uploadToS3} from "../../utils/helpers";
import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {FadeLoader} from "react-spinners";

function EditAdmin(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [image, setImage] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [file, setFile] = useState(null)
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const regex= new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$")
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format
    useEffect(async () => {
        let id = props.match.params.id;
        await getUserByid(id);
    }, [])

    const getUserByid = async (id) => {
        await GetUserById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("username", result.data?.full_name);
                    setValue("email", result.data?.email);
                    setValue("phone", result.data?.phone);
                    setValue("address", result.data?.address);
                    setValue("dob" ,result.data?.dob ? new Date(result.data.dob).toLocaleDateString('en-CA') : '');
                    setValue("gender", result.data?.gender);
                    // setValue("type", result.data?.type);
                    if(result.data?.image){
                        setImage(result.data?.image)
                        setIsUpload(true)
                    }
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }


    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {
        if(isUploadOverWrite){
            if(file.type.startsWith('image/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }}
        setLoader(true)
        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Media is required"})
            }

            let uploadImage = await uploadToS3(file.type,file.name,file,'user');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            data.media=uploadImage;
        }else {
            data.media = image
        }
        let id = props.match.params.id;
        let dt = {
            full_name: data.username,
            address: data.address,
            dob: data.dob,
            gender:data.gender,
            type:'Admin',
            device_type: 'web',
            device_token: 'user',
            image: data.media,
        }
        if (data.password && data.password_confirmation) {
            dt.password = data.password;
            dt.password_confirmation = data.password_confirmation;
        }
        await UpdateUser(id, dt).then(async (data) => {
            setLoader(false)
            if(data.status){
                toast.success(data.message);
                history.push('/admins');
            } else{
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const onAgeChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setAge(date.toLocaleDateString())
    }

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
    }

    const validateMedia = (fileList) => {
        const file = fileList[0];
        if (file && file.size > 5 * 1024 * 1024) {
            return 'Image size should be less than 5MB';
        }else if(!image || !file){
            return 'Image is required';
        }
        return true; // Validation passed
    };

    return (
        <div className="EditUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Admin</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={7}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 MB)</Form.Text> </Form.Label>
                                    <br/>
                                    <input
                                        {...register('media',{
                                            validate: validateMedia
                                        })}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUpload ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img  src={image} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        {...register('username', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Full Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.username && <Form.Text
                                        className="text-muted validationText hasError">{errors.username.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="email" readOnly/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Gender*</Form.Label>
                                    <Form.Select
                                        id={"gender"}
                                        type="text"
                                        placeholder="Select Gender"
                                        {...register("gender", {
                                                required: {
                                                    value: "required",
                                                    message: "Gender is required"
                                                }
                                            }
                                        )}
                                    >
                                        <option selected disabled value="">Select Gender</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Non-binary"}>Non-binary</option>
                                        <option value={"Transgender"}>Transgender</option>
                                        <option value={"Gender Neutral"}>Gender Neutral</option>
                                        <option value={"Agender"}>Agender</option>
                                        <option value={"Pangender"}>Pangender</option>
                                        <option value={"Two-spirit"}>Two-spirit</option>
                                        <option value={"Genderqueer"}>Genderqueer</option>
                                        <option value={"Third Gender"}>Third Gender</option>
                                        <option value={"Gender Fluid"}>Gender Fluid</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Select>
                                    {errors.gender && <Form.Text
                                        className=" validationText hasError">{errors.gender.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {/*<Col  xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className={"mb-3"} controlId="type">*/}
                            {/*        <Form.Label className='d-block'>Select Type*</Form.Label>*/}
                            {/*        <Form.Select className='formselect' aria-label="type" {...register('type', {*/}
                            {/*            required: {*/}
                            {/*                value: "required",*/}
                            {/*                message: "Type is required"*/}
                            {/*            }*/}
                            {/*        })}>*/}
                            {/*            <option value="Admin">Admin</option>*/}
                            {/*            <option value="User">User</option>*/}
                            {/*            <option value="Trainer">Trainer</option>*/}
                            {/*        </Form.Select>*/}
                            {/*        {errors.type && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.type.message}</Form.Text>}*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>DOB*</Form.Label>
                                    <Form.Control
                                        id={"age"}
                                        placeholder="Enter Age"
                                        value={age.date}
                                        max={maxDate}
                                        maxLength={3}
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "DOB is required"
                                            },
                                            onChange: (e) => onAgeChange(e),
                                        })} type="date"
                                    />
                                    {errors.dob && <Form.Text
                                        className=" validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="phone">*/}
                            {/*        <Form.Label>Phone*</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            placeholder="Enter Phone"*/}
                            {/*            id={"formSignUpName"}*/}
                            {/*            maxLength={25}*/}
                            {/*            minLength={3}*/}
                            {/*            {...register('phone', {*/}
                            {/*                required: {*/}
                            {/*                    value: "required",*/}
                            {/*                    message: "Phone is required"*/}
                            {/*                },*/}
                            {/*                pattern: {*/}
                            {/*                    value: regex,*/}
                            {/*                    message: "Please enter valid phone number"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 25,*/}
                            {/*                    message: "Max length is 25"*/}
                            {/*                },*/}
                            {/*            })} type="number"*/}
                            {/*        />*/}
                            {/*        {errors.phone && <Form.Text*/}
                            {/*            className="text-muted validationText hasError">{errors.phone.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('address', {
                                            required: {
                                                value: "required",
                                                message: "Address is required"
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "Max length is 5"
                                            },
                                            maxLength: {
                                                value: 200,
                                                message: "Max length is 200"
                                            },
                                        })} type="text" />
                                    {errors.address && <Form.Text
                                        className="text-muted validationText hasError">{errors.address.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            maxLength={255}
                                            minLength={8}
                                            {...register('password', {
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                    message: 'Password should contain a mix of uppercase and lowercase letters, numbers, and special characters'
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            onClick={() => setConfirmPass(!confirmPass)}
                                            type={pass ? "password" : "text"} />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            disabled={confirmPass}
                                            maxLength={25}
                                            minLength={3}
                                            {...register('password_confirmation', {
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: (value) => {
                                                    if ( password.current?.length > 0 && !value) {
                                                        return "The confirm password is required";
                                                    } else if (password.current && password.current?.length > 0) {
                                                        return value === password.current || "The passwords do not match";
                                                    }
                                                    return true;
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" disabled={confirmPass} onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>


                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Admin"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditAdmin;