import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {DeleteAppointment, GetAppointment} from "../../services/appointment.service";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {GetCategory} from "../../services/category.service";
import {GetType, GetUsers} from "../../services/users.service";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {customStyles} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import {convertDateUTCToLocal} from "../../utils/helpers";
import ExportCsv from "../../components/ExportCSV";

function Appointments(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();
    const [perPage, setPerPage] = useState(20);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [selectedTrainer, setSelectedTrainer] = useState();
    const [trainer, setTrainers] = useState([]);
    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState();
    const [trainerValue, setTrainerValue] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const typingTimeoutRef = useRef(null);
    const [startDate, setStartDate] = useState({ format: "mm/dd/yyyy" })
    const [date, setDate] = useState()
    const [exportRows, setExportRows] = useState();

    const getType = async () => {
        await GetType("AppointmentStatus").then((result) => {
            if (result.status && result.data) {
                const AppointmentStatus =  result.data[0].AppointmentStatus;
                setStatus(Object.values(AppointmentStatus));
            }
        })
    }


    useEffect(  async () => {
        await getType()
       await getUsers()
        await getTrainers()
        await  getAppointment();
    }, [date, page, perPage,selectedStatus,selectedUser,selectedTrainer])


    function convertToLcalTime(time) {
        const utcTime = moment.utc(time, 'HH:mm');

// Convert UTC time to local time
        const localTime = utcTime.local().format('HH:mm');

        const [hours, minutes] = localTime.split(':');

        let formattedHours = parseInt(hours);
        let period = 'AM';

        if (formattedHours === 0) {
            formattedHours = 12;
        } else if (formattedHours === 12) {
            period = 'PM';
        } else if (formattedHours > 12) {
            formattedHours -= 12;
            period = 'PM';
        }

        const formattedTime = `${formattedHours}:${minutes.padStart(2, '0')} ${period}`;
        return formattedTime;
    }
    const getAppointment = async () => {
        setLoading(true)
        await GetAppointment(date, page, perPage,1,selectedStatus,selectedUser,selectedTrainer,).then((result) => {
            if (result.status) {
                if (result.data.appointments) {
                    const rowData = [];
                    const exportData = [];
                    setPage(result?.data?.pagination?.currentPage)
                    setTotalRows(result.data.pagination.total);
                    result.data.appointments.map((dt, index) => {
                        rowData.push({
                            id:dt._id || '-',
                            user_id:`${dt?.user?.full_name} (${dt?.user?.email})` || '-',
                            trainer_id:`${dt?.trainer?.full_name} (${dt?.trainer?.email})` || '-',
                            date: (convertDateUTCToLocal(dt.date,dt.start_time))  || '-',
                            start_time: convertToLcalTime(dt.start_time)  || '-',
                            end_time: convertToLcalTime(dt.end_time)  || '-',
                            duration_in_mins: dt.duration_in_mins  || '-',
                            appointment_type: dt.appointment_type  || '-',
                            status: dt.status   || '-',
                            payment_status: dt.payment_status  || '-',
                            fee: dt.fee.toFixed(2)  || '-',
                            // vat_percent: dt.vat_percent  || '-',
                            // vat_amount: dt.vat_amount  || '-',
                            grand_total: dt.grand_total  || '-',
                            payment_method: dt.payment_method  || '-',
                            reason: dt.reason  || '-',
                            cancelled_by: (dt?.cancelled_by ? `${dt?.cancelled_by?.full_name} (${dt?.cancelled_by?.email})` : '-' ) ,
                            user_card_id: dt.user_card_id  || '-',
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });

                        exportData.push({
                            id:dt._id || '-',
                            user:`${dt?.user?.full_name} (${dt?.user?.email})` || '-',
                            professional:`${dt?.trainer?.full_name} (${dt?.trainer?.email})` || '-',
                            date: (convertDateUTCToLocal(dt.date,dt.start_time))  || '-',
                            start_time: convertToLcalTime(dt.start_time)  || '-',
                            end_time: convertToLcalTime(dt.end_time)  || '-',
                            duration_in_mins: dt.duration_in_mins  || '-',
                            appointment_type: dt.appointment_type  || '-',
                            status: dt.status   || '-',
                            payment_status: dt.payment_status  || '-',
                            fee: '$' + dt.fee.toFixed(2)  || '-',
                            // vat_percent:  dt.vat_percent + '%'  || '-',
                            // vat_amount: '$' + dt.vat_amount  || '-',
                            grand_total: '$' + dt.grand_total  || '-',
                            payment_method: dt.payment_method  || '-',
                            reason: dt.reason  || '-',
                            cancelled_by: (dt?.cancelled_by ? `${dt?.cancelled_by?.full_name} (${dt?.cancelled_by?.email})` : '-' ) ,
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: (dt?.updated_at ? moment(dt?.updated_at).fromNow() : '-')
                        });


                    })
                    setRows(rowData);
                    setExportRows(exportData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }



    const onAppointmentDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this appointment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {

            if (willShip) {
                await DeleteAppointment(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getAppointment();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push(`/appointments/edit-appointment/` + data.id, {
            data: data
        });
    }



    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onAppointmentDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            maxWidth:'50px',
            selector: rowData => rowData.id,
        },
        {
            id: 'user_id',
            name: 'Member',
            grow: false,
            minWidth:'300px',
            maxWidth:'350px',
            center: true,
            selector: rowData => rowData.user_id,
        },
        {
            id: 'trainer_id',
            name: 'Professionals',
            grow: false,
            minWidth:'300px',
            maxWidth:'350px',
            center: true,
            selector: rowData => rowData.trainer_id,
        },
        {
            id: 'date',
            name: 'Date',
            grow: false,
            sortable: true,
            center: true,
            selector: rowData => rowData.date,
        },
        {
            id: 'start_time',
            name: 'Start Time',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.start_time,
        },
        {
            id: 'end_time',
            name: 'End Time',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.end_time,
        },
        {
            id: 'duration_in_mins',
            name: 'Duration (Min)',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.duration_in_mins,
        },
        // {
        //     id: ' appointment_type',
        //     name: ' Appointment Type',
        //     width:'150px',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData. appointment_type,
        // },
        {
            id: ' status',
            name: 'Status',
            grow: false,
            center: true,
            selector: rowData => rowData.status,
        },
        {
            id: 'cancelled_by',
            name: 'Cancelled By',
            minWidth:'220px',
            maxWidth:'300px',
            grow: false,
            center: true,
            selector: rowData => rowData.cancelled_by,
        },
        {
            id: 'reason',
            name: 'Reason',
            minWidth:'220px',
            maxWidth:'400px',
            grow: false,
            center: true,
            selector: rowData => rowData.reason,
        },
        {
            id: ' payment_status',
            name: 'Payment Status',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.payment_status,
        },
        {
            id: ' fee',
            name: 'Fee ($)',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.fee,
        },
        // {
        //     id: ' vat_percent ',
        //     name: 'Vat Percentage (%)',
        //     width:'150px',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData.vat_percent,
        // },
        // {
        //     id: ' vat_amount',
        //     name: 'Vat Amount ($)',
        //     width:'150px',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData.vat_amount,
        // },
        {
            id: ' grand_total',
            name: 'Grand Total ($)',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.grand_total,
        },
        {
            id: ' payment_method',
            name: 'Payment Method',
            width:'150px',
            grow: false,
            center: true,
            selector: rowData => rowData.payment_method,
        },
        // {
        //     id: ' user_card_id',
        //     name: 'User Card Id',
        //     width:'200px',
        //     grow: false,
        //     center: true,
        //     selector: rowData => rowData.user_card_id,
        // },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    const getTrainers = async () => {
        await GetUsers(false, 'Trainer', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setTrainers(result.data);
            }
        })
    }

    const getUsers = async () => {
        await GetUsers(false, 'User', null,null,null, null, 0).then((result) => {
            if (result.status && result.data) {
                // result.data.unshift({ full_name:'All', _id:null })
                setUsers(result.data);
            }
        })
    }

    const optionsUsers = (item) => {
        setUserValue({ label: item.label, value:item.value })
        setSelectedUser(item.value);
    }

    const optionsTrainer = (item) => {
        setTrainerValue({label:item.label ,value:item.value})
        setSelectedTrainer(item.value);
    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    const filterType = async (value) => {
        setSelectedStatus(value)
    }

    const onDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        const formattedDate = moment(date).format("YYYY-MM-DD")
        setDate(formattedDate)
        setStartDate(date.toLocaleDateString())
    }

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Appointments</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Form.Group className="mb-3">
                            <Form.Label>Filter Date</Form.Label>
                            <Form.Control
                                id={"filter_date"}
                                placeholder="Enter Date"
                                value={startDate.date}
                                maxLength={3}
                                onChange={(e) => onDateChange(e)}
                                type="date"
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 3, offset: 0 }} lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }}>
                        <Link to={"/appointments/add-appointment"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                        <ExportCsv exportRows={exportRows} fileName={"appointments"}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Form.Group className="mb-3" controlId="users">
                            <Select
                                value={userValue}
                                placeholder={'Filter by member'}
                                options={users && users?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id} ))}
                                className="basic-multi-select"
                                onChange={optionsUsers}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Form.Group className="mb-3" controlId="trainer">
                            <Select
                                value={trainerValue}
                                placeholder={'Filter by professional'}
                                options={trainer && trainer?.map(e => ({ label: `${e.full_name} (${e.email})`, value:e._id} ))}
                                className="basic-multi-select"
                                onChange={optionsTrainer}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Form.Group className={"mb-3"} controlId="status">
                            <Form.Select id={"type"}
                                         type="text"
                                         placeholder="Filter Type"
                                         onChange={(e) => filterType(e.target.value)}
                                         value={selectedStatus}
                            >
                                <option selected disabled value="">Filter Status</option>
                                {status && status?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3} md={{ span: 3, offset: 9 }} lg={{ span: 3, offset: 9 }} xl={{ span: 2, offset: 1 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={ ()=>{
                            setStartDate({ date: "mm/dd/yyyy" })
                            setTrainerValue(null)
                            setUserValue(null)
                            setSelectedUser(null)
                            setSelectedTrainer(null)
                            setSelectedStatus('')
                            setDate('')

                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#41D2D2'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Appointments;