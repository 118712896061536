import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {FadeLoader} from "react-spinners";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {uploadToS3} from "../../utils/helpers";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetBreathingVideoByid, getBreathingVideoByid, UpdateBreathingVideo} from "../../services/users.service";

function BreathingVideo(props) {
    let history = useHistory();
    const videoRef = useRef(null);
    const [data, setData] = useState({});
    const [id, setId] = useState(null);
    const [video, setVideo] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [file, setFile] = useState(null)
    const [loader, setLoader] = useState(false);
    const [update, setUpdate] = useState(false);
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const {register, watch, handleSubmit, setError, clearErrors,setValue, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        await getBreathingVideoByid();

    }, [])

    const getBreathingVideoByid = async () => {
        await GetBreathingVideoByid().then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setId(result.data._id)
                    if(result.data?.value){
                        setVideo(result.data?.value)
                        setIsUpload(true)
                    }

                }
            } else {
                return toast.error(result.message);
            }


        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    }



    const onSubmit = async data => {
        if(isUploadOverWrite){
            if(file?.type.startsWith('video/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }}
        setLoader(true)
        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Video is required"})
            }

            let uploadVideo = await uploadToS3(file.type,file.name,file,'video');

            if (uploadVideo) {
                if (!uploadVideo) {
                    setLoader(false)
                    toast.error(uploadVideo.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Video');
                return false;
            }
            data.media=uploadVideo;
        }

        let dt = {
            ...(isUploadOverWrite && {
                url:data.media
            }),

        }

        await UpdateBreathingVideo(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                setUpdate(false)
                toast.success(data.message);
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const uploadFile = async (fileInput) => {
        let fileUrl = URL.createObjectURL(fileInput[0])
        setVideo(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
            clearErrors('media')
        }

    }

    return (
        <div className="EditVideo">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Breathing Video</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        {!update &&
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="media">
                                        <Form.Label>Preview</Form.Label>
                                        {isUpload ?
                                            <video src={video} type="video" width="450" height="250" controls
                                                   crossOrigin="anonymous"/> : ""
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        {update &&
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="media">
                                    <Form.Label>Video* <Form.Text className=" text-muted" style={{fontSize: ".675em"}}>(Max
                                        upload file size: 5 GB)</Form.Text> </Form.Label>
                                    <br/>
                                    <input
                                        {...register('media',{
                                            required: {
                                            value: "required",
                                            message: "Video file is required"
                                        }
                                        })}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.mp4'}
                                        className="custom-file-input"
                                        id='image'

                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                    {isUpload ?
                                        <video ref={videoRef} src={video} type="video" width="450" height="250" controls
                                               crossOrigin="anonymous"/> : ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>}

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"button"} onClick={()=> {
                                    setUpdate(!update)
                                    clearErrors('media')
                                }} className={"btn btn-green-theme w-100 mt-3"}
                                       value={update ? "Preview" :"Update Video"}/>
                            </Col>
                            {update &&
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#41D2D2"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Submit"}/>}
                            </Col>
                            }
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default BreathingVideo;